import React, {useCallback, useEffect, useState, useContext} from 'react';
import API from '@aws-amplify/api';
import {graphqlOperation} from '@aws-amplify/api-graphql';
import {useDisclosure} from '@chakra-ui/hooks';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import {PageLoading} from 'components/page/page-loading';
import {deleteWidget} from 'graphql/mutations';
import {BsGlobe, BsShieldCheck, BsTools, BsTranslate} from 'react-icons/bs';
import {useNavigate, useParams} from 'react-router';
import styled from 'styled-components';
import {PageContainer} from 'components/page/page-container';
import CopyButton from 'components/utils/copy-button';
import {LanguageContext} from 'App';
import {getWidgetOnWidget} from 'graphql/custom';
import ConfirmDelete from 'components/utils/confirm-delete';
import {WidgetSecurity} from './_widget-security';
import {WidgetInstall} from './_widget-install';
import {WidgetDetail} from './_widget-detail';
import {WidgetLanguage} from './_widget-language';
import {MobileHide} from 'components/page/mobilehide';
import {PageHeader} from 'components/page/page-header-section';

const TabIcon = styled.span`
  padding-right: 8px;
`;

export const WidgetScreen = () => {
  const params = useParams();
  const navigate = useNavigate();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {translatedData} = useContext(LanguageContext);

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [widget, setWidget] = useState<any>();

  const fetchData = useCallback(async () => {
    const response = (await API.graphql(
      graphqlOperation(getWidgetOnWidget, {id: params.widgetId}) as any,
    )) as any;
    console.log(response.data);
    const data = response.data.getWidget as any;
    setWidget(data);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDeleteWidget = async () => {
    setIsSaving(true);
    await API.graphql(
      graphqlOperation(deleteWidget, {
        input: {id: widget.id},
      }) as any,
    );
    setIsSaving(false);
    navigate(`/widget`);
  };

  return isLoading ? (
    <PageLoading />
  ) : (
    <PageContainer>
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink onClick={() => navigate('/widget')}>
            {translatedData['widgets']}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="#">{widget.name}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <br />
      <PageHeader title={widget.name} />

      <Box display="flex" justifyContent="space-between">
        <Box>
          {translatedData['widget-id']}: &nbsp; {widget.id} &nbsp;{' '}
          <CopyButton text={widget.id} />
        </Box>
        <Button colorScheme="red" size="sm" onClick={onOpen}>
          {translatedData['delete-widget']}
        </Button>
      </Box>
      <ConfirmDelete
        header={translatedData['delete-widget']}
        isOpen={isOpen}
        onClose={onClose}
        isSaving={isSaving}
        confirm={handleDeleteWidget}
      />
      <br />
      <Tabs>
        <TabList>
          <Tab>
            <TabIcon>
              <BsGlobe />
            </TabIcon>
            <MobileHide>{translatedData['website-details']}</MobileHide>
          </Tab>
          <Tab>
            <TabIcon>
              <BsTranslate />
            </TabIcon>
            <MobileHide>{translatedData['language']}</MobileHide>
          </Tab>
          <Tab>
            <TabIcon>
              <BsShieldCheck />
            </TabIcon>
            <MobileHide>{translatedData['security']}</MobileHide>
          </Tab>
          <Tab>
            <TabIcon>
              <BsTools />
            </TabIcon>
            <MobileHide>{translatedData['install']}</MobileHide>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Box maxWidth={600}>
              <WidgetDetail
                widget={widget}
                submitLabel={translatedData['save']}
                submit={fetchData}
              />
            </Box>
          </TabPanel>
          <TabPanel>
            <WidgetLanguage
              widget={widget}
              submitLabel={translatedData['save']}
              submit={fetchData}
            />
          </TabPanel>
          <TabPanel>
            <WidgetSecurity
              widget={widget}
              submitLabel={translatedData['save']}
              submit={fetchData}
            />
          </TabPanel>
          <TabPanel>
            <WidgetInstall widget={widget} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageContainer>
  );
};
