import {useToast} from '@chakra-ui/react';
import {AiOutlineCopy} from 'react-icons/ai';
import copy from 'copy-to-clipboard';

export default function CopyButton(props: any) {
  const toast = useToast();

  const confirm = () => {
    copy(props.text);
    toast({
      description: `Copied '${props.text}' to clipboard`,
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <AiOutlineCopy
      onClick={confirm}
      style={{display: 'inline', cursor: 'pointer'}}
    />
  );
}
