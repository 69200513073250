import styled from 'styled-components';

const OrContainer = styled.div`
  margin: 30px 0 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  color: rgb(105, 120, 135);
  font-size: 14px;
  text-align: center;
`;

const Or = styled.div`
  position: relative;
  top: -8px;
  display: inline-block;
  padding: 0px 20px;
  background: rgb(255, 255, 255);
`;

export default function OrSplitter() {
  return (
    <OrContainer>
      <Or>OR</Or>
    </OrContainer>
  );
}
