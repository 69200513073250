import {useContext} from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import {LanguageContext} from 'App';

export default function ConfirmDialog(props: any) {
  const {
    isOpen,
    onClose,
    isSaving,
    confirm,
    headerText,
    bodyText,
    confirmText,
  } = props;
  const {translatedData} = useContext(LanguageContext);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{headerText}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{bodyText}</ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            {translatedData['close']}
          </Button>
          <Button colorScheme="red" isLoading={isSaving} onClick={confirm}>
            {confirmText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
