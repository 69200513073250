import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
// eslint-disable-next-line
const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // track pageview with gtag / react-ga / react-ga4, for example:
    window.gtag('event', 'page_view', {
      page_path: location.pathname + location.search,
    });
  }, [location]);
};

export default usePageTracking;

export const trackSignup = (): void => {
  window.gtag('event', 'conversion', {
    send_to: 'AW-1070194762/TqHyCK7M4YwYEMrAp_4D',
  });
};
