import gql from 'graphql-tag';
import axios from 'axios';
import {DocumentNode, print} from 'graphql';

export interface ILanguage {
  label: string;
  code: string;
  localLabel?: string;
  autoTranslate?: boolean;
}

export const getLanguage = (): string => {
  const lang = localStorage.getItem('fluentc-language') || navigator.language;
  return lang || 'en';
};

export const getValidLanguage = (
  languages: ILanguage[],
  lang: string,
): string => {
  const obj = languages.find(itm => itm.code === lang);
  if (obj) return obj.code;
  if (!lang.includes('-')) return 'en';
  const sp0 = lang.split('-')[0];
  const spObj = languages.find(itm => itm.code === sp0);
  if (spObj) return spObj.code;
  return 'en';
};

export const checkLanguage = (
  languages: ILanguage[],
  lang: string,
): boolean => {
  const idx = languages.findIndex(itm => itm.code === lang);
  return idx > -1;
};

export const saveLanguage = (lang: string): void => {
  localStorage.setItem('fluentc-language', lang);
};

export interface TranslatedDataInterface {
  [key: string]: string;
}

export const loadCacheTranslatedData = (): TranslatedDataInterface => {
  const _data = localStorage.getItem('fluentc_translated');
  if (!_data) return {};
  try {
    const data = JSON.parse(_data);
    return data;
  } catch (ex) {
    console.log('error', ex);
  }
  return {};
};

const cacheTranslatedData = (data: TranslatedDataInterface) => {
  try {
    const _data = JSON.stringify(data);
    localStorage.setItem('fluentc_translated', _data);
  } catch (ex) {
    console.log('error', ex);
  }
};

const fetch = async (
  query: DocumentNode,
  _apiUrl?: string,
  _apiKey?: string,
) => {
  const apiUrl =
    _apiUrl ||
    'https://yks6m7xxfzdthm7ulpc5sea4te.appsync-api.us-east-1.amazonaws.com/graphql';
  const apiKey = _apiKey || 'da2-n2smjogejzhhbfo4dvvc3frw5u';

  const graphqlData = await axios({
    url: apiUrl,
    method: 'post',
    headers: {
      'x-api-key': apiKey,
    },
    data: {
      query: print(query),
    },
  });
  return graphqlData?.data?.data;
};

export const fetchTranslationData = async (
  lang: string,
): Promise<TranslatedDataInterface> => {
  const environmentID = 'ac6cf015-d14b-4318-9fd7-4ed83f353e8e';
  const translatedData: TranslatedDataInterface = {};

  const query = gql`
    query content {
      requestContent(environmentID: "${environmentID}", language: "${lang}", limit: 1000) {
        body {
          key
          value
        }
      }
    }
  `;

  try {
    const response = await fetch(query);
    const data = response?.requestContent?.body;
    for (let i = 0; i < data.length; i++) {
      translatedData[data[i].key] = data[i].value;
    }

    cacheTranslatedData(translatedData);
  } catch (ex) {
    console.log(ex);
  }
  return translatedData;
};

export const fetchAvailableLanguages = async (
  language: string,
): // lang: string,
Promise<ILanguage[]> => {
  const query = gql`
    query langQuery {
      getAvailableLanguages(lang: "${language}") {
        body {
          code
          label
          localLabel
        }
      }
    }
  `;

  try {
    const response = await fetch(query);
    const data: ILanguage[] = response?.getAvailableLanguages?.body;
    if (data?.length) {
      return data;
    }
  } catch (ex) {
    console.log(ex);
  }
  return [];
};
