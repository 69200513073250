import {Box, Divider, Heading, Text} from '@chakra-ui/react';
import React from 'react';
import {IconType} from 'react-icons/lib';

type PageHeaderProps = {
  title: string;
  description?: React.ReactElement | string;
  TitleIcon?: IconType;
  HeaderActions?: any;
};

export const PageHeader = ({
  title,
  TitleIcon,
  description,
  HeaderActions,
}: PageHeaderProps) => {
  return (
    <Box
      sx={{
        marginBottom: description ? '20px' : 0,
        py: '15px',
        borderRadius: '10px',
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          gap: '15px',
        }}>
        <Box sx={{display: 'flex', alignItems: 'center', gap: '15px'}}>
          <Heading color="gray.700">{title}</Heading>
          {TitleIcon && <TitleIcon size="40px" color="gray" />}
        </Box>
        {HeaderActions}
      </Box>
      {description ? (
        <>
          <Divider sx={{my: 2}} />
          <Text sx={{whiteSpace: 'break-spaces'}}>{description}</Text>
        </>
      ) : (
        ''
      )}
    </Box>
  );
};
