import React, {useState, useEffect, useContext} from 'react';
import {Button} from '@chakra-ui/button';
import queryString from 'querystring';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  useToast,
  Stack,
  Text,
} from '@chakra-ui/react';
import {useAuth} from 'Auth';
import {useForm} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {LanguageContext} from 'App';
import {Layout} from './layout';

const OrContainer = styled.div`
  margin: 50px 0 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  color: rgb(105, 120, 135);
  font-size: 14px;
`;

const Or = styled.div`
  position: relative;
  top: -8px;
  display: inline-block;
  padding: 0px 20px;
  background: rgb(255, 255, 255);
`;
const Form = styled.form`
  text-align: center;
`;
const Spacer = styled.div`
  height: 20px;
`;

export function ConfirmAccount() {
  const {translatedData} = useContext(LanguageContext);
  const location = useLocation();
  const {register, setValue, handleSubmit} = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [queryParams, setQueryParams] = useState<any>({email: ''});
  const {auth} = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    if (!location) return;
    const searchQuery = location.search.replace('?', '');
    const params = queryString.parse(searchQuery);
    console.log('query params', params);
    if (params.email) setValue('email', params.email);
    if (params.authCode) setValue('authCode', params.authCode);
    setQueryParams(params);

    if (params.email && params.authCode) {
      handleCreateAccount(params);
    }
  }, [location]);

  const handleCreateAccount = async (data: any) => {
    setIsLoading(true);
    try {
      await auth.confirmSignUp(data.email, data.authCode);
      setIsLoading(false);
      navigate('/');
    } catch (err: any) {
      toast({
        // title: 'Error',
        description: `${err.message}`,
        position: 'top',
        status: 'error',
        isClosable: true,
      });
      setIsLoading(false);
    }
  };
  return (
    <Layout>
      <Stack spacing={12} width="full" maxW="md">
        <Stack spacing="1" textAlign="center">
          <Heading fontSize="28px">{translatedData['confirm-account']}</Heading>
          <Text color="muted">
            {translatedData['confirm-account-description']}
          </Text>
        </Stack>
        <Form onSubmit={handleSubmit(handleCreateAccount)}>
          <FormControl id="text">
            <FormLabel>{translatedData['email-address']}</FormLabel>
            <Input
              {...register(`email`)}
              placeholder={translatedData['email-address']}
              disabled={!!queryParams?.email}
            />
          </FormControl>
          <Spacer />
          <FormControl id="text">
            <FormLabel>{translatedData['one-time-password']}</FormLabel>
            <Input
              {...register(`authCode`)}
              placeholder={translatedData['one-time-password']}
            />
            <FormHelperText></FormHelperText>
          </FormControl>
          <Spacer />
          <Button
            type="submit"
            colorScheme="pink"
            bg={'pink.900'}
            _hover={{bg: 'pink.800'}}
            style={{width: '100%'}}
            isLoading={isLoading}>
            {translatedData['confirm-account']}
          </Button>
          <Stack spacing="2" display={{base: 'flex', lg: 'none'}}>
            <OrContainer>
              <Or>OR</Or>
            </OrContainer>
            <Button
              colorScheme="gray"
              style={{width: '100%'}}
              onClick={() => navigate('/login')}>
              {translatedData['sign-in']}
            </Button>
          </Stack>
        </Form>
      </Stack>
    </Layout>
  );
}
