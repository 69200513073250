import {useState, useContext} from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  StackProps,
  Text,
  useToast,
} from '@chakra-ui/react';
import {useAuth} from 'Auth';
import {useNavigate} from 'react-router-dom';
import {LanguageContext} from 'App';
import {GoogleIcon} from './ProviderIcons';
import PasswordInput from 'components/utils/password-input';
import {BsApple, BsFacebook} from 'react-icons/bs';
import OrSplitter from 'components/utils/or-splitter';

export const SignInForm = (props: StackProps) => {
  const {translatedData} = useContext(LanguageContext);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const {auth} = useAuth();
  const toast = useToast();

  const getErrorMessage = (err: any) => {
    let key = '';
    if (err.message === 'Username cannot be empty')
      key = 'usernameempty-exception';
    switch (err.name) {
      case 'NotAuthorizedException':
      case 'InvalidParameterException':
        key = 'signin-notauthorized-exception';
        break;
      case 'UserNotFoundException':
        key = 'signin-usernotfound-exception';
        break;
      case 'UserNotConfirmedException':
        key = 'signin-usernotconfirmed-exception';
        break;
    }
    if (!key) return err.message;
    return translatedData[key];
  };

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      await auth.signIn(email, password);
      setIsLoading(false);
      navigate('/');
    } catch (err: any) {
      toast({
        description: getErrorMessage(err),
        position: 'top',
        status: 'error',
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const handleGoogleLogin = () => {
    auth.signInWithGoogle();
  };
  const handleAppleLogin = () => {
    auth.signInWithApple();
  };
  const handleFacebookLogin = () => {
    auth.signInWithFacebook();
  };
  const inputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string,
  ) => {
    if (type === 'email') {
      setEmail(e.target.value);
    } else if (type === 'password') {
      setPassword(e.target.value);
    }
  };

  const handleKeyPress = (target: any) => {
    if (target.charCode == 13) {
      handleLogin();
    }
  };

  return (
    <Stack spacing={{base: 8, md: 12, lg: 16}} {...props}>
      <Stack spacing="1" textAlign="center">
        <Heading fontSize="24px">{translatedData['welcome-back']}</Heading>
        <Text color="muted">{translatedData['login-to-your-account']}</Text>
      </Stack>
      <Stack spacing="6">
        <Stack spacing="4">
          <FormControl>
            <FormLabel htmlFor="email">
              {translatedData['email-address']}
            </FormLabel>
            <Input
              id="email"
              placeholder={translatedData['email-address']}
              type="email"
              onKeyPress={handleKeyPress}
              onChange={e => inputChange(e, 'email')}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="password">
              {translatedData['password']}
            </FormLabel>
            <PasswordInput
              id="password"
              placeholder="********"
              onKeyPress={handleKeyPress}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                inputChange(e, 'password')
              }
            />
          </FormControl>
        </Stack>
        <HStack justify="space-between">
          <Checkbox colorScheme={'pink'} defaultChecked>
            {translatedData['remember-me']}
          </Checkbox>
          <Button
            variant="link"
            color="pink.900"
            size="sm"
            onClick={() => navigate('/forgot-password')}>
            {translatedData['forgot-password-question']}
          </Button>
        </HStack>
        <Stack spacing="4">
          <Button
            // variant="outline"
            colorScheme="pink"
            bg={'pink.900'}
            _hover={{bg: 'pink.800'}}
            onClick={() => handleLogin()}
            isLoading={isLoading}>
            {translatedData['sign-in']}
          </Button>
          <OrSplitter />
          <Button
            variant="outline"
            leftIcon={<GoogleIcon boxSize="5" />}
            iconSpacing="3"
            border="1px"
            onClick={handleGoogleLogin}>
            {translatedData['sign-in-with-google']}
          </Button>

          <Button
            variant="outline"
            leftIcon={<BsApple />}
            iconSpacing="3"
            style={{width: '100%'}}
            border="1px"
            onClick={handleAppleLogin}>
            {translatedData['sign-in-with-apple']}
          </Button>
          {/* <Button
            variant="outline"
            leftIcon={<BsFacebook />}
            iconSpacing="3"
            style={{width: '100%'}}
            border="1px"
            onClick={handleFacebookLogin}>
            {translatedData['sign-in-with-facebook']}
          </Button> */}
        </Stack>
        <Stack spacing="2" display={{base: 'flex', lg: 'none'}}>
          <HStack spacing="1" justify="center">
            <Text color="muted">{translatedData['dont-have-account']}</Text>
            <Button
              variant="link"
              colorScheme="pink"
              onClick={() => navigate('/signup')}>
              {translatedData['sign-up']}
            </Button>
          </HStack>
        </Stack>
      </Stack>
    </Stack>
  );
};
