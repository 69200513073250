import React from 'react';
import {PageContainer} from '../components/page/page-container';
import GraphiQL from 'graphiql';
import {createGraphiQLFetcher} from '@graphiql/toolkit';
import awsExports from '../aws-exports';
import {ResponsiveContainer} from 'recharts';
import {PageHeader} from 'components/page/page-header-section';
console.log(awsExports.aws_appsync_graphqlEndpoint);
const fetcher = createGraphiQLFetcher({
  url: awsExports.aws_appsync_graphqlEndpoint,
  headers: {'x-api-key': awsExports.aws_appsync_apiKey},
});

export const IntegrationsScreen = () => {
  return (
    <PageContainer>
      <PageHeader title={'Integrations'} />
      <ResponsiveContainer width="99%" aspect={3}>
        <GraphiQL fetcher={fetcher} />
      </ResponsiveContainer>
    </PageContainer>
  );
};
