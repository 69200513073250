import React, {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {ChakraProvider, Flex, Button, Text, Box} from '@chakra-ui/react';
import styled from 'styled-components';
import {LanguageContext} from 'App';

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
`;
const Left = styled.div`
  display: flex;
  flex: 1 1 50%;
  padding: 40px 40px 20px;
  align-items: center;
  position: relative;
  background-color: #fff;
  flex-direction: column;
  margin-top: 0px;

  @media screen and (max-width: 768px) {
    & {
      padding: 20px 10px;
    }
  }
`;
const LeftContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  @media screen and (max-width: 768px) {
    & {
      padding: 0 10px;
    }
  }
`;
const LogoBox = styled(Box)`
  width: 100%;

  @media screen and (max-width: 768px) {
    img {
      width: auto;
      height: 50px;
    }
  }
`;

const Right = styled(Box)`
  flex: 1 1 50%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 50px 50px 15vh;
  background: url(https://i.postimg.cc/5tZnjTBF/login-logo.jpg);
  background-size: cover;
`;

const RightContent = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: row;
  gap: 60px;
  justify-content: center;
  align-items: center;

  padding: 40px 0;
  /* color: rgba(255, 255, 255, 0.8); */
  /* background: rgba(217, 217, 217, 0.1); */
  border-radius: 15px;
`;

const HeroContainer = styled.div`
  backdrop-filter: blur(30.5px);
  font-size: 68px;
  color: #fff;
  /* color: #592d5a; */
  font-weight: 800;
  background: rgba(217, 217, 217, 0.1);
  border-radius: 15px;
  padding: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
const HeroText = styled.div`
  font-size: 62px;
  color: #fff;
  /* color: #f5ce97; */
  font-weight: 800;
  align-self: flex-start;

  border-radius: 15px;
  /* padding: 40px; */
`;
const HeroSubtext = styled.div`
  margin-top: 40px;
  font-size: 48px;
  color: #f5ce97;
  align-self: flex-start;
  font-weight: 600;
`;

export function Layout({
  children,
  redirect,
}: {
  children: any;
  redirect?: string;
}) {
  const {translatedData} = useContext(LanguageContext);
  const navigate = useNavigate();

  const redirectPage = () => {
    if (redirect === 'signup') return navigate('/signup');
    navigate('/login');
  };

  const redirectHelpText = () => {
    if (redirect === 'signup') return translatedData['dont-have-account'];
    return translatedData['already-have-an-account'];
  };
  const redirectText = () => {
    if (redirect === 'signup') return translatedData['sign-up'];
    return translatedData['sign-in'];
  };

  return (
    <ChakraProvider>
      <Container>
        <Flex mx="auto" width="full">
          <Right display={{base: 'none', lg: 'flex'}}>
            <LogoBox>
              <img
                alt="logo"
                src={require('../../assets/img/fluentc-white-logo.png')}
                width="200px"
              />
            </LogoBox>
            <HeroContainer>
              <HeroText>
                {translatedData['your-multi-language-journey-begins']}
              </HeroText>
              <HeroSubtext>{translatedData['start-for-free']}</HeroSubtext>
              <RightContent>
                <Text>{translatedData['simple-setup']}</Text>
                <Text>{translatedData['easy-to-use']}</Text>
                <Text>{translatedData['instantly-translate']}</Text>
              </RightContent>
            </HeroContainer>
          </Right>
          <Left>
            <LogoBox
              justifyContent="center"
              display={{base: 'flex', lg: 'none'}}>
              <img
                alt="logo"
                src="http://crowdflare-staging.nyc3.cdn.digitaloceanspaces.com/delete-fluentc/no-background.png"
                width="200px"
              />
            </LogoBox>
            <Box
              ml="auto"
              gap={3}
              alignItems="center"
              display={{base: 'none', lg: 'flex'}}>
              <Text>{redirectHelpText()}</Text>
              <Button
                ml="auto"
                variant="link"
                colorScheme="pink"
                onClick={redirectPage}>
                {redirectText()}
              </Button>
            </Box>
            <LeftContent>{children}</LeftContent>
          </Left>
        </Flex>
      </Container>
    </ChakraProvider>
  );
}
