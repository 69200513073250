import {
  Box,
  Divider,
  Flex,
  Heading,
  Stack,
  Stat,
  StatArrow,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
} from '@chakra-ui/react';
import {API, graphqlOperation} from 'aws-amplify';
import {PageLoading} from 'components/page/page-loading';
import {endOfMonth, startOfMonth} from 'date-fns';
import {listRequests, listTranslations} from 'graphql/queries';
import React, {useEffect, useState} from 'react';
import {PageContainer} from '../components/page/page-container';
import {PageHeader} from 'components/page/page-header-section';

interface Request {
  id: string;
  environment: {
    name: string;
    url: string;
  };
  language: string;
}
interface Translation {
  id: string;
  environment: {
    name: string;
    url: string;
  };
  language: string;
}

export const StatusScreen = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [requests, setRequests] = useState<Request[]>([
    {id: '', environment: {name: '', url: ''}, language: ''},
  ]);

  const [translations, setTranslations] = useState<Translation[]>([
    {id: '', environment: {name: '', url: ''}, language: ''},
  ]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const start = startOfMonth(new Date());
    const end = endOfMonth(new Date());
    const response = (await API.graphql(
      graphqlOperation(listRequests, {
        filter: {createdAt: {ge: start, le: end}},
      }) as any,
    )) as any;
    const responseTranslation = (await API.graphql(
      graphqlOperation(listTranslations, {
        filter: {createdAt: {ge: start, le: end}},
      }) as any,
    )) as any;
    const data = response.data.listRequests.items as any;
    const data2 = responseTranslation.data.listTranslations.items as any;
    setRequests(data);
    setTranslations(data2);

    setIsLoading(false);
  };

  return isLoading ? (
    <PageLoading />
  ) : (
    <PageContainer>
      <PageHeader title={'Status and Logs'} />

      <Flex>
        <Box
          w="lg"
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          p={4}
          mr={4}
        >
          <Stack spacing={3}>
            <Heading as="div" size="md">
              System Status
            </Heading>
            <Text>Overall status of the Fluentc sytems</Text>
            <Divider />
            <Heading as="div" size="sm">
              Dev System Status
            </Heading>
            <StatGroup>
              <Stat>
                <StatLabel>Requests</StatLabel>
                <StatNumber>{requests.length}</StatNumber>
                <StatHelpText>
                  <StatArrow type="decrease" />
                  10.36%
                </StatHelpText>
              </Stat>

              <Stat>
                <StatLabel>Translations</StatLabel>
                <StatNumber>{translations.length}</StatNumber>
                <StatHelpText>
                  <StatArrow type="increase" />
                  9.05%
                </StatHelpText>
              </Stat>
            </StatGroup>
            <Divider />
            <Heading as="div" size="sm">
              QA System Status
            </Heading>
            <StatGroup>
              <Stat>
                <StatLabel>Errors</StatLabel>
                <StatNumber>3,034</StatNumber>
                <StatHelpText>
                  <StatArrow type="increase" />
                  9.36%
                </StatHelpText>
              </Stat>

              <Stat>
                <StatLabel>Translations</StatLabel>
                <StatNumber>5,034</StatNumber>
                <StatHelpText>
                  <StatArrow type="increase" />
                  9.05%
                </StatHelpText>
              </Stat>
            </StatGroup>
            <Divider />
            <Heading as="div" size="sm">
              Production System Status
            </Heading>
            <StatGroup>
              <Stat>
                <StatLabel>Errors</StatLabel>
                <StatNumber>3,034</StatNumber>
                <StatHelpText>
                  <StatArrow type="decrease" />
                  1.36%
                </StatHelpText>
              </Stat>

              <Stat>
                <StatLabel>Translations</StatLabel>
                <StatNumber>845,034</StatNumber>
                <StatHelpText>
                  <StatArrow type="increase" />
                  23.05%
                </StatHelpText>
              </Stat>
            </StatGroup>
          </Stack>
        </Box>

        <Box w="lg" borderWidth="1px" borderRadius="lg" overflow="hidden" p={4}>
          <Stack spacing={3}>
            <Heading as="div" size="md">
              Logs
            </Heading>
            {requests.map(r => (
              <Heading as="div" size="xs" key={r.id}>
                {r.environment.name}({r.environment.url}): requested{' '}
                {r.language} translation.
              </Heading>
            ))}
          </Stack>
        </Box>
      </Flex>
    </PageContainer>
  );
};
