import React, {useState, useContext} from 'react';
import API from '@aws-amplify/api';
import {graphqlOperation} from '@aws-amplify/api-graphql';
import {Button} from '@chakra-ui/button';
import {useDisclosure} from '@chakra-ui/hooks';
import {deleteApplication} from 'graphql/mutations';
import {useNavigate, useParams} from 'react-router';
import {LanguageContext} from 'App';
import ConfirmDelete from 'components/utils/confirm-delete';

export const Settings = () => {
  const navigate = useNavigate();
  const params = useParams();
  const {translatedData} = useContext(LanguageContext);

  const {isOpen, onOpen, onClose} = useDisclosure();
  const [isSaving, setIsSaving] = useState(false);

  const handleDeleteApp = async () => {
    setIsSaving(true);
    await API.graphql(
      graphqlOperation(deleteApplication, {
        input: {
          id: params.applicationId,
        },
      }) as any,
    );
    setIsSaving(false);

    await navigate(`/applications`);
  };
  return (
    <>
      <Button colorScheme="red" onClick={onOpen}>
        {translatedData['delete-application']}
      </Button>

      <ConfirmDelete
        header={translatedData['delete-application']}
        isOpen={isOpen}
        onClose={onClose}
        isSaving={isSaving}
        confirm={handleDeleteApp}
      />
    </>
  );
};
