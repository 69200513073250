import React, {useCallback, useEffect, useState, useContext} from 'react';
import {graphqlOperation} from '@aws-amplify/api-graphql';
import {
  Button,
  Heading,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  ModalFooter,
} from '@chakra-ui/react';
import {Table, TableCaption, Tbody, Td, Th, Thead, Tr} from '@chakra-ui/table';
import {useDisclosure} from '@chakra-ui/hooks';
import {BsThreeDots} from 'react-icons/bs';
import {useNavigate, useParams} from 'react-router';
import {PageHeader} from 'components/page/page-header';
import {StyledTableContainer, StyledTr} from 'components/ui/table/table';
import API from '@aws-amplify/api';
import {createEnvironment} from 'graphql/mutations';
import {getApplication} from 'graphql/queries';
import {LanguageContext} from 'App';
import {formatRelative, parseISO} from 'date-fns';
import {Environment} from 'API';

export const Environments = () => {
  const params = useParams();
  const navigate = useNavigate();
  const {translatedData} = useContext(LanguageContext);

  const {isOpen, onOpen, onClose} = useDisclosure();
  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState('');
  const [url, setUrl] = useState('false');
  const [environments, setEnvironments] = useState([]);
  const [application, setApplication] = useState({id: ''});

  const handleAddApplication = async () => {
    setIsSaving(true);

    (await API.graphql(
      graphqlOperation(createEnvironment, {
        input: {
          applicationID: params.applicationId,
          name,
          url,
        },
      }) as any,
    )) as any;

    await fetchData();
    setIsSaving(false);
    onClose();
  };

  const fetchData = useCallback(async () => {
    const response = (await API.graphql(
      graphqlOperation(getApplication, {
        id: params.applicationId,
      }) as any,
    )) as any;

    setApplication(response.data.getApplication);
    const data = (response.data.getApplication.environments.items ?? []) as any;
    data.sort((a: Environment, b: Environment) =>
      a.createdAt > b.createdAt ? -1 : 1,
    );
    setEnvironments(data);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <PageHeader>
        <Heading as="h3" size="md"></Heading>
        <Button onClick={onOpen}>{translatedData['add']}</Button>
      </PageHeader>
      <StyledTableContainer>
        <Table variant="simple">
          <TableCaption>{translatedData['all-environments']}</TableCaption>
          <Thead>
            <Tr>
              <Th>{translatedData['name']}</Th>
              <Th>URL</Th>
              <Th>{translatedData['manage']}</Th>
              <Th>{translatedData['created-at']}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {environments.length > 0 ? (
              environments.map((environment: any) => (
                <StyledTr
                  key={environment.id}
                  onClick={() =>
                    navigate(
                      `/applications/${application.id}/environments/${environment.id}`,
                    )
                  }>
                  <Td>{environment.name}</Td>
                  <Td>{environment.url}</Td>
                  <Td>
                    <Menu>
                      <MenuButton as={Button} colorScheme="gray">
                        <BsThreeDots />
                      </MenuButton>
                      <MenuList>
                        <MenuItem>{translatedData['edit']}</MenuItem>
                        <MenuDivider />
                        <MenuItem>{translatedData['delete']}</MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                  <Td style={{textAlign: 'right'}}>
                    {formatRelative(
                      parseISO(environment.createdAt),
                      new Date(),
                    )}
                  </Td>
                </StyledTr>
              ))
            ) : (
              <Tr>
                <Td colSpan={4} textAlign="center">
                  {translatedData['no-results-found']}
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </StyledTableContainer>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{translatedData['new-environment']}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="text">
              <FormLabel>{translatedData['name']}</FormLabel>
              <Input type="text" onChange={e => setName(e.target.value)} />
              <FormHelperText>
                {translatedData['enter-environment-name']}
              </FormHelperText>
            </FormControl>
            <br />
            <FormControl id="text">
              <FormLabel>Url</FormLabel>
              <Input type="text" onChange={e => setUrl(e.target.value)} />
              <FormHelperText>
                {translatedData['enter-environment-url']}
              </FormHelperText>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              {translatedData['close']}
            </Button>
            <Button
              colorScheme="pink"
              isLoading={isSaving}
              onClick={handleAddApplication}>
              {translatedData['save']}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
