import React from 'react';
import {SignInForm} from './SignInForm';
import {Layout} from './layout';

export function Login() {
  return (
    <Layout redirect="signup">
      <SignInForm width="full" maxW="md" />
    </Layout>
  );
}
