import {API, Auth, graphqlOperation} from 'aws-amplify';
import {CognitoHostedUIIdentityProvider} from '@aws-amplify/auth';
import React, {useEffect} from 'react';
import {getLanguage} from 'utils/languages';
import {getCompany} from 'graphql/queries';

interface AuthContextType {
  user: {
    attributes: any;
  };
  role: string;
  tenantID: string;
  signIn: (username: string, password: string) => void;
  signInWithGoogle: () => void;
  signInWithApple: () => void;
  signInWithFacebook: () => void;
  signUp: (username: string, email: string, password: string) => void;
  confirmSignUp: (username: string, authCode: string) => void;
  forgotPassword: (username: string) => void;
  forgotPasswordSubmit: (
    username: string,
    code: string,
    new_password: string,
  ) => void;
  changePassword: (oldPassword: string, newPassword: string) => void;
  signOut: () => void;
  authStatus: AuthStatus;
  isOnboarded: string;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export enum AuthStatus {
  pending,
  signedIn,
  signedOut,
}

export function useAuth() {
  return {auth: React.useContext(AuthContext)};
}

export function AuthProvider({children}: {children: React.ReactNode}) {
  const [user, setUser] = React.useState<any>(null);
  const [isOnboarded, setIsOnboarded] = React.useState<string>('');
  const [authStatus, setAuthStatus] = React.useState<AuthStatus>(
    AuthStatus.pending,
  );
  const [role, setRole] = React.useState('');
  const [tenantID, setTenantID] = React.useState('');
  const [signUpUser, setSignUpUser] = React.useState<any>(null);

  useEffect(() => {
    Auth.currentUserInfo()
      .then(user => {
        console.log('currentUserInfo', user);
      })
      .catch(() => {
        console.log('fetch user failed');
      });

    Auth.currentAuthenticatedUser({bypassCache: true})
      .then(user => {
        console.log('currentAuthenticatedUser', user);
        setUser(user);
        setIsOnboarded(user.attributes['custom:isOnboarded']);
        setAuthStatus(AuthStatus.signedIn);
      })
      .catch(() => {
        setUser(null);
        setAuthStatus(AuthStatus.signedOut);
      });
  }, []);

  useEffect(() => {
    const tenantID = user?.attributes?.['custom:tenantId'];
    const userID = user?.attributes?.['sub'];

    if (tenantID) {
      setTenantID(tenantID);
    }

    if (!tenantID || !userID) return;

    (async () => {
      const response = (await API.graphql(
        graphqlOperation(getCompany, {id: tenantID}),
      )) as any;
      const company = response?.data?.getCompany;
      if (company?.owner === userID) {
        setRole('owner');
      } else {
        setRole('');
      }
    })();
  }, [user]);

  const signIn = async (username: string, password: string) => {
    const res = await Auth.signIn(username, password);
    setAuthStatus(AuthStatus.signedIn);
    setUser(res);
  };

  const signInWithGoogle = () => {
    Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google});
  };

  const signInWithApple = () => {
    Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Apple});
  };
  const signInWithFacebook = () => {
    Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Facebook});
  };

  const signOut = async () => {
    await Auth.signOut();
    setAuthStatus(AuthStatus.signedOut);
    setUser(null);
  };

  async function signUp(username: string, email: string, password: string) {
    setSignUpUser({username, password});
    const lang = getLanguage();
    await Auth.signUp({
      username,
      password,
      attributes: {email},
      clientMetadata: {lang},
    });
  }

  async function confirmSignUp(username: string, authCode: string) {
    await Auth.confirmSignUp(username, authCode, {
      clientMetadata: {lang: getLanguage()},
    });
    if (signUpUser) {
      await signIn(signUpUser.username, signUpUser.password);
    }
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    await Auth.changePassword(user, oldPassword, newPassword);
  }

  async function forgotPassword(username: string) {
    await Auth.forgotPassword(username);
  }

  async function forgotPasswordSubmit(
    username: string,
    code: string,
    new_password: string,
  ) {
    await Auth.forgotPasswordSubmit(username, code, new_password);
  }

  const value = {
    user,
    role,
    tenantID,
    signIn,
    signInWithGoogle,
    signInWithApple,
    signInWithFacebook,
    signUp,
    signOut,
    authStatus,
    confirmSignUp,
    forgotPassword,
    forgotPasswordSubmit,
    changePassword,
    isOnboarded,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
