import React, {useContext, useState} from 'react';
import {useAuth} from 'Auth';
import {API, graphqlOperation} from 'aws-amplify';
import {fetchCheckoutUrl} from 'graphql/queries';
import {Link} from '@chakra-ui/react';

import {PageContainer} from 'components/page/page-container';
import {LanguageContext} from 'App';
import {PageHeader} from 'components/page/page-header-section';
import {CreditPackageCards} from './_credit-package-cards';
import {BillingFaqs} from './_billing-faqs';
import {FaMoneyCheck} from 'react-icons/fa';
import {FiHelpCircle} from 'react-icons/fi';

export type ItemType = 'basic' | 'bulk' | 'premium';

export const BillingScreen = () => {
  const {auth} = useAuth();
  const {translatedData} = useContext(LanguageContext);

  // const [cart, setCart] = useState({
  //   basic: 0,
  //   bulk: 0,
  //   premium: 0,
  // });

  const [isLoading, setIsLoading] = useState({});

  // const checkout = async () => {
  //   setIsLoading(true);
  //   const host = window.location.origin;
  //   const response = (await API.graphql(
  //     graphqlOperation(fetchCheckoutUrl, {
  //       customerEmail: auth.user.attributes.email,
  //       host,
  //       basic: cart.basic,
  //       bulk: cart.bulk,
  //       premium: cart.premium,
  //     }) as any,
  //   )) as any;

  //   window.location.href = response.data.fetchCheckoutUrl.body;
  //   setIsLoading(false);
  // };

  // const addItem = (item: ItemType) => {
  //   if (cart[item] >= 99) return;
  //   cart[item]++;
  //   setCart({...cart});
  // };

  // const subItem = (item: ItemType) => {
  //   if (cart[item] < 1) return;
  //   cart[item]--;
  //   setCart({...cart});
  // };

  // const clearItems = () => {
  //   setCart({
  //     basic: 0,
  //     bulk: 0,
  //     premium: 0,
  //   });
  // };

  const buyNow = async (item: ItemType) => {
    setIsLoading({[item]: true});
    const host = window.location.origin;
    const response = (await API.graphql(
      graphqlOperation(fetchCheckoutUrl, {
        customerEmail: auth.user.attributes.email,
        host,
        basic: item === 'basic' ? 1 : 0,
        bulk: item === 'bulk' ? 1 : 0,
        premium: item === 'premium' ? 1 : 0,
      }) as any,
    )) as any;

    window.location.href = response.data.fetchCheckoutUrl.body;
    setIsLoading({[item]: false});
  };

  return (
    <PageContainer>
      <PageHeader
        title={translatedData['billing']}
        // description={translatedData['billing-page-description']}
        description={translatedData['no-contracts']}
        TitleIcon={FaMoneyCheck}
        HeaderActions={
          <Link href="#billing-faqs">
            <FiHelpCircle color="#EF8E67" size={30} />
          </Link>
        }
      />
      <CreditPackageCards buyNow={buyNow} isLoading={isLoading} />
      <BillingFaqs />
      {/* <MyCart
        cart={cart}
        addItem={addItem}
        subItem={subItem}
        clearItems={clearItems}
        isLoading={isLoading}
        checkout={checkout}
      /> */}
    </PageContainer>
  );
};
