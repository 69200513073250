import React, {useCallback, useEffect, useState, useContext} from 'react';
import API from '@aws-amplify/api';
import {graphqlOperation} from '@aws-amplify/api-graphql';
import {useDisclosure} from '@chakra-ui/hooks';
import {Heading} from '@chakra-ui/layout';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import Papa from 'papaparse';
import GraphQLPlayground from 'components/page/GraphQLPlayground';
import {PageLoading} from 'components/page/page-loading';
import {deleteEnvironment} from 'graphql/mutations';
import {BsDownload, BsGear} from 'react-icons/bs';
import {FiEdit} from 'react-icons/fi';
import {GrGraphQl} from 'react-icons/gr';
import {useNavigate, useParams} from 'react-router';
import styled from 'styled-components';
import {PageContainer} from 'components/page/page-container';
import {PageHeader} from 'components/page/page-header';
import CopyButton from 'components/utils/copy-button';
import {LinkContent} from './_link-content';
import {LanguageContext} from 'App';
import {getEnvironmentWithContents} from 'graphql/custom';
import ConfirmDelete from 'components/utils/confirm-delete';
import {MobileHide} from 'components/page/mobilehide';

const TabIcon = styled.span`
  padding-right: 8px;
`;

export const EnvironmentScreen = () => {
  const params = useParams();
  const navigate = useNavigate();
  const {translatedData, languages} = useContext(LanguageContext);

  const [isLoading, setIsLoading] = useState(true);
  const [environment, setEnvironment] = useState<any>();

  const fetchData = useCallback(async () => {
    const response = (await API.graphql(
      graphqlOperation(getEnvironmentWithContents, {
        id: params.environmentId,
        limit: 1000,
      }) as any,
    )) as any;
    console.log(response.data);
    const data = response.data.getEnvironment as any;
    setEnvironment(data);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const {isOpen, onOpen, onClose} = useDisclosure();
  const {
    isOpen: isOpenGql,
    onOpen: onOpenGql,
    onClose: onCloseGql,
  } = useDisclosure();
  const [isSaving, setIsSaving] = useState(false);

  const handleDeleteApp = async () => {
    setIsSaving(true);
    await API.graphql(
      graphqlOperation(deleteEnvironment, {
        input: {
          id: environment.id,
        },
      }) as any,
    );
    setIsSaving(false);

    await navigate(`/applications/${environment.application.id}`);
  };

  const downloadCSVFile = (rows: any[]) => {
    console.log(rows);
    const csvContent = 'data:text/csv;charset=utf-8,' + Papa.unparse(rows);
    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  const downloadCSV = async () => {
    const ret = [];
    ret.push(['key', 'value', ...languages.map(lang => lang.code)]);
    for (let i = 0; i < environment.versions.items.length; i++) {
      const version = environment.versions.items[i].version;
      if (version.type !== 'text') continue;
      const csvData = [version.content.name, version.value];

      for (let j = 0; j < languages.length; j++) {
        const lang = languages[j].code;
        const translations = version.translations.items;
        const idx = translations.findIndex((tr: any) => tr.language === lang);
        if (idx > -1) csvData.push(translations[idx].text);
        else csvData.push('');
      }
      ret.push(csvData);
    }
    downloadCSVFile(ret);
  };

  const onEnvironmentVersionUpdated = (data: any) => {
    const {deleted, created} = data;
    if (deleted?.id) {
      const idx = environment.versions.items.findIndex(
        (i: any) => i.id === deleted?.id,
      );
      console.log('onDeleted', deleted?.id, idx);
      if (idx > -1) {
        environment.versions.items.splice(idx, 1);
      }
    }
    if (created) {
      console.log('onCreated', created);
      environment.versions.items.push(created);
    }
    setEnvironment({...environment});
  };

  return isLoading ? (
    <PageLoading />
  ) : (
    <PageContainer>
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink onClick={() => navigate('/applications')}>
            {translatedData['applications']}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink
            href="#"
            onClick={() =>
              navigate(`/applications/${environment.application.id}`)
            }>
            {environment.application.name}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="#">
            {environment.name} ({environment.url})
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <br />
      <PageHeader>
        <Heading>{environment.name}</Heading>
      </PageHeader>
      <Box>
        {translatedData['environment-id']}: &nbsp; {environment.id} &nbsp;{' '}
        <CopyButton text={environment.id} />
      </Box>
      <br />
      <Tabs>
        <TabList>
          <Tab>
            <TabIcon>
              <FiEdit />
            </TabIcon>
            <MobileHide>{translatedData['manage-content']}</MobileHide>
          </Tab>
          <Tab>
            <TabIcon>
              <BsGear />
            </TabIcon>
            <MobileHide>{translatedData['settings']}</MobileHide>
          </Tab>
          <Tab>
            <TabIcon>
              <GrGraphQl />
            </TabIcon>
            <MobileHide>{translatedData['playground']}</MobileHide>
          </Tab>
          <Tab>
            <TabIcon>
              <BsDownload />
            </TabIcon>
            <MobileHide>{translatedData['download']}</MobileHide>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <LinkContent
              environment={environment}
              onUpdated={onEnvironmentVersionUpdated}
            />
          </TabPanel>

          <TabPanel>
            <Button colorScheme="red" onClick={onOpen}>
              {translatedData['delete-environment']}
            </Button>

            <ConfirmDelete
              header={translatedData['delete-environment']}
              isOpen={isOpen}
              onClose={onClose}
              isSaving={isSaving}
              confirm={handleDeleteApp}
            />
          </TabPanel>
          <TabPanel>
            <Text>
              {translatedData['playground-description-1']}
              <br />
              <br />
            </Text>
            <Button colorScheme="pink" onClick={onOpenGql}>
              {translatedData['open']}
            </Button>
          </TabPanel>
          <TabPanel>
            <Button colorScheme="pink" onClick={downloadCSV}>
              {translatedData['download-csv']}
            </Button>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Modal isOpen={isOpenGql} onClose={onCloseGql} size="full">
        <ModalOverlay />
        <ModalContent height={'100%'}>
          <ModalHeader>FluentC API</ModalHeader>
          <ModalCloseButton />
          <GraphQLPlayground environment={environment} />
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onCloseGql}>
              {translatedData['close']}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </PageContainer>
  );
};
