import API from '@aws-amplify/api';
import {graphqlOperation} from '@aws-amplify/api-graphql';
import {Box, HStack, Heading} from '@chakra-ui/layout';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Text,
} from '@chakra-ui/react';
import {LanguageContext} from 'App';
import {updateApplication, updateWidget} from 'graphql/mutations';
import {useContext, useEffect, useState} from 'react';
import {FiAlertCircle, FiMoreVertical} from 'react-icons/fi';
import dropdown from 'assets/icons/dropdown.jpg';
import list from 'assets/icons/list.jpg';
import float from 'assets/icons/float.jpg';

export const WidgetLanguage = (props: any) => {
  const {hidden, widget, submit, submitLabel} = props;
  const {languages, translatedData} = useContext(LanguageContext);

  const [isSaving, setIsSaving] = useState(false);
  const [language, setLanguage] = useState('en');
  const [autoTranslate, setAutoTranslate] = useState(true);
  const [display, setDisplay] = useState('');
  const [formality, setFormality] = useState('');

  const [supportedLanguages, setSupportedLanguages] = useState<any>([]);

  useEffect(() => {
    if (!widget) return;
    const _languages =
      widget.environment?.application?.supportedLanguages || [];
    setSupportedLanguages(
      languages.map((l: any) => ({
        ...l,
        selected: _languages.findIndex((ll: any) => l.code === ll.code) > -1,
      })),
    );
    setLanguage(widget?.sourceLanguage || 'en');
    setAutoTranslate(!!widget?.autoTranslate);
    setDisplay(widget?.display || 'dropdown');
    setFormality(widget?.formality || '');
  }, [widget]);

  const submitClicked = async () => {
    const activeLanguages = supportedLanguages
      .filter((l: any) => l.selected)
      .map((l: any) => ({
        code: l.code,
        label: l.label,
        autoTranslate: true,
      }));
    if (!autoTranslate && !activeLanguages.length) return;

    setIsSaving(true);
    const appResponse = (await API.graphql(
      graphqlOperation(updateApplication, {
        input: {
          id: widget.environment.applicationID,
          supportedLanguages: activeLanguages,
        },
      }),
    )) as any;
    if (!appResponse?.data?.updateApplication) return;

    const widgetResponse = (await API.graphql(
      graphqlOperation(updateWidget, {
        input: {
          id: widget.id,
          sourceLanguage: language,
          autoTranslate,
          display,
          formality,
        },
      }) as any,
    )) as any;
    const wid = widgetResponse?.data?.updateWidget;
    if (!wid) return;
    submit(wid.id);
    setIsSaving(false);
  };

  return (
    <Box display={hidden ? 'none' : 'block'}>
      <Heading size="md">{translatedData['language-options']}</Heading>
      <Accordion allowMultiple defaultIndex={[0, 1, 2, 3, 4]} mt={3}>
        <AccordionItem>
          <AccordionButton
            display="flex"
            justifyContent="space-between"
            bgColor="gray.200">
            {translatedData['website-language']}
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <Text whiteSpace="break-spaces">
              {translatedData['widget-source-language-description']}
            </Text>
            <Select
              value={language}
              onChange={e => setLanguage(e.target.value)}>
              {languages.map((itm, idx) => (
                <option key={idx} value={itm.code}>
                  {itm.localLabel || itm.label}
                </option>
              ))}
            </Select>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton
            display="flex"
            justifyContent="space-between"
            bgColor="gray.200">
            {translatedData['auto-translate']}
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <Box display="flex" justifyContent="space-between" gap={15}>
              <Text whiteSpace="break-spaces">
                {translatedData['widget-auto-translate-description']}
              </Text>
              <Switch
                colorScheme="teal"
                size="md"
                isChecked={autoTranslate}
                onChange={e => setAutoTranslate(e.target.checked)}
              />
            </Box>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton
            display="flex"
            justifyContent="space-between"
            bgColor="gray.200">
            {translatedData['formality']}
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <RadioGroup value={formality} onChange={setFormality}>
              <Box display="flex" flexDirection="column">
                <Radio value="">{translatedData['widget-default']}</Radio>
                <Radio value="formal">
                  {translatedData['widget-formal-description']}
                </Radio>
                <Radio value="informal">
                  {translatedData['widget-informal-description']}
                </Radio>
              </Box>
            </RadioGroup>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton
            display="flex"
            justifyContent="space-between"
            bgColor="gray.200">
            {translatedData['supported-languages']}
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <Box display="flex" justifyContent="space-between" gap={15}>
              <Text whiteSpace="break-spaces">
                {translatedData['widget-supported-languages-description']}
              </Text>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<FiMoreVertical />}
                  variant="outline"
                />
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      setSupportedLanguages(
                        supportedLanguages.map((l: any) => ({
                          ...l,
                          selected: true,
                        })),
                      );
                    }}>
                    {translatedData['enable-all']}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setSupportedLanguages(
                        supportedLanguages.map((l: any) => ({
                          ...l,
                          selected: false,
                        })),
                      );
                    }}>
                    {translatedData['disable-all']}
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
            <Box
              mt={5}
              sx={{
                columnCount: [1, 2, 3, 4], // Adjust the number of columns based on the breakpoint
                columnGap: '16px', // Adjust the gap between columns
              }}>
              {supportedLanguages
                .sort((a: any, b: any) => a.label.localeCompare(b.label))
                .map((lang: any) => (
                  <Box
                    key={lang.code}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={2}
                    mb={4} // Margin bottom for each language box
                    bg="white"
                    boxShadow="sm"
                    borderRadius="md">
                    <Text>{lang.label}</Text>
                    <Switch
                      colorScheme="teal"
                      size="md"
                      isChecked={lang.selected}
                      onChange={e => {
                        // Handle the change
                        const newLanguages = supportedLanguages.map(
                          (language: any) => {
                            if (language.code === lang.code) {
                              return {...language, selected: e.target.checked};
                            }
                            return language;
                          },
                        );
                        setSupportedLanguages(newLanguages);
                      }}
                    />
                  </Box>
                ))}
            </Box>
            {/* <Box
              display="flex"
              flexWrap="wrap"
              flexDirection={['column']}
              justifyContent="space-between"
              gap={1}
              mt={5}>
              {supportedLanguages.map((lang: any) => (
                <Box
                  key={lang.code}
                  width={250}
                  display="flex"
                  justifyContent="space-between">
                  <Text>{lang.label}</Text>
                  <Switch
                    colorScheme="teal"
                    size="md"
                    isChecked={lang.selected}
                    onChange={e => {
                      lang.selected = e.target.checked;
                      setSupportedLanguages([...supportedLanguages]);
                    }}
                  />
                </Box>
              ))}
            </Box> */}
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton
            display="flex"
            justifyContent="space-between"
            bgColor="gray.200">
            {translatedData['display-options']}
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <Box display="flex" justifyContent="space-between" gap={15}>
              <Text whiteSpace="break-spaces">
                {translatedData['widget-display-option-description']}
              </Text>
              <RadioGroup value={display} onChange={setDisplay}>
                <HStack spacing={3}>
                  <Radio value="dropdown">Dropdown</Radio>
                  <Radio value="list">List</Radio>
                  <Radio value="float">Float</Radio>
                </HStack>
              </RadioGroup>
              {display === 'dropdown' && <img src={dropdown} />}
              {display === 'list' && <img src={list} />}
              {display === 'float' && <img src={float} />}
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      {!autoTranslate &&
        !supportedLanguages.filter((l: any) => l.selected).length && (
          <Box display="flex" alignItems="center" gap={2} mt={2}>
            <Text color="red.500">
              <FiAlertCircle size="20px" />
            </Text>
            <Text color="red.500">
              {translatedData['widget-language-required']}
            </Text>
          </Box>
        )}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        gap={2}
        mt={5}>
        {submit && (
          <Button
            colorScheme="pink"
            bg={'pink.900'}
            _hover={{bg: 'pink.800'}}
            disabled={
              !autoTranslate &&
              !supportedLanguages.filter((l: any) => l.selected).length
            }
            isLoading={isSaving}
            onClick={submitClicked}>
            {submitLabel}
          </Button>
        )}
      </Box>
    </Box>
  );
};
