import {useState, useContext, useEffect} from 'react';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Switch,
  Divider,
} from '@chakra-ui/react';
import {LanguageContext} from 'App';
import ColorPicker from 'components/color-picker';
import styled from 'styled-components';

const StyledWrapper = styled(Box)`
  margin-top: 8px;
  width: 250px;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
`;
const StyledWrapperRight = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
`;

export default function BannerAppearance(props: any) {
  const {isOpen, onClose, widget, save} = props;
  const {translatedData} = useContext(LanguageContext);

  const [bannerColor, setBannerColor] = useState('');
  const [bannerBorderColor, setBannerBorderColor] = useState('');
  const [hideBanner, setHideBanner] = useState(true);
  const [hideLogo, setHideLogo] = useState(true);

  useEffect(() => {
    setBannerColor(widget?.bannerColor || '#E8F2FB');
    setBannerBorderColor(widget?.bannerBorderColor || '#6b90da');
    setHideBanner(widget?.hideBanner || false);
    setHideLogo(widget?.hideLogo || false);
  }, [widget]);

  const handleSave = () => {
    save(widget, bannerColor, bannerBorderColor, hideBanner, hideLogo);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Banner Appearance</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <StyledWrapper>
              <Box display="flex" gap="10px">
                <Text>Show Banner</Text>
              </Box>
              <StyledWrapperRight>
                <Switch
                  isChecked={!hideBanner}
                  onChange={e => setHideBanner(!e.target.checked)}
                />
              </StyledWrapperRight>
            </StyledWrapper>
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <StyledWrapper>
              <Box display="flex" gap="10px">
                <Text>FluentC Logo</Text>
              </Box>
              <StyledWrapperRight>
                <Switch
                  isChecked={!hideLogo}
                  onChange={e => setHideLogo(!e.target.checked)}
                />
              </StyledWrapperRight>
            </StyledWrapper>
          </Box>
          <StyledWrapper>
            <Text>Background Color</Text>
            <ColorPicker color={bannerColor} setColor={setBannerColor} />
          </StyledWrapper>
          <StyledWrapper>
            <Text>Border Color</Text>
            <ColorPicker
              color={bannerBorderColor}
              setColor={setBannerBorderColor}
            />
          </StyledWrapper>
          <Divider my={5} />
          <Box>
            <Text>Preview</Text>
            <Box
              sx={{
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                padding: '5px 10px',
                border: '1px solid #0001',
                borderBottomColor: bannerBorderColor,
                bgColor: bannerColor,
              }}>
              {!hideLogo && (
                <img
                  style={{marginRight: '12px', width: '24px', height: '24px'}}
                  src="https://s3.amazonaws.com/widget.fluentc.io/logo.png"
                />
              )}
              <select style={{border: '1px solid black'}}>
                <option>Language 1</option>
                <option>Language 1</option>
                <option>Language 1</option>
              </select>
              <button
                style={{
                  marginLeft: '12px',
                  padding: '3px 8px',
                  fontSize: '14px',
                  border: '1px solid black',
                }}>
                Show Original
              </button>
              <Box
                sx={{
                  width: '24px',
                  height: '24px',
                  marginLeft: 'auto',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.59-13L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41z"></path>
                </svg>
              </Box>
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            {translatedData['close']}
          </Button>
          <Button colorScheme="pink" onClick={handleSave}>
            {translatedData['save']}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
