import React, {useState, useContext} from 'react';
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  useToast,
  HStack,
  Text,
  Link,
  Stack,
  Checkbox,
  UnorderedList,
  ListItem,
  Box,
} from '@chakra-ui/react';
import validator from 'validator';
import {useAuth} from 'Auth';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import queryString from 'querystring';
import {LanguageContext} from 'App';
import {GoogleIcon} from './ProviderIcons';
import {Layout} from './layout';
import PasswordInput from 'components/utils/password-input';
import {BsApple, BsFacebook} from 'react-icons/bs';
import OrSplitter from 'components/utils/or-splitter';

const Spacer = styled.div`
  height: 10px;
`;
const ToastWrapper = styled.div`
  border-radius: 6px;
  overflow: hidden;
`;

export function CreateAccount() {
  const {translatedData} = useContext(LanguageContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [agreeTerm, setAgreeTerm] = useState(false);
  const {auth} = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  const getCustomException = (validate: any) => {
    const keys = Object.keys(validate);
    if (!keys.some(key => validate[key])) return null;
    return (
      <ToastWrapper>
        <Alert status="warning">
          <AlertIcon />
          <AlertDescription>
            <UnorderedList>
              {keys.map(key => (
                <ListItem
                  key={key}
                  color={validate[key] ? 'red.500' : 'green.500'}>
                  {translatedData[key]}
                </ListItem>
              ))}
            </UnorderedList>
          </AlertDescription>
        </Alert>
      </ToastWrapper>
    );
  };

  const getCustomExceptionMessage = (email: string, password: string) => {
    const emailValidate = {
      'usernameempty-exception': false,
      'signup-invalidemailspace-exception': false,
      'signup-invalidemail-exception': false,
    };
    const passwordValidate = {
      'passwordempty-exception': false,
      'signup-passwordlength-exception': false,
      'signup-invalidpasswordspace-exception': false,
    };

    const emailValidateRegex = /[\p{L}\p{M}\p{S}\p{N}\p{P}]+/u;
    const spaceValidateRegex = /^[\S]+.*[\S]+$/u;

    if (validator.isEmpty(email))
      emailValidate['usernameempty-exception'] = true;
    if (
      !validator.matches(email, emailValidateRegex) ||
      !validator.matches(email, spaceValidateRegex)
    )
      emailValidate['signup-invalidemailspace-exception'] = true;
    if (!validator.isEmail(email))
      emailValidate['signup-invalidemail-exception'] = true;

    if (validator.isEmpty(password))
      passwordValidate['passwordempty-exception'] = true;
    if (!validator.isLength(password, {min: 8}))
      passwordValidate['signup-passwordlength-exception'] = true;
    if (
      !validator.matches(password, spaceValidateRegex) ||
      password.includes(' ')
    )
      passwordValidate['signup-invalidpasswordspace-exception'] = true;

    const customException =
      getCustomException(emailValidate) || getCustomException(passwordValidate);
    return customException;
  };

  const showExceptionToast = (err: any, email: string, password: string) => {
    const customException = getCustomExceptionMessage(email, password);

    let customMessageKey = '';
    if (
      err?.name === 'UsernameExistsException' ||
      err?.name === 'UserLambdaValidationException' ||
      err?.name === 'InvalidParameterException'
    )
      customMessageKey = 'signup-usernameexists-exception';
    const customDescription =
      !customException && (translatedData[customMessageKey] || err?.message);

    toast({
      // title: 'Error',
      description: customDescription,
      position: 'top',
      status: 'error',
      duration: 5000,
      isClosable: true,
      render: customException ? () => customException : undefined,
    });
  };

  const handleCreateAccount = async () => {
    if (getCustomExceptionMessage(email, password)) {
      return showExceptionToast(null, email, password);
    }
    setIsLoading(true);
    try {
      await auth.signUp(email, email, password);
      setIsLoading(false);
      navigate(`/confirm-account/?${queryString.stringify({email})}`);
    } catch (err: any) {
      showExceptionToast(err, email, password);
      setIsLoading(false);
    }
  };

  const inputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string,
  ) => {
    if (type === 'email') {
      setEmail(e.target.value);
    } else if (type === 'password') {
      setPassword(e.target.value);
    }
  };
  const handleGoogleLogin = () => {
    auth.signInWithGoogle();
  };
  const handleAppleLogin = () => {
    auth.signInWithApple();
  };
  const handleFacebookLogin = () => {
    auth.signInWithFacebook();
  };
  return (
    <Layout>
      <Stack spacing={12} width="full" maxW="md">
        <Stack spacing="1" textAlign="center">
          <Heading fontSize="28px">
            {translatedData['get-started-with-fluentc']}
          </Heading>
          <Text color="muted">{translatedData['getting-started-is-easy']}</Text>
        </Stack>
        <Box>
          <FormControl>
            <FormLabel>{translatedData['email-address']}</FormLabel>
            <Input
              onChange={e => inputChange(e, 'email')}
              placeholder={translatedData['email-address']}
            />
          </FormControl>
          <Spacer />
          <FormControl>
            <FormLabel>{translatedData['password']}</FormLabel>
            <PasswordInput
              placeholder={'********'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                inputChange(e, 'password')
              }
            />
            <FormHelperText></FormHelperText>
          </FormControl>
          <HStack>
            <Checkbox
              checked={agreeTerm}
              onChange={e => setAgreeTerm(e.target.checked)}>
              <Text color="muted">
                {translatedData['agree-privacy-policy']}.
                <Link
                  href="https://www.fluentc.io/privacy-policy/"
                  target="_blank"
                  color="teal.500"
                  ml={3}
                  zIndex={3}>
                  {translatedData['privacy-policy']}
                </Link>
              </Text>
            </Checkbox>
          </HStack>
          <Spacer />
          <Button
            type="submit"
            colorScheme="pink"
            bg={'pink.900'}
            _hover={{bg: 'pink.800'}}
            style={{width: '100%'}}
            disabled={!agreeTerm}
            isLoading={isLoading}
            onClick={() => handleCreateAccount()}>
            {translatedData['create-account']}
          </Button>
          <OrSplitter />
          <Button
            variant="outline"
            leftIcon={<GoogleIcon boxSize="5" />}
            iconSpacing="3"
            style={{width: '100%'}}
            border="1px"
            onClick={handleGoogleLogin}>
            {translatedData['sign-in-with-google']}
          </Button>
          <Spacer />
          <Button
            variant="outline"
            leftIcon={<BsApple />}
            iconSpacing="3"
            style={{width: '100%'}}
            border="1px"
            onClick={handleAppleLogin}>
            {translatedData['sign-in-with-apple']}
          </Button>
          {/* <Spacer />
          <Button
            variant="outline"
            leftIcon={<BsFacebook />}
            iconSpacing="3"
            style={{width: '100%'}}
            border="1px"
            onClick={handleFacebookLogin}>
            {translatedData['sign-in-with-facebook']}
          </Button> */}
          <Spacer />
          <Stack spacing="2" display={{base: 'flex', lg: 'none'}}>
            <HStack spacing="1" justify="center">
              <Text color="muted">
                {translatedData['already-have-an-account']}
              </Text>
              <Button
                variant="link"
                colorScheme="pink"
                onClick={() => navigate('/login')}>
                {translatedData['sign-in']}
              </Button>
            </HStack>
          </Stack>
        </Box>
      </Stack>
    </Layout>
  );
}
