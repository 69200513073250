import {LanguageContext} from 'App';
import {PageContainer} from 'components/page/page-container';
import PluginLink from 'components/utils/plugin-link';
import {useContext, useEffect, useState} from 'react';

import {
  AspectRatio,
  Box,
  Divider,
  Heading,
  Link,
  List,
  ListItem,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {Token} from 'API';
import {useAuth} from 'Auth';
import FigmaIcon from 'assets/icons/figma.png';
import {API, graphqlOperation} from 'aws-amplify';
import {PageHeader} from 'components/page/page-header-section';
import {PageLoading} from 'components/page/page-loading';
import CopyButton from 'components/utils/copy-button';
import {tokensByTenant} from 'graphql/queries';
import {FiFigma} from 'react-icons/fi';
import {formatNumber} from 'utils/helper';
import {SanitizeHTML} from 'utils/sanitize-html';
export const FigmaScreen = () => {
  const {translatedData} = useContext(LanguageContext);
  const [tokens, setTokens] = useState<Token[]>([]);
  const {auth} = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const tenantID =
    auth.user?.attributes && auth.user?.attributes['custom:tenantId'];
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const apiKeys = (await API.graphql(
      graphqlOperation(tokensByTenant, {tenantID, limit: 25}) as any,
    )) as any;

    const tokens = apiKeys.data.tokensByTenant.items;
    tokens.sort((a: Token, b: Token) => (a.createdAt > b.createdAt ? -1 : 1));

    setTokens(tokens);

    setIsLoading(false);
  };

  return isLoading ? (
    <PageLoading />
  ) : (
    <PageContainer>
      <PageHeader
        title={translatedData['figma']}
        description={translatedData['figma-description']}
        TitleIcon={FiFigma}
      />

      <Box marginY="8">
        <Heading as="h2" size="lg">
          {translatedData['install-title']}
        </Heading>
        <Text>{translatedData['install-description-figma']}</Text>
        <PluginLink
          label={translatedData['try-it-figma']}
          href="https://www.figma.com/community/plugin/1131290121606601469/FluentC-Translation-and-Localization"
          icon={FigmaIcon}
        />
      </Box>
      <Divider />
      <Box marginY="8">
        <Heading as="h2" size="lg">
          {translatedData['api-key']}
        </Heading>

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>{translatedData['api-key']}</Th>
              <Th>{translatedData['usage']}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {tokens.map((token: any) => (
              <Tr key={token?.apiKey}>
                <Td>
                  {token?.apiKey} <CopyButton text={token?.apiKey} />
                </Td>
                <Td>
                  {formatNumber(token.creditsUsage || 0)}{' '}
                  {translatedData['credits']}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Divider />
      <Box marginY="8">
        <Heading as="h2" size="lg">
          {translatedData['setup-figma-title']}
        </Heading>
        <SimpleGrid columns={[1, null, 2]} spacing="40px">
          <Box p="4">
            <Box maxWidth="800px" bg="white">
              <Text fontWeight="bold" marginBottom="2">
                {translatedData['pre-requisites']}
              </Text>
              <List spacing={2} marginBottom="5">
                <ListItem>{translatedData['figma-account']}</ListItem>
                <ListItem>
                  {translatedData['fluentc-figma-plugin-installed']}
                </ListItem>
                <ListItem>
                  {translatedData['api-key-from-your-fluentc-account']}
                </ListItem>
              </List>

              <Heading size="md" marginBottom="4">
                {translatedData['step-by-step-instructions']}
              </Heading>

              <Box marginBottom="4">
                <Text fontWeight="bold" marginBottom="1">
                  {translatedData['step-by-step-1']}
                </Text>
                <Text>{translatedData['adobe-xd-step1-description']}</Text>
              </Box>

              <Box marginBottom="4">
                <Text fontWeight="bold" marginBottom="1">
                  {translatedData['step-by-step-figma-2']}
                </Text>
                <Text>
                  {translatedData['figma-step2-description']}{' '}
                  <Link
                    href="https://www.figma.com/"
                    isExternal
                    color="blue.500">
                    https://www.figma.com/
                  </Link>
                  .
                </Text>
              </Box>

              <Box marginBottom="4">
                <Text fontWeight="bold" marginBottom="1">
                  {translatedData['step-by-step-figma-3']}
                </Text>
                <Text>
                  <SanitizeHTML
                    html={translatedData['figma-step3-description']}
                  />
                </Text>
              </Box>

              <Box marginBottom="4">
                <Text fontWeight="bold" marginBottom="1">
                  {translatedData['step-by-step-4']}
                </Text>
                <Text>
                  {' '}
                  <SanitizeHTML
                    html={translatedData['figma-step4-description']}
                  />
                </Text>
              </Box>

              <Box marginBottom="4">
                <Text fontWeight="bold" marginBottom="1">
                  {translatedData['step-by-step-5']}
                </Text>
                <Text>{translatedData['adobe-xd-step5-description']}</Text>
              </Box>
            </Box>
          </Box>
          <Box p="4">
            <AspectRatio maxW="720px" ratio={16 / 9}>
              <iframe
                allowFullScreen
                src="https://www.youtube.com/embed/Hgwr30MYx2g"></iframe>
            </AspectRatio>
          </Box>
        </SimpleGrid>
      </Box>
    </PageContainer>
  );
};
