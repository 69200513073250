import React, {useContext, useEffect, useState} from 'react';
import API from '@aws-amplify/api';
import {graphqlOperation} from '@aws-amplify/api-graphql';
import {Box, Heading} from '@chakra-ui/layout';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Textarea,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {LanguageContext, TenantContext} from 'App';
import {
  createApplication,
  createEnvironment,
  createWidget,
  updateWidget,
} from 'graphql/mutations';
import {listApplicationsOnWidget} from 'graphql/custom';
import OrSplitter from 'components/utils/or-splitter';
import {FiPlus} from 'react-icons/fi';
import ConfirmDialog from 'components/utils/confirm-dialog';
import {listWidgets} from 'graphql/queries';

export const WidgetDetail = (props: any) => {
  const {hidden, widget, submit, submitLabel} = props;
  const {languages, translatedData} = useContext(LanguageContext);
  const tenantID = useContext(TenantContext);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {
    isOpen: isOpenExistApp,
    onOpen: onOpenExistApp,
    onClose: onCloseExistApp,
  } = useDisclosure();
  const {
    isOpen: isOpenExistEnv,
    onOpen: onOpenExistEnv,
    onClose: onCloseExistEnv,
  } = useDisclosure();
  const {
    isOpen: isOpenExistWidget,
    onOpen: onOpenExistWidget,
    onClose: onCloseExistWidget,
  } = useDisclosure();

  const [isSaving, setIsSaving] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [appName, setAppName] = useState('Untitled Application');
  const [envName, setEnvName] = useState('Test Environment');
  const [envUrl, setEnvUrl] = useState('http://localhost:3000');
  const [addNewAppEnv, setAddNewAppEnv] = useState(false);

  const [name, setName] = useState(
    widget?.name || translatedData['untitled-widget'],
  );
  const [description, setDescription] = useState(widget?.description || '');
  const [application, setApplication] = useState('');
  const [environment, setEnvironment] = useState('');

  const [widgets, setWidgets] = useState<any>([]);
  const [applications, setApplications] = useState<any>([]);
  const [environments, setEnvironments] = useState<any>([]);

  const fetchData = async () => {
    const response = (await API.graphql(
      graphqlOperation(listApplicationsOnWidget, {}) as any,
    )) as any;
    let data = response.data.listApplications.items as any;
    data = data.filter((d: any) => d.type === 'Web Application' || !d.type);
    data.sort((a: any, b: any) => (a.createdAt > b.createdAt ? -1 : 1));

    setApplications(data);
    setApplication(widget?.environment?.applicationID || '');
    setEnvironment(widget?.environmentID || '');
  };

  const fetchWidgets = async () => {
    const response = (await API.graphql(
      graphqlOperation(listWidgets, {}) as any,
    )) as any;
    const data = response.data.listWidgets.items as any;
    setWidgets(data);
  };

  useEffect(() => {
    fetchData();
    fetchWidgets();
  }, []);

  useEffect(() => {
    const app: any = applications.find((app: any) => app.id === application);
    const envs = app?.environments?.items || [];
    setEnvironments(envs);
    const env: any = environments.find((env: any) => env.id === environment);

    if (!app && application) setApplication('');
    if (!env && environment) setEnvironment('');
  }, [application, applications]);

  const addAppEnv = async () => {
    if (!addNewAppEnv) return;
    onCloseExistApp();
    onCloseExistEnv();
    setIsAdding(true);

    const appResponse = (await API.graphql(
      graphqlOperation(createApplication, {
        input: {
          companyID: tenantID,
          name: appName,
          type: 'Web Application',
          supportedLanguages: languages.map((l: any) => ({
            code: l.code,
            label: l.label,
            autoTranslate: true,
          })),
        },
      }) as any,
    )) as any;

    const app = appResponse?.data?.createApplication;
    if (!app) return;
    setApplications([app, ...applications]);
    setApplication(app.id);

    const envResponse = (await API.graphql(
      graphqlOperation(createEnvironment, {
        input: {
          applicationID: app.id,
          name: envName,
          url: envUrl,
        },
      }) as any,
    )) as any;
    setIsAdding(false);

    const env = envResponse?.data?.createEnvironment;
    if (!env) return;
    setEnvironments([env, ...environments]);
    setEnvironment(env.id);

    onClose();
    setAppName('');
    setEnvName('');
    setEnvUrl('');
  };

  const addAppEnvClicked = async () => {
    if (!appName || !envName) return;
    setAddNewAppEnv(true);

    const _existApp = applications.find((app: any) => app.name === appName);
    if (_existApp) {
      const _existEnv = (_existApp.environments?.items || []).find(
        (env: any) => env.name === envName,
      );
      if (_existEnv) {
        onOpenExistEnv();
      } else {
        onOpenExistApp();
      }
      return;
    }
    addAppEnv();
  };

  const saveWidget = async () => {
    onCloseExistWidget();
    setIsSaving(true);
    if (!widget) {
      const appResponse = (await API.graphql(
        graphqlOperation(createApplication, {
          input: {
            companyID: tenantID,
            name: 'Widget Application',
            type: 'Web Application',
            supportedLanguages: languages.map((l: any) => ({
              code: l.code,
              label: l.label,
              autoTranslate: true,
            })),
          },
        }) as any,
      )) as any;

      const app = appResponse?.data?.createApplication;
      if (!app) return;

      const envResponse = (await API.graphql(
        graphqlOperation(createEnvironment, {
          input: {
            applicationID: app.id,
            name: 'New Widget Environment',
            url: 'http://localhost:3000',
          },
        }) as any,
      )) as any;
      setIsAdding(false);

      const environment = envResponse?.data?.createEnvironment;

      const widgetResponse = (await API.graphql(
        graphqlOperation(createWidget, {
          input: {
            name,
            description,
            environmentID: environment.id,
            display: 'dropdown',
            sourceLanguage: 'en',
            autoTranslate: true,
            urls: [],
            tags: [],
          },
        }) as any,
      )) as any;

      const wid = widgetResponse?.data?.createWidget;
      if (!wid) return;
      submit(wid.id);
    } else {
      const widgetResponse = (await API.graphql(
        graphqlOperation(updateWidget, {
          input: {
            id: widget.id,
            name,
            description,
            environmentID: environment,
          },
        }) as any,
      )) as any;
      const wid = widgetResponse?.data?.updateWidget;
      if (!wid) return;
      submit(wid.id);
    }
    setIsSaving(false);
  };

  const submitClicked = async () => {
    const _existWidget = widgets.find(
      (wid: any) => wid.id !== widget?.id && wid.name === name,
    );
    if (_existWidget) {
      onOpenExistWidget();
    } else {
      saveWidget();
    }
  };

  return (
    <Box display={hidden ? 'none' : 'block'} maxWidth="600px">
      <Heading size="md">{translatedData['website-details']}</Heading>
      <Box mt={2}>
        <FormControl isInvalid={!name}>
          <FormLabel mb={0}>{translatedData['widget-name']}</FormLabel>
          <Input
            type="text"
            autoFocus
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder={translatedData['enter-widget']}
          />
        </FormControl>
        <FormControl mt={3}>
          <FormLabel mb={0}>{translatedData['widget-description']}</FormLabel>
          <Textarea
            value={description}
            onChange={e => setDescription(e.target.value)}
            placeholder={translatedData['enter-widget-description']}
            rows={3}
          />
        </FormControl>
        {/* <Box display="flex" gap={5} alignItems="center" mt={3}>
          <FormControl isInvalid={!application}>
            <FormLabel mb={0}>{translatedData['application']}</FormLabel>
            <Select
              value={application}
              onChange={e => setApplication(e.target.value)}
              placeholder={
                applications.length
                  ? translatedData['select-web-application']
                  : translatedData['no-application-found']
              }>
              {applications.map((app: any) => (
                <option value={app.id} key={app.id}>
                  {app.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl isInvalid={!environment}>
            <FormLabel mb={0}>{translatedData['environment']}</FormLabel>
            <Select
              value={environment}
              onChange={e => setEnvironment(e.target.value)}
              placeholder={
                environments.length
                  ? translatedData['select-environment']
                  : translatedData['no-environment-found']
              }>
              {environments.map((env: any) => (
                <option value={env.id} key={env.id}>
                  {env.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box> */}
      </Box>
      {/* <OrSplitter />
      <Box>
        <Button style={{width: '100%'}} leftIcon={<FiPlus />} onClick={onOpen}>
          {translatedData['add-application-environment']}
        </Button>
      </Box>*/}
      <Box display="flex" justifyContent="flex-end" gap={2} mt={5}>
        {submit && (
          <Button
            colorScheme="pink"
            bg={'pink.900'}
            _hover={{bg: 'pink.800'}}
            disabled={!name || !environment}
            isLoading={isSaving}
            onClick={submitClicked}>
            {submitLabel}
          </Button>
        )}
      </Box>
      {/*  <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {translatedData['new-application-environment']}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={3}>
              {translatedData['new-widget-application-description']}
            </Text>
            <FormControl>
              <FormLabel mb={0}>{translatedData['application-name']}</FormLabel>
              <Input
                type="text"
                value={appName}
                onChange={e => setAppName(e.target.value)}
                placeholder={translatedData['enter-application']}
              />
            </FormControl>
            <FormControl mt={1}>
              <FormLabel mb={0}>{translatedData['environment-name']}</FormLabel>
              <Input
                type="text"
                value={envName}
                onChange={e => setEnvName(e.target.value)}
                placeholder={translatedData['enter-environment-name']}
              />
            </FormControl>
            <FormControl mt={1}>
              <FormLabel mb={0}>URL</FormLabel>
              <Input
                type="text"
                value={envUrl}
                onChange={e => setEnvUrl(e.target.value)}
                placeholder={translatedData['enter-environment-url']}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              {translatedData['close']}
            </Button>
            <Button
              isLoading={isAdding}
              disabled={!appName || !envName || !envUrl}
              colorScheme="pink"
              bg={'pink.900'}
              _hover={{bg: 'pink.800'}}
              onClick={addAppEnvClicked}>
              {translatedData['add']}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal> */}
      <ConfirmDialog
        isOpen={isOpenExistWidget}
        onClose={onCloseExistWidget}
        isSaving={isAdding}
        confirm={saveWidget}
        headerText={translatedData['confirm']}
        bodyText={translatedData['widget-name-exist']}
        confirmText={translatedData['save']}
      />
      <ConfirmDialog
        isOpen={isOpenExistEnv}
        onClose={onCloseExistEnv}
        isSaving={isAdding}
        confirm={addAppEnv}
        headerText={translatedData['confirm']}
        bodyText={translatedData['application-environment-exist']}
        confirmText={translatedData['add']}
      />
      <ConfirmDialog
        isOpen={isOpenExistApp}
        onClose={onCloseExistApp}
        isSaving={isAdding}
        confirm={addAppEnv}
        headerText={translatedData['confirm']}
        bodyText={translatedData['application-name-exist']}
        confirmText={translatedData['add']}
      />
    </Box>
  );
};
