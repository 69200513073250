import {Box, Button, Heading, Text} from '@chakra-ui/react';
import {LanguageContext} from 'App';
import {useContext} from 'react';

type CreditPackageCardProps = {
  isLoading: boolean;
  name: string;
  price: number;
  pricePerCredit: number;
  subDescription: string;
  discount: number;
  features: string;
  buyNow: () => void;
};

export const CreditPackageCard = ({
  isLoading,
  name,
  price,
  pricePerCredit,
  subDescription,
  features,
  discount,
  buyNow,
}: CreditPackageCardProps) => {
  const {translatedData} = useContext(LanguageContext);

  return (
    <Box>
      <Box
        sx={{
          maxWidth: '100%',
          borderRadius: '8px',
        }}>
        <Box
          sx={{
            bgGradient: 'linear(to-br, gray.100, gray.100)',
            pt: 5,

            cursor: 'pointer',
            textAlign: 'center',
          }}>
          <Box
            sx={{
              px: '15px',
            }}>
            <Heading as={'h3'} size={'lg'}>
              {name}
            </Heading>
          </Box>

          <Text fontSize="20px" fontWeight={500} mt={2} color="pink.900">
            {subDescription}
          </Text>
          {features}
          <Box sx={{padding: '10px 20px'}}>
            <Box sx={{mt: 3}}>
              <Heading color="pink.900">${price}</Heading>
              <Text mt={1} fontWeight={500}>
                ${pricePerCredit} {translatedData['per-credit']}
              </Text>
              <Text>
                (
                {discount
                  ? discount + `% ${translatedData['discount']}`
                  : translatedData['base-price']}
                )
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <Button
        colorScheme="pink"
        bg={'pink.900'}
        _hover={{bg: 'pink.800'}}
        mt={3}
        width="100%"
        isLoading={isLoading}
        onClick={buyNow}>
        {translatedData['buy-now']}
      </Button>
    </Box>
  );
};
