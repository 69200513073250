import {Button, Img} from '@chakra-ui/react';
import {FiExternalLink} from 'react-icons/fi';

export default function PluginLink(props: {
  label: string;
  href: string;
  icon: any;
}) {
  const {label, href, icon} = props;

  const openLink = () => {
    window.open(href, '_blank');
  };

  return (
    <Button
      onClick={openLink}
      justifyContent="space-between"
      gap="10px"
      marginLeft="auto"
      marginY="2"
      paddingY="2"
      minWidth="220px"
      height="50px"
      color="white"
      bg="pink.900"
      _hover={{bg: 'pink.800'}}
      _active={{bg: 'pink.800'}}
      fontSize="large">
      <Img height="100%" src={icon} alt="plugin" />
      {label}
      <FiExternalLink />
    </Button>
  );
}
