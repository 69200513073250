import React from 'react';

import {TenantContext} from 'App';

// import {API, graphqlOperation} from 'aws-amplify';
import {PageContainer} from 'components/page/page-container';
import {PageLoading} from 'components/page/page-loading';
// import {createApplication, createEnvironment} from 'graphql/mutations';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

// const AppName = 'Figma';
// const EnvironmentName = 'Plugin Default';
// const EnvironmentUrl = 'fluentc://';

export const BillingSuccessScreen = () => {
  const navigate = useNavigate();
  const tenantID = useContext(TenantContext);

  const [isLoading, setIsLoading] = useState(true);

  const setupAppEnvironment = async () => {
    // const appResponse = (await API.graphql(
    //   graphqlOperation(createApplication, {
    //     input: {
    //       companyID: tenantID,
    //       name: AppName,
    //     },
    //   }) as any,
    // )) as any;

    // (await API.graphql(
    //   graphqlOperation(createEnvironment, {
    //     input: {
    //       applicationID: appResponse.data.createApplication.id,
    //       name: EnvironmentName,
    //       url: EnvironmentUrl,
    //     },
    //   }) as any,
    // )) as any;

    navigate('/');
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    setupAppEnvironment();
  }, [tenantID]);
  return <PageContainer>{isLoading ? <PageLoading /> : <></>}</PageContainer>;
};
