import {Box} from '@chakra-ui/react';
import {CreditFeatures} from './_credit-features';
import {CreditPackageCard} from './_credit-package-card';
import {ItemType} from './billing-screen';
import {useContext} from 'react';
import {LanguageContext} from 'App';

type CreditPackageCardsProps = {
  isLoading: any;
  buyNow: (item: ItemType) => void;
  displayFeatures?: boolean;
};

export const CreditPackageCards = ({
  isLoading,
  buyNow,
  displayFeatures = true,
}: CreditPackageCardsProps) => {
  const {translatedData} = useContext(LanguageContext);
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '15px',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}>
      <Box>
        <CreditPackageCard
          name={translatedData['basic-package']}
          price={14.99}
          pricePerCredit={0.015}
          discount={0}
          subDescription={`1,000 ${translatedData['credits']}`}
          features={translatedData['basic-package-description']}
          isLoading={isLoading['basic']}
          buyNow={() => buyNow('basic')}
        />
      </Box>
      <Box>
        <CreditPackageCard
          name={translatedData['bulk-discount-package']}
          price={24.99}
          pricePerCredit={0.005}
          discount={67}
          subDescription={`5,000 ${translatedData['credits']}`}
          features={translatedData['bulk-discount-package-description']}
          isLoading={isLoading['bulk']}
          buyNow={() => buyNow('bulk')}
        />
      </Box>
      <Box>
        <CreditPackageCard
          name={translatedData['premium-discount-package']}
          price={44.99}
          pricePerCredit={0.00225}
          discount={85}
          subDescription={`20,000 ${translatedData['credits']}`}
          features={translatedData['premium-discount-package-description']}
          isLoading={isLoading['premium']}
          buyNow={() => buyNow('premium')}
        />
      </Box>
      {displayFeatures ?? <CreditFeatures />}
    </Box>
  );
};
