import {useContext} from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import {LanguageContext} from 'App';

export default function ConfirmDelete(props: any) {
  const {isOpen, onClose, isSaving, confirm, header} = props;
  const {translatedData} = useContext(LanguageContext);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{translatedData['action-confirm-alert']}</ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            {translatedData['close']}
          </Button>
          <Button colorScheme="red" isLoading={isSaving} onClick={confirm}>
            {translatedData['delete']}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
