/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://yks6m7xxfzdthm7ulpc5sea4te.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-n2smjogejzhhbfo4dvvc3frw5u",
    "aws_cloud_logic_custom": [
        {
            "name": "fluentcRestApi",
            "endpoint": "https://9khxn5jve5.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:54625d01-5b1e-42a0-bbaf-79f733fe7e3a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_w9kPGDdXy",
    "aws_user_pools_web_client_id": "a154qpga9kafb144lrldrkia7",
    "oauth": {
        "domain": "fluentc28b598a5-28b598a5-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://dev.fluentc.io/,https://dashboard.fluentc.io/,https://bogdan.d1qrs21ox2oi7l.amplifyapp.com/",
        "redirectSignOut": "http://localhost:3000/,https://dev.fluentc.io/,https://dashboard.fluentc.io/,https://bogdan.d1qrs21ox2oi7l.amplifyapp.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "fluentcdevblockcontent203247-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
