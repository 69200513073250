import React, {useState, useContext} from 'react';
import {Button} from '@chakra-ui/button';
import {Switch} from '@chakra-ui/switch';
import {Table, TableCaption, Tbody, Td, Th, Thead, Tr} from '@chakra-ui/table';
import {Tag} from '@chakra-ui/tag';
import {ILanguage} from 'utils/languages';
import API, {graphqlOperation} from '@aws-amplify/api';
import {updateContent} from 'graphql/mutations';
import {useParams} from 'react-router';
import {GrClose, GrLanguage} from 'react-icons/gr';
import {LanguageContext} from 'App';
import {StyledTableContainer} from 'components/ui/table/table';
import {PageHeader} from 'components/page/page-header-section';

export const Languages = ({
  contentLanguages,
}: {
  contentLanguages: ILanguage[];
}) => {
  const {translatedData, languages} = useContext(LanguageContext);

  const [enabledLanguages, setEnabledLanguages] =
    useState<ILanguage[]>(contentLanguages);
  const [availableLanguages, setAvailableLanguages] = useState<ILanguage[]>(
    languages.filter(
      l => !contentLanguages.map(cl => cl.code).includes(l.code),
    ),
  );
  const params = useParams();

  const handleUpdateContentLanguages = async (addedLanguages: ILanguage[]) => {
    const response = (await API.graphql(
      graphqlOperation(updateContent, {
        input: {
          id: params.contentId,
          enabledLanguages: addedLanguages.map(lang => ({
            code: lang.code,
            label: lang.label,
            autoTranslate: lang.autoTranslate,
          })),
        },
      }) as any,
    )) as any;
    setEnabledLanguages(addedLanguages);
    console.log(response.data);
    console.log(enabledLanguages);
  };

  return (
    <>
      <PageHeader
        title={translatedData['active-languages']}
        TitleIcon={GrLanguage}
        HeaderActions={
          <Button
            onClick={() => {
              setAvailableLanguages(
                languages.map(el => ({
                  ...el,
                  autoTranslate: true,
                })),
              );
              handleUpdateContentLanguages([]);
            }}
          >
            {translatedData['deactivate-all']}
          </Button>
        }
      />
      <StyledTableContainer>
        <Table variant="simple">
          <TableCaption>{translatedData['active-languages']}</TableCaption>
          <Thead>
            <Tr>
              <Th>{translatedData['code']}</Th>
              <Th>{translatedData['language']}</Th>
              <Th>{translatedData['status']}</Th>
              <Th>{translatedData['enable-automatic-translation']}</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {enabledLanguages.length > 0 ? (
              enabledLanguages.map(enabledLanguage => (
                <Tr key={enabledLanguage.code}>
                  <Td>{enabledLanguage.code}</Td>
                  <Td>{enabledLanguage.label}</Td>
                  <Td>
                    {enabledLanguage.autoTranslate ? (
                      <>
                        <Tag colorScheme="teal">
                          {translatedData['enabled']}
                        </Tag>
                      </>
                    ) : (
                      <Tag>{translatedData['disabled']}</Tag>
                    )}{' '}
                  </Td>
                  <Td>
                    <Switch
                      colorScheme="teal"
                      size="lg"
                      defaultChecked={enabledLanguage.autoTranslate}
                      onChange={e => {
                        const index = enabledLanguages.findIndex(
                          el => el.code === enabledLanguage.code,
                        );

                        enabledLanguages[index].autoTranslate =
                          e.target.checked;
                        handleUpdateContentLanguages(enabledLanguages);
                      }}
                    />
                  </Td>
                  <Td>
                    <Button
                      onClick={() => {
                        const addedLanguages = enabledLanguages
                          .filter(el => el.code !== enabledLanguage.code)
                          .map(el => ({
                            ...el,
                          }));
                        setAvailableLanguages([enabledLanguage]);
                        handleUpdateContentLanguages(addedLanguages);
                      }}
                    >
                      <GrClose />
                    </Button>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={5}>{translatedData['no-active-languages']}</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </StyledTableContainer>

      <PageHeader
        title={translatedData['available-languages']}
        TitleIcon={GrLanguage}
        HeaderActions={
          <Button
            onClick={() => {
              const addedLanguages = languages.map(el => ({
                ...el,
                autoTranslate: true,
              }));
              setAvailableLanguages([]);
              handleUpdateContentLanguages(addedLanguages);
            }}
          >
            {translatedData['activate-all']}
          </Button>
        }
      />
      <StyledTableContainer>
        <Table variant="simple">
          <TableCaption>{translatedData['available-languages']}</TableCaption>
          <Thead>
            <Tr>
              <Th>{translatedData['code']}</Th>
              <Th>{translatedData['language']}</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {availableLanguages.length > 0 ? (
              availableLanguages.map((language: any) => (
                <Tr key={language.code}>
                  <Td>{language.code}</Td>
                  <Td>{language.label}</Td>
                  <Td>
                    <Button
                      onClick={() => {
                        const addedLanguages = enabledLanguages
                          .concat(language)
                          .map(el => ({
                            ...el,
                            autoTranslate: true,
                          }));
                        setAvailableLanguages(
                          availableLanguages.filter(
                            l => l.code !== language.code,
                          ),
                        );

                        handleUpdateContentLanguages(addedLanguages);
                      }}
                    >
                      {translatedData['activate']}
                    </Button>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={3}>
                  {translatedData['no-more-available-languages']}
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </StyledTableContainer>
    </>
  );
};
