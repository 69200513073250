import {LanguageContext} from 'App';
// import {useAuth} from 'Auth';
// import {useContext, useEffect} from 'react';
import react from 'react';
import {useEffect} from 'react';
import ReactGA4 from 'react-ga4';
import {PiStackBold} from 'react-icons/pi';
import {FiEdit, FiFigma, FiHome} from 'react-icons/fi';

import {GrDocumentText, GrGraphQl} from 'react-icons/gr';
import {MdOutlineWidgets} from 'react-icons/md';
import {SiAdobexd, SiI18Next} from 'react-icons/si';

import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {Colors} from 'utils/colors';
import usePageTracking from 'utils/usePageTracking';
import {VscJson} from 'react-icons/vsc';

const NavMenu = styled.div`
  background-color: #f7fafc;
  width: 240px;
  flex-direction: column;
  display: flex;
  padding: 20px;
  border-right: 1px solid #edf2f7;
  @media screen and (max-width: 768px) {
    width: 56px;
    padding: 10px;
  }
`;

const NavMenuTitle = styled.div`
  font-size: 14px;
  color: ${Colors.black};
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 10px;
  font-weight: 600;
  @media screen and (max-width: 768px) {
    font-size: 0px;
    margin: 5px 0;
  }
`;

const NavMenuItem = styled(Link)<any>`
  font-size: 16px;
  color: ${Colors.black};
  text-decoration: none;
  padding: 10px 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  && svg {
    margin-right: 8px;
  }
  @media screen and (max-width: 768px) {
    font-size: 24px;
    && svg {
      margin: auto;
    }
  }
`;

const NavMenuLink = styled.a`
  font-size: 16px;
  color: ${Colors.black};
  text-decoration: none;
  padding: 10px 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  && svg {
    margin-right: 8px;
  }
  @media screen and (max-width: 768px) {
    font-size: 24px;
    && svg {
      margin: auto;
    }
  }
`;

const NavMenuText = styled.span`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Navbar = () => {
  usePageTracking();
  const {translatedData} = react.useContext(LanguageContext);
  // const {auth} = useAuth();

  useEffect(() => {
    ReactGA4.send({hitType: 'pageview', page: window.location.pathname});
  }, []);

  return (
    <>
      <NavMenu>
        <NavMenuItem to="/">
          <FiHome />
          <NavMenuText>{translatedData['nav-home']}</NavMenuText>
        </NavMenuItem>
        <NavMenuItem to="/widget" id="tour-widget">
          <MdOutlineWidgets />
          <NavMenuText>{translatedData['web-widget']}</NavMenuText>
        </NavMenuItem>

        <NavMenuTitle>Design</NavMenuTitle>
        <NavMenuItem to="/adobe-xd" id="tour-plugins">
          <SiAdobexd />
          <NavMenuText>{translatedData['adobe-xd']}</NavMenuText>
        </NavMenuItem>
        <NavMenuItem to="/figma" id="tour-plugins">
          <FiFigma />
          <NavMenuText>{translatedData['figma']}</NavMenuText>
        </NavMenuItem>

        <NavMenuTitle>Content Management</NavMenuTitle>
        <NavMenuItem to="/applications" id="tour-application">
          <PiStackBold />
          <NavMenuText>{translatedData['applications']}</NavMenuText>
        </NavMenuItem>
        <NavMenuItem to="/content" id="tour-content">
          <FiEdit />
          <NavMenuText>{translatedData['content']}</NavMenuText>
        </NavMenuItem>
        {/* {auth?.role === 'owner' && (
          <NavMenuItem to="/myteam" id="tour-myteam">
            <BsFillPeopleFill />
            <NavMenuText>My Team</NavMenuText>
          </NavMenuItem>
        )} */}

        <NavMenuTitle>Build</NavMenuTitle>

        <NavMenuItem
          as="a"
          href="https://www.npmjs.com/package/i18n-fluentc"
          id="tour-playground">
          <SiI18Next />
          <NavMenuText>i18next</NavMenuText>
        </NavMenuItem>
        <NavMenuItem to="/playground" id="tour-playground">
          <GrGraphQl />
          <NavMenuText>{translatedData['playground']}</NavMenuText>
        </NavMenuItem>
        {/* <NavMenuTitle>Tools</NavMenuTitle>
        <NavMenuLink
          href="https://docs.fluentc.io/"
          target="_blank"
          id="tour-documentation">
          <VscJson />
          <NavMenuText>JSON Translator</NavMenuText>
        </NavMenuLink> */}
        <NavMenuTitle>Resources</NavMenuTitle>
        <NavMenuLink
          href="https://docs.fluentc.io/"
          target="_blank"
          id="tour-documentation">
          <GrDocumentText />
          <NavMenuText>{translatedData['documentation']}</NavMenuText>
        </NavMenuLink>

        {/* <NavMenuItem to="/status">
            <GrStatusInfo />
            Status and Logs
          </NavMenuItem> */}
      </NavMenu>
    </>
  );
};
