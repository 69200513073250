import {LanguageContext} from 'App';
import {useContext} from 'react';
import {Select} from '@chakra-ui/react';
import {saveLanguage} from 'utils/languages';
import {useAuth} from 'Auth';
import {API, graphqlOperation} from 'aws-amplify';
import {updateUser} from 'graphql/mutations';

export default function LanguageSelector() {
  const {auth} = useAuth();

  const {language, setLanguage, languages} = useContext(LanguageContext);

  const updateLanguage = async (lang: string) => {
    if (!lang) return;
    const userid = auth.user.attributes.sub;
    const response = await API.graphql(
      graphqlOperation(updateUser, {
        input: {
          id: userid,
          lang,
        },
      }),
    );
    console.log('updatelanguage', response);
  };

  const handleChange = (e: any) => {
    const lang = e.target.value;
    if (!lang) return;
    setLanguage(lang);
    saveLanguage(lang);
    updateLanguage(lang);
  };

  return (
    <Select value={language} onChange={handleChange}>
      {languages.map((itm, idx) => (
        <option key={idx} value={itm.code}>
          {itm.localLabel || itm.label}
        </option>
      ))}
    </Select>
  );
}
