import React from 'react';

import {createGraphiQLFetcher} from '@graphiql/toolkit';
import {GraphiQL} from 'graphiql';

import 'graphiql/graphiql.css';

const fetcher = createGraphiQLFetcher({
  url: process.env.REACT_APP_FLUENTC_API_URL as string,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_FLUENTC_API_KEY as string,
  },
});

export default function GraphQLPlayground({environment}: {environment: any}) {
  console.log('GraphiQL environment', environment);
  return (
    <GraphiQL
      fetcher={fetcher}
      query={`
query{
  requestContent(environmentID:"${environment?.id}", language:"en"){
    body{
      type
      key
      value
      translated
    }
  }
}
  `}
    />
  );
}
