import styled from 'styled-components';

export const PageContainer = styled.div`
  padding: 40px;
  min-height: 100%;

  @media screen and (max-width: 768px) {
    padding: 10px;
  }
`;
