export const parseKey = (txt: string): string => {
  const sps = txt.trim().toLocaleLowerCase().split(' ');
  let key = '';
  for (const sp of sps) {
    if (key.length > 15 && key.includes('-')) break;
    if (!sp) continue;
    key += (key ? '-' : '') + sp;
  }
  return key;
};

interface validkeyResponseType {
  valid: boolean;
  error?: string;
}

export const validateKey = (key: string): validkeyResponseType => {
  if (!key) {
    return {
      valid: false,
      error: 'invalid-key',
    };
  }
  if (key.includes(' ')) {
    return {
      valid: false,
      error: 'invalid-key',
    };
  }
  return {valid: true};
};

export const increaseVersion = (v: string): string => {
  const num: RegExpMatchArray | null = v.match(/\d*$/);
  if (!num || !num[0]) return v + 2;
  return v.substring(0, num.index) + (parseInt(num[0]) + 1);
};
