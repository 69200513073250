import {useContext} from 'react';
import {Text, Link} from '@chakra-ui/react';
import {LanguageContext} from 'App';

export const Footer = () => {
  const {translatedData} = useContext(LanguageContext);

  return (
    <Text fontSize="xs" align="center">
      {translatedData['copyright-fluentc-inc']} {new Date().getFullYear()}{' '}
      &copy;. {translatedData['all-rights-reserved']}&nbsp;
      <Link
        color="blue.600"
        href="https://www.fluentc.io/privacy-policy/"
        target="_blank"
        rel="noreferrer"
      >
        {translatedData['privacy-policy']}
      </Link>
    </Text>
  );
};
