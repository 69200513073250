import {useEffect, useState, useContext} from 'react';
import {useDisclosure} from '@chakra-ui/hooks';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  InputGroup,
  InputLeftElement,
  Select,
  Flex,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  InputRightElement,
  CloseButton,
} from '@chakra-ui/react';
import {
  Paginator,
  Container,
  Previous,
  Next,
  PageGroup,
} from 'chakra-paginator';
import {FiEdit, FiSearch} from 'react-icons/fi';
import {API, graphqlOperation, Storage} from 'aws-amplify';
import {PageContainer} from 'components/page/page-container';
import {PageLoading} from 'components/page/page-loading';
import {StyledTableContainer, StyledTr} from 'components/ui/table/table';
import {Dropdown} from 'components/ui/dropdown';
import {createContent, createVersion} from 'graphql/mutations';
import {searchContents} from 'graphql/queries';
import {searchTotalContents} from 'graphql/custom';
import {useNavigate} from 'react-router-dom';
import {AddFile} from './_add_file';
import {formatRelative, parseISO} from 'date-fns';
import {FaAngleLeft, FaAngleRight} from 'react-icons/fa';
import {useAuth} from 'Auth';
import {LanguageContext} from 'App';
import {CSVImport} from './_csv_import';
import {PageHeader} from 'components/page/page-header-section';

export const ContentsScreen = () => {
  const {translatedData, languages} = useContext(LanguageContext);

  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState('');
  const [fileKey, setFileKey] = useState('');
  const [imageKey, setImageKey] = useState('');
  const [videoKey, setVideoKey] = useState('');
  const [storageKey, setStorageKey] = useState('');
  const [uploadStatus, setUploadStatus] = useState('notStarted');

  //Pagination states
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [countPerPage, setCountPerPage] = useState(10);
  const [typeFilter, setTypeFilter] = useState('');
  const [keySearch, setKeySearch] = useState('');
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const [tenantID, setTenantID] = useState('');
  // const tenantID = useContext(TenantContext);
  const {auth} = useAuth();

  useEffect(() => {
    fetchData();
  }, [typeFilter, countPerPage, tenantID]);

  useEffect(() => {
    if (auth?.user?.attributes['custom:isOnboarded'])
      setTenantID(auth.user.attributes['custom:tenantId']);
  }, [auth?.user]);

  useEffect(() => {
    fetchData(false);
  }, [page]);

  const getContentsCount = async (filter: any) => {
    let total = 0;

    const response = (await API.graphql(
      graphqlOperation(searchTotalContents, {
        filter,
      }) as any,
    )) as any;

    const data = response.data.searchContents;

    total = data.total;

    return total;
  };

  const fetchData = async (refresh = true, newSearch = false) => {
    if (!tenantID) return;

    setIsLoading(true);
    let _page = page;
    if (refresh) {
      setPage(1);
      _page = 1;
    }

    const filter: any = {
      type: {wildcard: `*${typeFilter}*`},
      companyID: {eq: tenantID},
    };
    if (!newSearch && keySearch) filter.name = {match: keySearch};
    const queryParams = {
      limit: countPerPage,
      filter,
      sort: {direction: 'desc', field: 'updatedAt'},
      from: (_page - 1) * countPerPage,
    };
    const response = (await API.graphql(
      graphqlOperation(searchContents, queryParams) as any,
    )) as any;
    setData(response.data.searchContents.items);

    if (_page === 1 && tenantID) {
      const total = await getContentsCount(filter);
      setTotal(total);
    }

    setIsLoading(false);
  };

  const addContent = async () => {
    const res = (await API.graphql(
      graphqlOperation(createContent, {
        input: {
          type: 'text',
          name,
          companyID: tenantID,
          enabledLanguages: languages.map(l => ({
            label: l.label,
            code: l.code,
            autoTranslate: true,
          })),
        },
      }),
    )) as any; // equivalent to above example

    fetchData();
    onClose();
    navigate(`/content/${res.data.createContent.id}`);
  };

  const onChangeFile = async (e: any) => {
    setUploadStatus('uploading');
    const file = e.target.files[0];
    const timestamp = new Date().toISOString();
    const result = await Storage.put(file.name + timestamp, file);
    setStorageKey(result.key);
    console.log(result);
    setUploadStatus('complete');
  };

  const uploadFile = async () => {
    const content = (await API.graphql(
      graphqlOperation(createContent, {
        input: {
          type: 'file',
          name: fileKey,
          companyID: tenantID,
        },
      }),
    )) as any; // equivalent to above example

    (await API.graphql(
      graphqlOperation(createVersion, {
        input: {
          contentID: content.data.createContent.id,
          name: '1',
          type: 'file',
          value: storageKey,
        },
      }) as any,
    )) as any;

    fetchData();
    setUploadStatus('notStarted');
    onCloseFile();
    navigate(`/content/${content.data.createContent.id}`);
  };
  const uploadImage = async () => {
    const content = (await API.graphql(
      graphqlOperation(createContent, {
        input: {
          type: 'image',
          name: imageKey,
          companyID: tenantID,
        },
      }),
    )) as any; // equivalent to above example

    (await API.graphql(
      graphqlOperation(createVersion, {
        input: {
          contentID: content.data.createContent.id,
          name: '1',
          type: 'image',
          value: storageKey,
        },
      }) as any,
    )) as any;

    fetchData();
    setUploadStatus('notStarted');
    onCloseImage();
    navigate(`/content/${content.data.createContent.id}`);
  };

  const uploadVideo = async () => {
    const content = (await API.graphql(
      graphqlOperation(createContent, {
        input: {
          type: 'video',
          name: videoKey,
          companyID: tenantID,
        },
      }),
    )) as any; // equivalent to above example

    (await API.graphql(
      graphqlOperation(createVersion, {
        input: {
          contentID: content.data.createContent.id,
          name: '1',
          type: 'video',
          value: storageKey,
        },
      }) as any,
    )) as any;

    fetchData();
    setUploadStatus('notStarted');
    onCloseVideo();
    navigate(`/content/${content.data.createContent.id}`);
  };

  const {isOpen, onOpen, onClose} = useDisclosure();
  const {
    isOpen: isOpenFile,
    onOpen: onOpenFile,
    onClose: onCloseFile,
  } = useDisclosure();
  const {
    isOpen: isOpenImage,
    onOpen: onOpenImage,
    onClose: onCloseImage,
  } = useDisclosure();
  const {
    isOpen: isOpenVideo,
    onOpen: onOpenVideo,
    onClose: onCloseVideo,
  } = useDisclosure();
  const {
    isOpen: isOpenCSV,
    onOpen: onOpenCSV,
    onClose: onCloseCSV,
  } = useDisclosure();
  const {
    isOpen: isOpenCSVConfig,
    onOpen: onOpenCSVConfig,
    onClose: onCloseCSVConfig,
  } = useDisclosure();

  return (
    <>
      {isLoading && <PageLoading />}
      <PageContainer>
        <PageHeader
          title={translatedData['content']}
          description={translatedData['contents-description']}
          TitleIcon={FiEdit}
          HeaderActions={
            <Dropdown
              buttonText={translatedData['add-content']}
              handleClick={onOpen}
              handleClickUploadFile={onOpenFile}
              handleClickImportCSV={onOpenCSV}
              handleClickUploadImage={onOpenImage}
              handleClickUploadVideo={onOpenVideo}
            />
          }
        />

        <Flex flexGrow="unset" gap={2} justifyContent="flex-end">
          <Select width={120} onChange={e => setTypeFilter(e.target.value)}>
            {/* <option value="">All</option> */}
            <option value="text">{translatedData['text']}</option>
            <option value="video">{translatedData['video']}</option>
            <option value="image">{translatedData['image']}</option>
            <option value="file">{translatedData['file']}</option>
          </Select>
          <InputGroup width={200}>
            <InputLeftElement pointerEvents="none">
              <FiSearch color="gray.300" />
            </InputLeftElement>
            <Input
              value={keySearch}
              placeholder={translatedData['search-keys']}
              onKeyPress={e => e.key === 'Enter' && fetchData()}
              onChange={e => setKeySearch(e.target.value)}
            />
            {keySearch && (
              <InputRightElement>
                <CloseButton
                  onClick={() => {
                    setKeySearch('');
                    fetchData(true, true);
                  }}
                />
              </InputRightElement>
            )}
          </InputGroup>
          <Button paddingX={10} onClick={() => fetchData()}>
            {translatedData['search']}
          </Button>
        </Flex>
        {/* <Dropzone /> */}
        <Box>
          <StyledTableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{translatedData['key-identifier']}</Th>
                  <Th width={50}>{translatedData['language']}</Th>
                  <Th width={50} style={{textAlign: 'center'}}>
                    {translatedData['updated-at']}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.length > 0 ? (
                  data.map((itm: any) => (
                    <StyledTr
                      key={itm?.id}
                      onClick={() => navigate(`/content/${itm.id}`)}>
                      <Td whiteSpace="break-spaces" wordBreak="break-all">
                        {itm.name}
                      </Td>
                      <Td textAlign="center">{itm.enabledLanguages?.length}</Td>
                      <Td textAlign="right">
                        {formatRelative(parseISO(itm.updatedAt), new Date())}
                      </Td>
                    </StyledTr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={3} textAlign="center">
                      {translatedData['no-results-found']}
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </StyledTableContainer>
          <Flex
            className="paginator-container"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            gap={3}>
            <Paginator
              pagesQuantity={Math.floor((total - 1) / countPerPage) + 1}
              activeStyles={{
                w: 9,
                bg: 'pink.900',
                color: 'white',
                _hover: {bg: 'pink.800'},
              }}
              normalStyles={{w: 9}}
              currentPage={page}
              onPageChange={setPage}
              outerLimit={1}
              innerLimit={1}>
              <Container align="center" justify="space-between" w="full" p={4}>
                <Previous>
                  <FaAngleLeft />
                </Previous>
                <PageGroup isInline align="center" />
                <Next>
                  <FaAngleRight />
                </Next>
              </Container>
            </Paginator>
            <Flex alignItems={'center'} gap={5} ml="auto">
              <Text fontWeight="bold">{translatedData['show']}: </Text>
              <Select
                width={120}
                onChange={e => setCountPerPage(parseInt(e.target.value))}
                value={countPerPage}>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </Select>
            </Flex>
          </Flex>
        </Box>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{translatedData['add-content']}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl id="name">
                <FormLabel>{translatedData['key-identifier']}</FormLabel>
                <Input onChange={e => setName(e.target.value)} />
                <FormHelperText>
                  {translatedData['content-key-description']}
                </FormHelperText>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                {translatedData['close']}
              </Button>
              <Button colorScheme="blue" onClick={addContent}>
                {translatedData['save']}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <CSVImport
          tenantID={tenantID}
          isOpen={isOpenCSV}
          onClose={onCloseCSV}
          isOpenConfig={isOpenCSVConfig}
          openConfig={onOpenCSVConfig}
          onCloseConfig={onCloseCSVConfig}
          fetchData={fetchData}
        />

        <AddFile
          isOpen={isOpenFile}
          onClose={onCloseFile}
          onChangeFile={onChangeFile}
          uploadFile={uploadFile}
          setFileKey={setFileKey}
          uploadStatus={uploadStatus}
          accept=".txt,.css,.js"
        />
        <AddFile
          isOpen={isOpenImage}
          onClose={onCloseImage}
          onChangeFile={onChangeFile}
          uploadFile={uploadImage}
          setFileKey={setImageKey}
          uploadStatus={uploadStatus}
          accept=".png,.jpg,.jpeg"
        />
        <AddFile
          isOpen={isOpenVideo}
          onClose={onCloseVideo}
          onChangeFile={onChangeFile}
          uploadFile={uploadVideo}
          setFileKey={setVideoKey}
          uploadStatus={uploadStatus}
          accept=".mp4,.mov"
        />
      </PageContainer>
    </>
  );
};
