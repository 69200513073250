import API from '@aws-amplify/api';
import {graphqlOperation} from '@aws-amplify/api-graphql';
import {Icon} from '@chakra-ui/icons';
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Switch,
  Text,
  theme,
} from '@chakra-ui/react';
import {LanguageContext} from 'App';
import {PageContainer} from 'components/page/page-container';
import {PageHeader} from 'components/page/page-header-section';
import {PageLoading} from 'components/page/page-loading';
import ConfirmDelete from 'components/utils/confirm-delete';
import {deleteWidget, updateWidget} from 'graphql/mutations';
import {listWidgets} from 'graphql/queries';
import {useContext, useEffect, useState} from 'react';
import {
  FiEye,
  FiLayout,
  FiMoreVertical,
  FiSettings,
  FiTrash2,
} from 'react-icons/fi';
import {MdOutlineWidgets} from 'react-icons/md';
import {IoIosAddCircleOutline} from 'react-icons/io';
import {useNavigate} from 'react-router';
import styled from 'styled-components';
import BannerAppearance from './_banner-appearance';

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  gap: 15px;
`;
const StyledMenuItemRight = styled(Box)`
  width: 36px;
  display: flex;
  justify-content: center;
`;

export const WidgetsScreen = () => {
  const {translatedData} = useContext(LanguageContext);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [widgets, setWidgets] = useState([]);
  const [deleteWidgetId, setDeleteWidgetId] = useState('');
  const [bannerWidget, setBannerWidget] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = (await API.graphql(
      graphqlOperation(listWidgets, {}) as any,
    )) as any;
    const data = response.data.listWidgets.items as any;
    data.sort((a: any, b: any) => (a.createdAt > b.createdAt ? -1 : 1));
    setWidgets(data);
    setIsLoading(false);
  };

  const goDetail = (widget: any) => {
    navigate(`/widget/${widget.id}`);
  };

  const handleDeleteWidget = async () => {
    setIsLoading(true);
    await API.graphql(
      graphqlOperation(deleteWidget, {
        input: {id: deleteWidgetId},
      }) as any,
    );

    setDeleteWidgetId('');
    fetchData();
  };

  const toggleDisable = async (widget: any, checked: boolean) => {
    await API.graphql(
      graphqlOperation(updateWidget, {
        input: {
          id: widget.id,
          disabled: !checked,
        },
      }) as any,
    );

    widget.disabled = !checked;
    setWidgets([...widgets]);
  };

  const toggleMonitoring = async (widget: any, checked: boolean) => {
    await API.graphql(
      graphqlOperation(updateWidget, {
        input: {
          id: widget.id,
          enableMonitoring: checked,
        },
      }) as any,
    );

    widget.enableMonitoring = checked;
    setWidgets([...widgets]);
  };

  const saveBannerAppearance = async (
    widget: any,
    bannerColor: string,
    bannerBorderColor: string,
    hideBanner: boolean,
    hideLogo: boolean,
  ) => {
    await API.graphql(
      graphqlOperation(updateWidget, {
        input: {
          id: widget.id,
          bannerColor,
          bannerBorderColor,
          hideBanner,
          hideLogo,
        },
      }) as any,
    );
    widget.bannerColor = bannerColor;
    widget.bannerBorderColor = bannerBorderColor;
    widget.hideBanner = hideBanner;
    widget.hideLogo = hideLogo;
    setWidgets([...widgets]);
  };

  return (
    <PageContainer>
      {isLoading && <PageLoading />}
      <PageHeader
        title={translatedData['web-widgets']}
        description={translatedData['widgets-description']}
        TitleIcon={MdOutlineWidgets}
        HeaderActions={
          <Button
            colorScheme="pink"
            bg={'pink.900'}
            _hover={{bg: 'pink.800'}}
            onClick={() => navigate('/widget/create')}>
            {translatedData['add-widget']}
          </Button>
        }
      />
      <Box display="flex" gap="15px" flexWrap="wrap">
        {widgets.map((widget: any, idx: number) => (
          <Box
            key={idx}
            width="300px"
            bgColor="gray.100"
            p="15px"
            cursor="pointer"
            onClick={() => goDetail(widget)}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center">
              <Icon as={FiLayout} boxSize={6} />
              <Box onClick={e => e.stopPropagation()}>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<FiMoreVertical />}
                    variant="outline"
                  />
                  <MenuList>
                    <StyledMenuItem>
                      <Text>{translatedData['enable-widget']}</Text>
                      <StyledMenuItemRight onClick={e => e.stopPropagation()}>
                        <Switch
                          isChecked={!widget.disabled}
                          onChange={e => {
                            toggleDisable(widget, e.target.checked);
                          }}
                        />
                      </StyledMenuItemRight>
                    </StyledMenuItem>
                    <StyledMenuItem>
                      <Text>{translatedData['widget-monitoring']}</Text>
                      <StyledMenuItemRight onClick={e => e.stopPropagation()}>
                        <Switch
                          isChecked={widget.enableMonitoring}
                          onChange={e => {
                            toggleMonitoring(widget, e.target.checked);
                          }}
                        />
                      </StyledMenuItemRight>
                    </StyledMenuItem>
                    <MenuDivider />

                    <StyledMenuItem onClick={() => setBannerWidget(widget)}>
                      <Text>{translatedData['banner-appearance']}</Text>
                      <StyledMenuItemRight>
                        <FiSettings />
                      </StyledMenuItemRight>
                    </StyledMenuItem>
                    <StyledMenuItem>
                      <Text>{translatedData['widget-interactions']}</Text>
                      <StyledMenuItemRight>
                        <FiEye />
                      </StyledMenuItemRight>
                    </StyledMenuItem>
                    <MenuDivider />
                    <StyledMenuItem
                      onClick={e => {
                        e.stopPropagation();
                        setDeleteWidgetId(widget.id);
                      }}>
                      <Text>{translatedData['delete-widget']}</Text>
                      <StyledMenuItemRight>
                        <FiTrash2 />
                      </StyledMenuItemRight>
                    </StyledMenuItem>
                  </MenuList>
                </Menu>
              </Box>
            </Box>
            <Text fontWeight="bold">{widget.name}</Text>

            <Text>{widget.description || ''}</Text>
          </Box>
        ))}

        <Box
          width="300px"
          bgColor="white.100"
          border={`1px dashed ${theme.colors.gray[500]}`}
          p="15px"
          cursor="pointer"
          onClick={() => navigate('/widget/create')}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column">
          <IoIosAddCircleOutline stroke="gray.500" />
          <Text fontWeight="bold" color="gray.500">
            {translatedData['create-new-widget']}
          </Text>
        </Box>
      </Box>
      <BannerAppearance
        isOpen={!!bannerWidget}
        onClose={() => setBannerWidget(null)}
        widget={bannerWidget}
        save={saveBannerAppearance}
      />
      <ConfirmDelete
        header={translatedData['delete-widget']}
        isOpen={!!deleteWidgetId}
        onClose={() => setDeleteWidgetId('')}
        isSaving={isLoading}
        confirm={handleDeleteWidget}
      />
    </PageContainer>
  );
};
