import React, {useContext, useEffect, useState} from 'react';
import API from '@aws-amplify/api';
import {graphqlOperation} from '@aws-amplify/api-graphql';
import {Button} from '@chakra-ui/button';
import {useDisclosure} from '@chakra-ui/hooks';
import {Spacer} from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import {FormControl, FormLabel, Input, Select} from '@chakra-ui/react';
import {Table, TableCaption, Tbody, Td, Th, Thead, Tr} from '@chakra-ui/table';
import {TenantContext, LanguageContext} from 'App';
import {PageLoading} from 'components/page/page-loading';
import {createApplication} from 'graphql/mutations';
import {listApplications} from 'graphql/queries';
import {useNavigate} from 'react-router';
import {PageContainer} from 'components/page/page-container';
import {StyledTableContainer, StyledTr} from 'components/ui/table/table';
import {parseKey} from 'utils/key-manager';
import {formatRelative, parseISO} from 'date-fns';
import {Application} from 'API';
import {PageHeader} from 'components/page/page-header-section';
import {AiFillAppstore} from 'react-icons/ai';

const availableTypes = ['Web Application', 'Mobile App', 'Marketing Site'];

export const ApplicationsScreen = () => {
  const {translatedData} = useContext(LanguageContext);
  const navigate = useNavigate();
  const {isOpen, onOpen, onClose} = useDisclosure();

  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState('');
  const [type, setType] = useState(0);
  const [applications, setApplications] = useState([]);

  const tenantID = useContext(TenantContext);

  const handleAddApplication = async () => {
    setIsSaving(true);

    (await API.graphql(
      graphqlOperation(createApplication, {
        input: {
          companyID: tenantID,
          name,
          type: availableTypes[type],
        },
      }) as any,
    )) as any;
    setIsSaving(true);
    await fetchData();
    setIsSaving(false);
    onClose();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = (await API.graphql(
      graphqlOperation(listApplications, {}) as any,
    )) as any;
    console.log(response.data);
    const data = (response.data.listApplications.items ?? []) as any;
    data.sort((a: Application, b: Application) =>
      a.createdAt > b.createdAt ? -1 : 1,
    );
    setApplications(data);
    setIsLoading(false);
  };

  return (
    <PageContainer>
      <PageHeader
        title={translatedData['applications']}
        description={translatedData['applications-description']}
        TitleIcon={AiFillAppstore}
        HeaderActions={
          <Button
            colorScheme="pink"
            bg={'pink.900'}
            _hover={{bg: 'pink.800'}}
            onClick={onOpen}>
            {translatedData['add-application']}
          </Button>
        }
      />
      {isLoading ? (
        <PageLoading />
      ) : (
        <StyledTableContainer>
          <Table variant="simple">
            <TableCaption>{translatedData['all-applications']}</TableCaption>
            <Thead>
              <Tr>
                <Th>{translatedData['name']}</Th>
                <Th>{translatedData['type']}</Th>
                <Th>{translatedData['created-at']}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {applications.length > 0 ? (
                applications.map((application: any) => (
                  <StyledTr
                    onClick={() => navigate(`/applications/${application.id}`)}
                    key={application.id}>
                    <Td>{application.name}</Td>
                    <Td>
                      {
                        translatedData[
                          parseKey(application.type || availableTypes[0])
                        ]
                      }
                    </Td>
                    <Td style={{textAlign: 'right'}}>
                      {formatRelative(
                        parseISO(application.createdAt),
                        new Date(),
                      )}
                    </Td>
                  </StyledTr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={3} textAlign="center">
                    {translatedData['no-results-found']}
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </StyledTableContainer>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{translatedData['new-application']}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="text">
              <FormLabel>{translatedData['name']}</FormLabel>
              <Input
                type="text"
                onChange={e => setName(e.target.value)}
                placeholder={translatedData['enter-application']}
              />
            </FormControl>
            <Spacer />
            <FormControl id="type">
              <FormLabel>{translatedData['type']}</FormLabel>
              <Select
                onChange={e => setType(parseInt(e.target.value))}
                value={type}>
                {availableTypes.map((itm, idx) => (
                  <option value={idx} key={idx}>
                    {translatedData[parseKey(itm)]}
                  </option>
                ))}
              </Select>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              {translatedData['close']}
            </Button>
            <Button
              colorScheme="pink"
              isLoading={isSaving}
              onClick={handleAddApplication}>
              {translatedData['save']}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </PageContainer>
  );
};
