import {API, Auth, graphqlOperation} from 'aws-amplify';
import React, {useEffect, useState, useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {
  Avatar,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Box,
  Text,
} from '@chakra-ui/react';
import {FiHelpCircle} from 'react-icons/fi';
import {TbCoins} from 'react-icons/tb';
import styled from 'styled-components';
import {Colors} from '../../utils/colors';
import {LanguageContext} from 'App';
import NavbarTour from 'components/utils/navbar-tour';
import NotificationHeader from 'components/utils/notification-header';
import {useAuth} from 'Auth';
import {getCompany} from 'graphql/queries';
import {formatNumber} from 'utils/helper';

const TopBar = styled.div`
  display: flex;
  padding: 4px;
  background-color: ${Colors.black};
  color: ${Colors.white};
  align-items: center;
`;
const Logo = styled.div`
  flex: 0 1 150px;
  margin-left: 8px;
  background-color: ${Colors.black};
  color: ${Colors.white};
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
`;
const Space = styled.div`
  flex: 1 1 auto;
`;

const UserMenuButton = styled(MenuButton)`
  margin: 4px 4px;
`;
const StyledButton = styled(MenuItem)`
  && {
    color: ${Colors.black};
  }
`;
const CustomMenuItem = styled(Box)`
  cursor: pointer;
  margin-right: 15px;
`;
const CreditsLabel = styled(Link)`
  display: flex;
  align-items: center;
  gap: 7px;
  margin-right: 30px;
  font-weight: bold;
  color: #f5ce97;
`;

interface User {
  email?: string;
}

declare global {
  interface Window {
    Intercom: any;
  }
}

// const Intercom = window.Intercom;

export const Header = () => {
  const {auth} = useAuth();
  const [user, setUser] = useState<User>({});
  const [credits, setCredits] = useState(0);
  const navigate = useNavigate();
  const {translatedData} = useContext(LanguageContext);
  const [startTour, setStartTour] = useState(false);

  const getUser = async () => {
    const user = (await Auth.currentAuthenticatedUser()) as any;
    setUser(user.attributes);
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'vql43ntd',
      name: user.attributes.email, // Full name
      email: user.attributes.email, // Email address
      phone_number: user.attributes.phone_number, // Signup date as a Unix timestamp
      user_id: user.attributes.sub,
    });
  };

  const fetchCredits = async () => {
    const tenantId =
      auth.user?.attributes && auth.user?.attributes['custom:tenantId'];
    if (!tenantId) return;
    const response = (await API.graphql(
      graphqlOperation(getCompany, {id: tenantId}) as any,
    )) as any;
    const company = response?.data?.getCompany as any;

    setCredits(company?.credits || 0);
  };

  useEffect(() => {
    getUser();
    fetchCredits();
  }, []);

  const signOut = async () => {
    try {
      await Auth.signOut();
      // eslint-disable-next-line
      window.location.href = window.location.href;
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };
  return (
    <TopBar>
      <Logo>
        <Link to="/">
          <img
            alt="logo"
            src={require('../../assets/img/fluentc-white-logo.png')}
            width="100px"
          />
        </Link>
      </Logo>
      {/* <TranslateInput placeholder="Search" /> */}
      <Space />
      <CreditsLabel to="/billing">
        <TbCoins size={20} />
        <Box sx={{display: 'flex', alignItems: 'center', gap: '5px'}}>
          <Text fontWeight="bold">{formatNumber(credits)}</Text>
          <Text>{translatedData['credits']}</Text>
        </Box>
      </CreditsLabel>
      <CustomMenuItem onClick={() => setStartTour(true)}>
        <FiHelpCircle size={20} />
      </CustomMenuItem>
      <NotificationHeader />
      <Menu>
        <UserMenuButton>
          <Avatar name={user.email} src="" size="sm" />
        </UserMenuButton>
        <MenuList>
          <StyledButton onClick={() => navigate('/settings')}>
            {translatedData['account-settings']}
          </StyledButton>
          <StyledButton onClick={() => navigate('/apikey')}>
            {translatedData['api-keys']}
          </StyledButton>
          <StyledButton onClick={() => navigate('/billing')}>
            {translatedData['billing']}
          </StyledButton>
          <MenuDivider />
          <StyledButton onClick={signOut}>
            {translatedData['signout']}
          </StyledButton>
        </MenuList>
      </Menu>
      <NavbarTour
        type="manual"
        start={startTour}
        onEnd={() => setStartTour(false)}
      />
    </TopBar>
  );
};
