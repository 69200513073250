import React, {useContext, useEffect, useState} from 'react';
import API from '@aws-amplify/api';
import {graphqlOperation} from '@aws-amplify/api-graphql';

import {updateWidget} from 'graphql/mutations';
import {LanguageContext} from 'App';
import {
  Badge,
  Box,
  Button,
  Code,
  Heading,
  FormControl,
  IconButton,
  Input,
  Text,
} from '@chakra-ui/react';
import validator from 'validator';
import {FiX} from 'react-icons/fi';

export const WidgetSecurity = (props: any) => {
  const {hidden, widget, submit, submitLabel} = props;
  const {translatedData} = useContext(LanguageContext);

  const [isSaving, setIsSaving] = useState(false);
  const [urls, setUrls] = useState<any>([]);
  const [tags, setTags] = useState<any>([]);

  useEffect(() => {
    if (!widget) return;
    setUrls(
      (widget?.urls || []).map((url: string) => ({
        value: url,
        type: getUrlType(url),
      })),
    );
    setTags(
      (widget?.tags || []).map((tag: string) => ({
        value: tag,
        type: getTagType(tag),
      })),
    );
  }, [widget]);

  const addUrl = () => {
    urls.push({value: '', type: ''});
    setUrls([...urls]);
  };
  const addTag = () => {
    tags.push({value: '', type: ''});
    setTags([...tags]);
  };

  const getUrlType = (url: string) => {
    if (!url) return '';
    if (validator.isIP(url)) return translatedData['ip-address'];
    if (validator.isURL(url, {require_protocol: true}))
      return translatedData['website-url'];
    if (url.substring(0, 16).toLocaleLowerCase() === 'http://localhost')
      return translatedData['website-url'];
    return '';
  };

  const getTagType = (tag: string) => {
    if (!tag) return '';
    if (tag[0] === '.') return translatedData['class-name'];
    if (tag[0] === '#') return translatedData['element-id'];
    return translatedData['tag-name'];
  };

  const submitClicked = async () => {
    setIsSaving(true);
    const widgetResponse = (await API.graphql(
      graphqlOperation(updateWidget, {
        input: {
          id: widget.id,
          urls: urls.map((url: any) => url.value),
          tags: tags.map((tag: any) => tag.value),
          step: Math.max(widget?.step || 0, 3),
        },
      }) as any,
    )) as any;
    const wid = widgetResponse?.data?.updateWidget;
    if (!wid) return;
    submit(wid.id);
    setIsSaving(false);
  };

  return (
    <Box display={hidden ? 'none' : 'block'}>
      <Heading size="md">{translatedData['security-settings']}</Heading>
      <Box mt={3}>
        <Text fontWeight="medium">{translatedData['source-urls']}</Text>
        <Text color="gray.500" fontSize="sm" whiteSpace="break-spaces">
          {translatedData['widget-source-url-description']}
        </Text>
        <Text mt={2} color="gray.500" fontSize="sm" whiteSpace="break-spaces">
          {translatedData['widget-source-url-description-2']}
        </Text>
        <Box>
          {urls.map((url: any, idx: number) => (
            <FormControl key={idx} isInvalid={!url.type} mt={2}>
              <Box display="flex" alignItems="center" gap={2}>
                <Input
                  width={250}
                  size="sm"
                  value={url.value}
                  onChange={e => {
                    url.value = e.target.value;
                    url.type = getUrlType(urls[idx].value);
                    setUrls([...urls]);
                  }}
                />
                <IconButton
                  aria-label="remove"
                  size="sm"
                  icon={<FiX />}
                  onClick={() => {
                    urls.splice(idx, 1);
                    setUrls([...urls]);
                  }}
                />
                {url.type ? (
                  <Badge ml={4} colorScheme="pink">
                    {url.type}
                  </Badge>
                ) : (
                  <Badge ml={4} colorScheme="red">
                    {translatedData['invalid']}
                  </Badge>
                )}
              </Box>
            </FormControl>
          ))}
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button variant="ghost" colorScheme="pink" size="sm" onClick={addUrl}>
            {translatedData['add-new']}
          </Button>
        </Box>
      </Box>
      <Box>
        <Text fontWeight="medium">{translatedData['no-translation-tags']}</Text>
        <Text color="gray.500" fontSize="sm" whiteSpace="break-spaces">
          {translatedData['widget-no-translation-description']}
        </Text>
        <Text mt={2} color="gray.500" fontSize="sm" whiteSpace="break-spaces">
          {translatedData['widget-no-translation-description-2']}
        </Text>
        <Box display="flex" gap={2}>
          <Code>tag</Code>
          <Code>.brand-logo</Code>
          <Code>#brand-id</Code>
        </Box>
        <Box>
          {tags.map((tag: any, idx: number) => (
            <FormControl key={idx} isInvalid={!tag.type} mt={2}>
              <Box display="flex" alignItems="center" gap={2}>
                <Input
                  width={250}
                  size="sm"
                  value={tag.value}
                  onChange={e => {
                    tag.value = e.target.value;
                    tag.type = getTagType(tag.value);
                    setTags([...tags]);
                  }}
                />
                <IconButton
                  aria-label="remove"
                  size="sm"
                  icon={<FiX />}
                  onClick={() => {
                    tags.splice(idx, 1);
                    setTags([...tags]);
                  }}
                />
                {tag.type ? (
                  <Badge ml={4} colorScheme="pink">
                    {tag.type}
                  </Badge>
                ) : (
                  <Badge ml={4} colorScheme="red">
                    {translatedData['invalid']}
                  </Badge>
                )}
              </Box>
            </FormControl>
          ))}
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button variant="ghost" colorScheme="pink" size="sm" onClick={addTag}>
            {translatedData['add-new']}
          </Button>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" gap={2} mt={5}>
        {submit && (
          <Button
            colorScheme="pink"
            bg={'pink.900'}
            _hover={{bg: 'pink.800'}}
            disabled={
              !!urls.filter((url: any) => !url.type).length ||
              !!tags.filter((tag: any) => !tag.type).length
            }
            isLoading={isSaving}
            onClick={submitClicked}>
            {submitLabel}
          </Button>
        )}
      </Box>
    </Box>
  );
};
