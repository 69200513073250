import React, {useContext} from 'react';
import {Box} from '@chakra-ui/layout';
import {LanguageContext} from 'App';

import GraphQLPlayground from 'components/page/GraphQLPlayground';
import {PageContainer} from 'components/page/page-container';
import {PageHeader} from 'components/page/page-header-section';
import {GrGraphQl} from 'react-icons/gr';

export const PlayGroundScreen = () => {
  const {translatedData} = useContext(LanguageContext);

  return (
    <PageContainer>
      <PageHeader
        title={translatedData['playground']}
        description={
          <>
            {translatedData['playground-description-1']}
            <br />
            <br />
            {translatedData['playground-description-2']}
          </>
        }
        TitleIcon={GrGraphQl}
      />
      <Box height={650}>
        <GraphQLPlayground environment={null} />
      </Box>
    </PageContainer>
  );
};
