import {useState} from 'react';
import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import {FiEye, FiEyeOff} from 'react-icons/fi';

export default function PasswordInput(props: any) {
  const [show, setShow] = useState(false);
  return (
    <InputGroup>
      <Input {...props} type={show ? 'text' : 'password'} />
      <InputRightElement>
        <IconButton
          variant="ghost"
          size="sm"
          aria-label=""
          onClick={() => setShow(!show)}
        >
          {show ? <FiEyeOff /> : <FiEye />}
        </IconButton>
      </InputRightElement>
    </InputGroup>
  );
}
