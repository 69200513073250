import React, {useCallback, useEffect, useState, useContext} from 'react';
import API from '@aws-amplify/api';
import {graphqlOperation} from '@aws-amplify/api-graphql';
import {Button} from '@chakra-ui/button';
import {useDisclosure} from '@chakra-ui/hooks';
import {Flex, Heading, Text} from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import {
  TableContainer,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/table';
import {PageActions} from 'components/page/page-actions';
import {PageHeader} from 'components/page/page-header';
import {PageLoading} from 'components/page/page-loading';
import {StyledTr} from 'components/ui/table/table';
import {formatRelative, parseISO} from 'date-fns';
import {useForm} from 'react-hook-form';
import {
  searchEnvironmentContents,
  searchTotalContents,
  createEnvironmentVersion,
  deleteEnvironmentVersion,
} from 'graphql/custom';
import {useAuth} from 'Auth';
import {LanguageContext} from 'App';
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Switch,
  useToast,
  Select,
} from '@chakra-ui/react';
import {
  Container,
  Next,
  PageGroup,
  Paginator,
  Previous,
} from 'chakra-paginator';
import {FiSearch} from 'react-icons/fi';
import {FaAngleLeft, FaAngleRight} from 'react-icons/fa';

export function LinkContent({environment, onUpdated}: any) {
  const {translatedData} = useContext(LanguageContext);

  const {register, handleSubmit} = useForm();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);
  const [isEditSaving, setIsEditSaving] = useState(false);
  const [contents, setContents] = useState<any>([]);
  const [editContent, setEditContent] = useState<any>({});

  //Pagination states
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [countPerPage, setCountPerPage] = useState(10);
  const [typeFilter, setTypeFilter] = useState('');
  const [keySearch, setKeySearch] = useState('');

  const [tenantID, setTenantID] = useState('');
  // const tenantID = useContext(TenantContext);
  const {auth} = useAuth();
  const toast = useToast();

  const showToast = (text: string) => {
    toast({
      description: text,
      status: 'warning',
      duration: 4000,
    });
  };

  const handleOpenLinkModal = () => {
    onOpen();
  };

  const handleEditLinkedVersion = async (data: any) => {
    const envVerId = environment.versions.items.find(
      (i: any) => i.version.content.id === editContent.id,
    );
    if (envVerId.version.id === data.versionId) {
      showToast('Same version');
      return;
    }
    if (!data.versionId) {
      showToast('Select the version first');
      return;
    }
    if (
      editContent.versions.items.findIndex(
        (v: any) => v.id === data.versionId,
      ) === -1
    )
      return;

    setIsEditSaving(true);

    console.log(envVerId.id);
    // delete environment version
    const response = (await API.graphql(
      graphqlOperation(deleteEnvironmentVersion, {
        input: {
          id: envVerId.id,
        },
      }) as any,
    )) as any;

    console.log(response);

    // // add environment version
    const response2 = (await API.graphql(
      graphqlOperation(createEnvironmentVersion, {
        input: {
          environmentID: environment.id,
          versionID: data.versionId,
        },
      }) as any,
    )) as any;

    console.log(response2);
    onUpdated({
      deleted: response.data.deleteEnvironmentVersion,
      created: response2.data.createEnvironmentVersion,
    });
    setIsEditSaving(false);
    onEditClose();
  };

  const handleDeleteLinkedVersion = async () => {
    const envVerId = environment.versions.items.find(
      (i: any) => i.version.content.id === editContent.id,
    );

    setIsEditSaving(true);

    console.log(envVerId.id);
    // delete environment version
    const response = (await API.graphql(
      graphqlOperation(deleteEnvironmentVersion, {
        input: {
          id: envVerId.id,
        },
      }) as any,
    )) as any;

    console.log(response);

    onUpdated({
      deleted: response.data.deleteEnvironmentVersion,
    });
    setIsEditSaving(false);
    onEditClose();
  };

  const getContentsCount = async (filter: any) => {
    let total = 0,
      nextToken;
    do {
      const response = (await API.graphql(
        graphqlOperation(searchTotalContents, {
          filter,
          nextToken,
          limit: 1000,
        }) as any,
      )) as any;
      console.log('total response', response);
      const data = response.data.searchContents;
      total += data.total;
      nextToken = data.nextToken;
    } while (nextToken);
    return total;
  };

  const fetchData = useCallback(
    async (refresh = true) => {
      if (!tenantID) return;
      console.log(tenantID);

      setIsLoading(true);
      let _page = page;
      if (refresh) {
        setPage(1);
        _page = 1;
      }

      const filter: any = {
        type: {wildcard: `*${typeFilter}*`},
        companyID: {eq: tenantID},
      };
      if (keySearch) filter.name = {match: keySearch};
      const queryParams = {
        limit: countPerPage,
        filter,
        sort: {direction: 'desc', field: 'updatedAt'},
        from: (_page - 1) * countPerPage,
      };
      const response = (await API.graphql(
        graphqlOperation(searchEnvironmentContents, queryParams) as any,
      )) as any;
      const data = response.data.searchContents.items as any;

      for (let i = 0; i < data.length; i++) {
        data[i].selectedVersion = data[i].versions?.items[0]?.id || '';
      }
      console.log(data);
      setContents(data);

      if (_page === 1 && tenantID) {
        const total = await getContentsCount(filter);
        setTotal(total);
      }

      setIsLoading(false);
    },
    [tenantID, typeFilter, keySearch, countPerPage, page],
  );

  useEffect(() => {
    console.log('ff', auth.user.attributes['custom:tenantId']);
    if (auth?.user?.attributes['custom:isOnboarded'])
      setTenantID(auth.user.attributes['custom:tenantId']);
  }, [auth?.user]);

  useEffect(() => {
    fetchData();
  }, [tenantID, typeFilter, countPerPage]);

  useEffect(() => {
    fetchData(false);
  }, [page]);

  const selectedVersionChanged = (c: any, versionId: string) => {
    c.selectedVersion = versionId;
    setContents([...contents]);
  };

  const includeStateChanged = async (c: any, status: boolean) => {
    console.log(c, status);
    if (c.loading) return;
    if (status === getIncludeStatus(c)) return;
    if (status) {
      if (!c.selectedVersion) {
        showToast('Select the version first');
        return;
      }
      c.loading = true;
      const res = (await API.graphql(
        graphqlOperation(createEnvironmentVersion, {
          input: {
            environmentID: environment.id,
            versionID: c.selectedVersion,
          },
        }) as any,
      )) as any;
      console.log('create', res);
      onUpdated({created: res?.data?.createEnvironmentVersion});
      c.loading = false;
    } else {
      const version = environment.versions.items.find(
        (itm: any) => itm.version.content.id === c.id,
      );
      if (!version) return;
      c.loading = true;
      const res = (await API.graphql(
        graphqlOperation(deleteEnvironmentVersion, {
          input: {
            id: version.id,
          },
        }) as any,
      )) as any;
      console.log('remove', res);
      onUpdated({deleted: res?.data?.deleteEnvironmentVersion});
      c.loading = false;
    }
  };

  const getIncludeStatus = (c: any) => {
    return (
      environment.versions.items.findIndex(
        (i: any) => i.version.content.id === c.id,
      ) > -1
    );
  };

  return (
    <>
      {isLoading && <PageLoading />}
      <PageHeader>
        <Heading size="sm">{translatedData['linked-content']}</Heading>
        <PageActions>
          <Button onClick={handleOpenLinkModal}>
            {translatedData['import']}
          </Button>
        </PageActions>
      </PageHeader>

      <TableContainer>
        <Table variant="simple">
          <TableCaption>{translatedData['linked-content']}</TableCaption>
          <Thead>
            <Tr>
              <Th>{translatedData['key-identifier']}</Th>
              <Th>{translatedData['version']}</Th>
              <Th>{translatedData['value']}</Th>
              <Th>{translatedData['created-at']}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {environment.versions.items.length > 0 ? (
              environment.versions.items.map((i: any) => (
                <StyledTr
                  key={i.version.id}
                  onClick={() => {
                    console.log(i.version.content.id);
                    setEditContent(i.version.content);
                    onEditOpen();
                  }}>
                  <Td>{i.version.content.name}</Td>
                  <Td>{i.version.name}</Td>
                  <Td>{i.version.value}</Td>
                  <Td>
                    {formatRelative(parseISO(i.version.createdAt), new Date())}
                  </Td>
                </StyledTr>
              ))
            ) : (
              <Tr>
                <Td colSpan={4} textAlign="center">
                  {translatedData['no-results-found']}
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>

      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{translatedData['import-content']}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>{translatedData['select-content-to-link']}</p>
            <Flex flexGrow="unset" gap={2} justifyContent="flex-end">
              <Select width={120} onChange={e => setTypeFilter(e.target.value)}>
                {/* <option value="">All</option> */}
                <option value="text">{translatedData['text']}</option>
                <option value="video">{translatedData['video']}</option>
                <option value="image">{translatedData['image']}</option>
                <option value="file">{translatedData['file']}</option>
              </Select>
              <InputGroup width={200}>
                <InputLeftElement pointerEvents="none">
                  <FiSearch color="gray.300" />
                </InputLeftElement>
                <Input
                  placeholder={translatedData['search-keys']}
                  onKeyPress={e => e.key === 'Enter' && fetchData()}
                  onChange={e => setKeySearch(e.target.value)}
                />
              </InputGroup>
              <Button paddingX={10} onClick={() => fetchData()}>
                {translatedData['search']}
              </Button>
            </Flex>
            <TableContainer>
              <Table variant="simple">
                <TableCaption>{translatedData['content']}</TableCaption>
                <Thead>
                  <Tr>
                    <Th>{translatedData['key-identifier']}</Th>
                    <Th>{translatedData['type']}</Th>
                    <Th>{translatedData['language']}</Th>
                    <Th width={130}>{translatedData['version']}</Th>
                    <Th>{translatedData['updated-at']}</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {contents.length > 0 ? (
                    contents.map((c: any) => (
                      <StyledTr key={c.id}>
                        <Td>{c.name}</Td>
                        <Td>{c.type}</Td>
                        <Td>{c.enabledLanguages?.length || 0}</Td>
                        <Td>
                          <Select
                            placeholder={translatedData['select-version']}
                            value={c.selectedVersion}
                            onChange={e =>
                              selectedVersionChanged(c, e.target.value)
                            }>
                            {c.versions.items.map((v: any) => (
                              <option key={v.id} value={v.id}>
                                {v.name}
                              </option>
                            ))}
                          </Select>
                        </Td>
                        <Td>
                          {formatRelative(parseISO(c.updatedAt), new Date())}
                        </Td>
                        <Td>
                          <Switch
                            colorScheme="teal"
                            size="lg"
                            isChecked={getIncludeStatus(c)}
                            onChange={e =>
                              includeStateChanged(c, e.target.checked)
                            }
                          />
                        </Td>
                      </StyledTr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan={6} textAlign="center">
                        {translatedData['no-results-found']}
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
            <Flex
              className="paginator-container"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              gap={3}>
              <Paginator
                pagesQuantity={Math.floor((total - 1) / countPerPage) + 1}
                activeStyles={{
                  w: 9,
                  bg: 'pink.900',
                  color: 'white',
                  _hover: {bg: 'pink.800'},
                }}
                normalStyles={{w: 9}}
                currentPage={page}
                onPageChange={setPage}
                outerLimit={1}
                innerLimit={1}>
                <Container
                  align="center"
                  justify="space-between"
                  w="full"
                  p={4}>
                  <Previous>
                    <FaAngleLeft />
                  </Previous>
                  <PageGroup isInline align="center" />
                  <Next>
                    <FaAngleRight />
                  </Next>
                </Container>
              </Paginator>
              <Flex alignItems={'center'} gap={5} ml="auto">
                <Text fontWeight="bold">{translatedData['show']}: </Text>
                <Select
                  width={120}
                  onChange={e => setCountPerPage(parseInt(e.target.value))}
                  value={countPerPage}>
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                </Select>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              {translatedData['close']}
            </Button>
            {/* <Button colorScheme="pink" isLoading={isSaving} type="submit">
              {translatedData['import']}
            </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isEditOpen} onClose={onEditClose}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(data => handleEditLinkedVersion(data))}>
            <ModalHeader>{translatedData['edit-version']}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <p>{translatedData['select-version-you-like']}</p>
              {editContent ? (
                <Select
                  placeholder={translatedData['select-version']}
                  {...register(`versionId`)}>
                  {editContent?.versions?.items.map((v: any) => (
                    <option key={v.id} value={v.id}>
                      {v.name}
                    </option>
                  ))}
                </Select>
              ) : (
                <>{translatedData['no-data']}</>
              )}
            </ModalBody>

            <ModalFooter>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}>
                <Button
                  colorScheme="red"
                  isLoading={isEditSaving}
                  onClick={handleDeleteLinkedVersion}>
                  {translatedData['delete']}
                </Button>
                <Box sx={{display: 'flex', gap: 2}}>
                  <Button variant="ghost" mr={3} onClick={onEditClose}>
                    {translatedData['close']}
                  </Button>
                  <Button
                    colorScheme="pink"
                    isLoading={isEditSaving}
                    type="submit">
                    {translatedData['save']}
                  </Button>
                </Box>
              </Box>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}
