import {CircularProgress} from '@chakra-ui/progress';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 100000;
`;

export function PageLoading() {
  return (
    <Container>
      <CircularProgress color={'pink.900'} isIndeterminate />
    </Container>
  );
}
