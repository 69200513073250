/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const requestContent = /* GraphQL */ `
  query RequestContent(
    $environmentID: String!
    $language: String!
    $limit: Int
  ) {
    requestContent(
      environmentID: $environmentID
      language: $language
      limit: $limit
    ) {
      body {
        key
        type
        value
        translated
      }
    }
  }
`;
export const translate = /* GraphQL */ `
  query Translate(
    $apiKey: String!
    $sourceLanguage: String!
    $targetLanguage: String!
    $formality: String
    $labels: [String!]
  ) {
    translate(
      apiKey: $apiKey
      sourceLanguage: $sourceLanguage
      targetLanguage: $targetLanguage
      formality: $formality
      labels: $labels
    ) {
      body {
        sourceLanguage
        targetLanguage
        originalText
        translatedText
      }
    }
  }
`;
export const translateWidget = /* GraphQL */ `
  query TranslateWidget(
    $widgetID: String!
    $sourceLanguage: String!
    $targetLanguage: String!
    $autoTranslate: Boolean
    $labels: [String!]
    $host: String
  ) {
    translateWidget(
      widgetID: $widgetID
      sourceLanguage: $sourceLanguage
      targetLanguage: $targetLanguage
      autoTranslate: $autoTranslate
      labels: $labels
      host: $host
    ) {
      body {
        sourceLanguage
        targetLanguage
        originalText
        translatedText
      }
    }
  }
`;
export const detectLanguage = /* GraphQL */ `
  query DetectLanguage($text: String!) {
    detectLanguage(text: $text) {
      language
    }
  }
`;
export const getAvailableLanguages = /* GraphQL */ `
  query GetAvailableLanguages($lang: String, $environmentID: String) {
    getAvailableLanguages(lang: $lang, environmentID: $environmentID) {
      body {
        label
        code
        localLabel
      }
    }
  }
`;
export const fetchCheckoutUrl = /* GraphQL */ `
  query FetchCheckoutUrl(
    $host: String!
    $basic: Int!
    $bulk: Int!
    $premium: Int!
    $customerEmail: String!
  ) {
    fetchCheckoutUrl(
      host: $host
      basic: $basic
      bulk: $bulk
      premium: $premium
      customerEmail: $customerEmail
    ) {
      body
    }
  }
`;
export const fetchUsage = /* GraphQL */ `
  query FetchUsage($apiKey: String!) {
    fetchUsage(apiKey: $apiKey) {
      credits
      usage
      insertUsage
      widgetUsage
    }
  }
`;
export const insertLayer = /* GraphQL */ `
  query InsertLayer($apiKey: String!) {
    insertLayer(apiKey: $apiKey) {
      body
    }
  }
`;
export const inviteUserFromPlugins = /* GraphQL */ `
  query InviteUserFromPlugins(
    $apiKey: String!
    $email: String!
    $from: String
  ) {
    inviteUserFromPlugins(apiKey: $apiKey, email: $email, from: $from) {
      body
    }
  }
`;
export const fetchWidgetOptions = /* GraphQL */ `
  query FetchWidgetOptions($widgetID: String!, $host: String) {
    fetchWidgetOptions(widgetID: $widgetID, host: $host) {
      environmentID
      name
      display
      tags
      disabled
      bannerColor
      bannerBorderColor
      hideBanner
      hideLogo
      autoTranslate
      sourceLanguage
    }
  }
`;
export const fluentcCronNotify = /* GraphQL */ `
  query FluentcCronNotify {
    fluentcCronNotify {
      body
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      owner
      tenantID
      credits
      usage
      insertUsage
      widgetUsage
      freeTrialUsage
      createdAtUnix
      updatedAtUnix
      createdAt
      updatedAt
      users {
        items {
          id
          companyID
          name
          username
          email
          isOnboarded
          phone
          firstName
          lastName
          lang
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
      contents {
        items {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
      applications {
        items {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
      tokens {
        items {
          id
          tenantID
          apiKey
          creditsUsage
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owner
        tenantID
        credits
        usage
        insertUsage
        widgetUsage
        freeTrialUsage
        createdAtUnix
        updatedAtUnix
        createdAt
        updatedAt
        users {
          nextToken
        }
        contents {
          nextToken
        }
        applications {
          nextToken
        }
        tokens {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      companyID
      name
      username
      email
      isOnboarded
      phone
      firstName
      lastName
      lang
      createdAtUnix
      updatedAtUnix
      createdAt
      updatedAt
      company {
        id
        name
        owner
        tenantID
        credits
        usage
        insertUsage
        widgetUsage
        freeTrialUsage
        createdAtUnix
        updatedAtUnix
        createdAt
        updatedAt
        users {
          nextToken
        }
        contents {
          nextToken
        }
        applications {
          nextToken
        }
        tokens {
          nextToken
        }
      }
      tenantID
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyID
        name
        username
        email
        isOnboarded
        phone
        firstName
        lastName
        lang
        createdAtUnix
        updatedAtUnix
        createdAt
        updatedAt
        company {
          id
          name
          owner
          tenantID
          credits
          usage
          insertUsage
          widgetUsage
          freeTrialUsage
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
        }
        tenantID
      }
      nextToken
    }
  }
`;
export const usersByCompanyID = /* GraphQL */ `
  query UsersByCompanyID(
    $companyID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByCompanyID(
      companyID: $companyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyID
        name
        username
        email
        isOnboarded
        phone
        firstName
        lastName
        lang
        createdAtUnix
        updatedAtUnix
        createdAt
        updatedAt
        company {
          id
          name
          owner
          tenantID
          credits
          usage
          insertUsage
          widgetUsage
          freeTrialUsage
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
        }
        tenantID
      }
      nextToken
    }
  }
`;
export const getContent = /* GraphQL */ `
  query GetContent($id: ID!) {
    getContent(id: $id) {
      id
      companyID
      name
      type
      createdAt
      updatedAt
      enabledLanguages {
        label
        code
        autoTranslate
      }
      company {
        id
        name
        owner
        tenantID
        credits
        usage
        insertUsage
        widgetUsage
        freeTrialUsage
        createdAtUnix
        updatedAtUnix
        createdAt
        updatedAt
        users {
          nextToken
        }
        contents {
          nextToken
        }
        applications {
          nextToken
        }
        tokens {
          nextToken
        }
      }
      tenantID
      versions {
        items {
          id
          contentID
          name
          value
          type
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
    }
  }
`;
export const listContents = /* GraphQL */ `
  query ListContents(
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyID
        name
        type
        createdAt
        updatedAt
        enabledLanguages {
          label
          code
          autoTranslate
        }
        company {
          id
          name
          owner
          tenantID
          credits
          usage
          insertUsage
          widgetUsage
          freeTrialUsage
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
        }
        tenantID
        versions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const contentsByType = /* GraphQL */ `
  query ContentsByType(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contentsByType(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyID
        name
        type
        createdAt
        updatedAt
        enabledLanguages {
          label
          code
          autoTranslate
        }
        company {
          id
          name
          owner
          tenantID
          credits
          usage
          insertUsage
          widgetUsage
          freeTrialUsage
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
        }
        tenantID
        versions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchContents = /* GraphQL */ `
  query SearchContents(
    $filter: SearchableContentFilterInput
    $sort: SearchableContentSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchContents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        companyID
        name
        type
        createdAt
        updatedAt
        enabledLanguages {
          label
          code
          autoTranslate
        }
        company {
          id
          name
          owner
          tenantID
          credits
          usage
          insertUsage
          widgetUsage
          freeTrialUsage
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
        }
        tenantID
        versions {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getVersion = /* GraphQL */ `
  query GetVersion($id: ID!) {
    getVersion(id: $id) {
      id
      contentID
      name
      value
      type
      createdAt
      updatedAt
      content {
        id
        companyID
        name
        type
        createdAt
        updatedAt
        enabledLanguages {
          label
          code
          autoTranslate
        }
        company {
          id
          name
          owner
          tenantID
          credits
          usage
          insertUsage
          widgetUsage
          freeTrialUsage
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
        }
        tenantID
        versions {
          nextToken
        }
      }
      tenantID
      translations {
        items {
          id
          versionID
          language
          text
          status
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
      environments {
        items {
          id
          environmentID
          versionID
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
    }
  }
`;
export const listVersions = /* GraphQL */ `
  query ListVersions(
    $filter: ModelVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVersions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        contentID
        name
        value
        type
        createdAt
        updatedAt
        content {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        tenantID
        translations {
          nextToken
        }
        environments {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTranslation = /* GraphQL */ `
  query GetTranslation($id: ID!) {
    getTranslation(id: $id) {
      id
      versionID
      language
      text
      status
      createdAt
      updatedAt
      version {
        id
        contentID
        name
        value
        type
        createdAt
        updatedAt
        content {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        tenantID
        translations {
          nextToken
        }
        environments {
          nextToken
        }
      }
      tenantID
    }
  }
`;
export const listTranslations = /* GraphQL */ `
  query ListTranslations(
    $filter: ModelTranslationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTranslations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        versionID
        language
        text
        status
        createdAt
        updatedAt
        version {
          id
          contentID
          name
          value
          type
          createdAt
          updatedAt
          tenantID
        }
        tenantID
      }
      nextToken
    }
  }
`;
export const getApplication = /* GraphQL */ `
  query GetApplication($id: ID!) {
    getApplication(id: $id) {
      id
      companyID
      name
      type
      supportedLanguages {
        label
        code
        autoTranslate
      }
      createdAt
      updatedAt
      company {
        id
        name
        owner
        tenantID
        credits
        usage
        insertUsage
        widgetUsage
        freeTrialUsage
        createdAtUnix
        updatedAtUnix
        createdAt
        updatedAt
        users {
          nextToken
        }
        contents {
          nextToken
        }
        applications {
          nextToken
        }
        tokens {
          nextToken
        }
      }
      tenantID
      environments {
        items {
          id
          applicationID
          name
          url
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
    }
  }
`;
export const listApplications = /* GraphQL */ `
  query ListApplications(
    $filter: ModelApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyID
        name
        type
        supportedLanguages {
          label
          code
          autoTranslate
        }
        createdAt
        updatedAt
        company {
          id
          name
          owner
          tenantID
          credits
          usage
          insertUsage
          widgetUsage
          freeTrialUsage
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
        }
        tenantID
        environments {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getEnvironment = /* GraphQL */ `
  query GetEnvironment($id: ID!) {
    getEnvironment(id: $id) {
      id
      applicationID
      name
      url
      createdAt
      updatedAt
      application {
        id
        companyID
        name
        type
        supportedLanguages {
          label
          code
          autoTranslate
        }
        createdAt
        updatedAt
        company {
          id
          name
          owner
          tenantID
          credits
          usage
          insertUsage
          widgetUsage
          freeTrialUsage
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
        }
        tenantID
        environments {
          nextToken
        }
      }
      tenantID
      versions {
        items {
          id
          environmentID
          versionID
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
    }
  }
`;
export const listEnvironments = /* GraphQL */ `
  query ListEnvironments(
    $filter: ModelEnvironmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnvironments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        applicationID
        name
        url
        createdAt
        updatedAt
        application {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        tenantID
        versions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getRequest = /* GraphQL */ `
  query GetRequest($id: ID!) {
    getRequest(id: $id) {
      id
      environmentID
      metadata
      language
      createdAt
      updatedAt
      environment {
        id
        applicationID
        name
        url
        createdAt
        updatedAt
        application {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        tenantID
        versions {
          nextToken
        }
      }
      tenantID
    }
  }
`;
export const listRequests = /* GraphQL */ `
  query ListRequests(
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        environmentID
        metadata
        language
        createdAt
        updatedAt
        environment {
          id
          applicationID
          name
          url
          createdAt
          updatedAt
          tenantID
        }
        tenantID
      }
      nextToken
    }
  }
`;
export const getToken = /* GraphQL */ `
  query GetToken($id: ID!) {
    getToken(id: $id) {
      id
      tenantID
      apiKey
      creditsUsage
      createdAt
      updatedAt
      company {
        id
        name
        owner
        tenantID
        credits
        usage
        insertUsage
        widgetUsage
        freeTrialUsage
        createdAtUnix
        updatedAtUnix
        createdAt
        updatedAt
        users {
          nextToken
        }
        contents {
          nextToken
        }
        applications {
          nextToken
        }
        tokens {
          nextToken
        }
      }
    }
  }
`;
export const listTokens = /* GraphQL */ `
  query ListTokens(
    $filter: ModelTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantID
        apiKey
        creditsUsage
        createdAt
        updatedAt
        company {
          id
          name
          owner
          tenantID
          credits
          usage
          insertUsage
          widgetUsage
          freeTrialUsage
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const tokensByTenant = /* GraphQL */ `
  query TokensByTenant(
    $tenantID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tokensByTenant(
      tenantID: $tenantID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantID
        apiKey
        creditsUsage
        createdAt
        updatedAt
        company {
          id
          name
          owner
          tenantID
          credits
          usage
          insertUsage
          widgetUsage
          freeTrialUsage
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      userID
      title
      body
      category
      group
      status
      type
      deliveredByEmail
      deliveredByText
      readAt
      createdAtUnix
      updatedAtUnix
      createdAt
      updatedAt
      user {
        id
        companyID
        name
        username
        email
        isOnboarded
        phone
        firstName
        lastName
        lang
        createdAtUnix
        updatedAtUnix
        createdAt
        updatedAt
        company {
          id
          name
          owner
          tenantID
          credits
          usage
          insertUsage
          widgetUsage
          freeTrialUsage
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
        }
        tenantID
      }
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        title
        body
        category
        group
        status
        type
        deliveredByEmail
        deliveredByText
        readAt
        createdAtUnix
        updatedAtUnix
        createdAt
        updatedAt
        user {
          id
          companyID
          name
          username
          email
          isOnboarded
          phone
          firstName
          lastName
          lang
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
          tenantID
        }
      }
      nextToken
    }
  }
`;
export const getWidget = /* GraphQL */ `
  query GetWidget($id: ID!) {
    getWidget(id: $id) {
      id
      environmentID
      name
      description
      urls
      tags
      sourceLanguage
      autoTranslate
      display
      bannerColor
      bannerBorderColor
      hideBanner
      hideLogo
      disabled
      formality
      step
      enableMonitoring
      createdAt
      updatedAt
      environment {
        id
        applicationID
        name
        url
        createdAt
        updatedAt
        application {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        tenantID
        versions {
          nextToken
        }
      }
      tenantID
    }
  }
`;
export const listWidgets = /* GraphQL */ `
  query ListWidgets(
    $filter: ModelWidgetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWidgets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        environmentID
        name
        description
        urls
        tags
        sourceLanguage
        autoTranslate
        display
        bannerColor
        bannerBorderColor
        hideBanner
        hideLogo
        disabled
        formality
        step
        enableMonitoring
        createdAt
        updatedAt
        environment {
          id
          applicationID
          name
          url
          createdAt
          updatedAt
          tenantID
        }
        tenantID
      }
      nextToken
    }
  }
`;
export const getWidgetInteraction = /* GraphQL */ `
  query GetWidgetInteraction($id: ID!) {
    getWidgetInteraction(id: $id) {
      id
      widgetID
      type
      autoTranslate
      sourceLanguage
      targetLanguage
      count
      url
      ip
      createdAt
      updatedAt
      widget {
        id
        environmentID
        name
        description
        urls
        tags
        sourceLanguage
        autoTranslate
        display
        bannerColor
        bannerBorderColor
        hideBanner
        hideLogo
        disabled
        formality
        step
        enableMonitoring
        createdAt
        updatedAt
        environment {
          id
          applicationID
          name
          url
          createdAt
          updatedAt
          tenantID
        }
        tenantID
      }
      tenantID
    }
  }
`;
export const listWidgetInteractions = /* GraphQL */ `
  query ListWidgetInteractions(
    $filter: ModelWidgetInteractionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWidgetInteractions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        widgetID
        type
        autoTranslate
        sourceLanguage
        targetLanguage
        count
        url
        ip
        createdAt
        updatedAt
        widget {
          id
          environmentID
          name
          description
          urls
          tags
          sourceLanguage
          autoTranslate
          display
          bannerColor
          bannerBorderColor
          hideBanner
          hideLogo
          disabled
          formality
          step
          enableMonitoring
          createdAt
          updatedAt
          tenantID
        }
        tenantID
      }
      nextToken
    }
  }
`;
