import React, {useState, useContext} from 'react';
import queryString from 'querystring';
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  useToast,
  Stack,
} from '@chakra-ui/react';
import {useAuth} from 'Auth';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {LanguageContext} from 'App';
import {Layout} from './layout';

const OrContainer = styled.div`
  margin: 50px 0 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  color: rgb(105, 120, 135);
  font-size: 14px;
`;

const Or = styled.div`
  position: relative;
  top: -8px;
  display: inline-block;
  padding: 0px 20px;
  background: rgb(255, 255, 255);
`;
const Form = styled.form`
  text-align: center;
`;
const Spacer = styled.div`
  height: 20px;
`;

export function ForgotPassword() {
  const {translatedData} = useContext(LanguageContext);
  const {register, handleSubmit} = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const {auth} = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  const getErrorMessage = (err: any) => {
    let key = '';
    if (err.message === 'Username cannot be empty')
      key = 'usernameempty-exception';
    switch (err.name) {
      case 'InvalidParameterException':
        key = 'signup-invalidemail-exception';
        break;
      case 'UserNotFoundException':
        key = 'signin-usernotfound-exception';
        break;
    }
    if (!key) return err.message;
    return translatedData[key];
  };

  const handleForgotPassword = async (data: any) => {
    setIsLoading(true);
    try {
      await auth.forgotPassword(data.email);
      setIsLoading(false);
      navigate(
        `/confirm-forgot-password?${queryString.stringify({
          email: data.email,
        })}`,
      );
    } catch (err: any) {
      console.log(err.name, err.message);
      toast({
        description: getErrorMessage(err),
        position: 'top',
        status: 'error',
        isClosable: true,
      });
      setIsLoading(false);
    }
  };
  return (
    <Layout>
      <Stack spacing={12} width="full" maxW="md">
        <Heading fontSize="24px" textAlign="center">
          {translatedData['forgot-password']}
        </Heading>
        <Form onSubmit={handleSubmit(handleForgotPassword)}>
          <FormControl id="text">
            <FormLabel>{translatedData['email-address']}</FormLabel>
            <Input
              {...register(`email`)}
              placeholder={translatedData['email-address']}
            />
            {/* <FormHelperText>Enter translation text.</FormHelperText> */}
          </FormControl>
          <Spacer />
          <Button
            type="submit"
            colorScheme="pink"
            style={{width: '100%'}}
            isLoading={isLoading}>
            {translatedData['send-confirmation-email']}
          </Button>
          <Stack spacing="2" display={{base: 'flex', lg: 'none'}}>
            <OrContainer>
              <Or>OR</Or>
            </OrContainer>
            <Button
              colorScheme="gray"
              style={{width: '100%'}}
              onClick={() => navigate('/login')}>
              {translatedData['sign-in']}
            </Button>
          </Stack>
        </Form>
      </Stack>
    </Layout>
  );
}
