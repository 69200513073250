import React, {useContext, useEffect, useState} from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import MultiSelect from 'react-dropdown-select';
import {LanguageContext} from 'App';
import Papa from 'papaparse';
import {increaseVersion, parseKey, validateKey} from 'utils/key-manager';
import styled from 'styled-components';
import {FiAlertCircle, FiAlertTriangle} from 'react-icons/fi';
import {API, graphqlOperation} from 'aws-amplify';
import {createContent, createVersion, updateContent} from 'graphql/mutations';
import {searchContents} from 'graphql/custom';

const StyledTd = styled(Td)`
  padding: 8px 4px !important;
`;
const StyledTh = styled(Th)`
  padding: 4px !important;
`;

export function CSVImport({
  tenantID,
  isOpen,
  onClose,
  isOpenConfig,
  openConfig,
  onCloseConfig,
  fetchData,
}: any) {
  const {translatedData, languages} = useContext(LanguageContext);
  const [isLoading, setIsLoading] = useState(false);

  const [csvData, setCsvData] = useState<any>([]);
  const [version, setVersion] = useState('');
  const [originLang, setOriginLang] = useState([]);
  const [targetLangs, setTargetLangs] = useState([]);
  const [dropOptions, setDropOptions] = useState([]);

  const [isContainingKey, SetIsContainingKey] = useState(false);
  const [importData, setImportData] = useState<any>([]);

  const toast = useToast();
  const showToast = (description: string, status: any = 'warning') => {
    toast({description, status, position: 'top'});
  };

  useEffect(() => {
    const langOptions: any = languages.map((lang: any) => {
      return {label: lang.label, value: lang.code};
    });
    setDropOptions(langOptions);
    setOriginLang(
      langOptions.filter((itm: any) => itm.value.toLocaleLowerCase() === 'en'),
    );
  }, []);

  useEffect(() => {
    updateImportData();
  }, [csvData, isContainingKey]);

  const handleMultiSelect = (values: any, type: string) => {
    console.log(values);
    if (type == 'origin' && values.length !== 0) {
      setOriginLang(values[0].value);
    } else if (type == 'target' && values.length !== 0) {
      setTargetLangs(values);
    }
  };

  const onChangeCSVFile = async (e: any) => {
    const file = e.target.files[0];
    if (!file) return;
    Papa.parse(file, {
      complete: res => {
        if (res.data) {
          const data: any = res.data
            .map((item: any) => {
              return item.filter((i: any) => !!i);
            })
            .filter((item: any) => item.length);
          setCsvData(data);
        }
      },
    });
  };

  const handleAdd = () => {
    if (!originLang) {
      return showToast('Source Language is required');
    }
    if (!targetLangs.length) {
      return showToast(translatedData['target-language-required']);
    }
    if (!version) {
      return showToast(translatedData['input-valid-version']);
    }
    if (!csvData.length) {
      return showToast(translatedData['choose-valid-csv-file']);
    }
    onClose();
    setTimeout(openConfig, 500);
  };

  const containingKeyChanged = (e: any) => {
    SetIsContainingKey(e.target.checked);
  };

  const generateData = (_key: string, value: string, useDefault = false) => {
    const defaultKey = parseKey(value);
    const key = useDefault ? defaultKey : _key;
    const {valid, error} = validateKey(key);
    const selected = valid;
    return {defaultKey, key, value, selected, valid, error};
  };

  const updateImportData = () => {
    const data = [];
    for (const d of csvData) {
      if (!d.length) continue;
      if (isContainingKey) {
        if (d.length > 1) {
          data.push(generateData(d[0], d[1]));
        } else {
          data.push(generateData('', d[0]));
        }
      } else {
        for (const dd of d) {
          if (!dd) return;
          data.push(generateData('', dd, true));
        }
      }
    }
    console.log(data);
    saveImportData(data);
  };

  const saveImportData = (importData: any) => {
    for (const d of importData) {
      if (!d.valid && d.error === 'invalid-key') continue;
      const _d = importData.find(
        (i: any) => i !== d && i.key === d.key && i.selected,
      );
      console.log(_d, d);
      if (_d && d.valid) {
        d.valid = false;
        d.selected = false;
        d.error = 'duplicate-key';
      }
      if (!_d && d.error === 'duplicate-key') {
        d.valid = true;
        d.error = '';
      }
    }
    setImportData([...importData]);
  };

  const selectChanged = (e: any, itm: any) => {
    const checked = e.target.checked;
    if (checked && !itm.valid) {
      if (itm.error === 'invalid-key')
        return showToast(translatedData['provided-key-not-valid']);
      if (itm.error === 'duplicate-key')
        return showToast(translatedData['provided-key-duplicated']);
    }
    itm.selected = checked;
    saveImportData(importData);
  };

  const useDefaultKey = (itm: any) => {
    itm.key = itm.defaultKey;
    itm.selected = itm.valid = true;
    saveImportData(importData);
  };

  const addContentClicked = async () => {
    const data = importData.filter((itm: any) => itm.selected);
    if (!data.length) {
      onCloseConfig();
      return;
    }
    setIsLoading(true);

    for (const d of data) {
      console.log('importing', d);
      const existResponse = (await API.graphql(
        graphqlOperation(searchContents, {
          filter: {name: {match: d.key}, type: {eq: 'text'}},
        }),
      )) as any;
      const existContents = existResponse.data.searchContents.items;
      if (!existContents) continue;

      let contentID = null;
      let _version = version;
      // check existing key
      if (existContents.length) {
        console.log('overwriting');
        existContents.sort((a: any, b: any) =>
          a.updatedAt > b.updatedAt ? -1 : 1,
        );
        const lastContent = existContents[0];

        // check all languages are available
        const enabledLanguages = lastContent.enabledLanguages || [];
        targetLangs.map((l: any) => {
          const idx = enabledLanguages.findIndex(
            (ll: any) => ll.code === l.value,
          );
          if (idx === -1)
            enabledLanguages.push({
              label: l.label,
              code: l.value,
              autoTranslate: true,
            });
        });

        await API.graphql(
          graphqlOperation(updateContent, {
            input: {
              id: lastContent.id,
              enabledLanguages,
            },
          }),
        );
        contentID = lastContent.id;

        // check exiting version name
        let existVersionId = lastContent.versions.items.findIndex(
          (v: any) => v.name === _version,
        );
        while (existVersionId !== -1) {
          _version = increaseVersion(_version);
          existVersionId = lastContent.versions.items.findIndex(
            (v: any) => v.name === _version,
          );
        }
      } else {
        const createResponse = (await API.graphql(
          graphqlOperation(createContent, {
            input: {
              type: 'text',
              name: d.key,
              companyID: tenantID,
              enabledLanguages: targetLangs.map((l: any) => ({
                label: l.label,
                code: l.value,
                autoTranslate: true,
              })),
            },
          }),
        )) as any;
        contentID = createResponse.data.createContent.id;
      }
      const response2 = (await API.graphql(
        graphqlOperation(createVersion, {
          input: {
            contentID,
            name: _version,
            type: 'text',
            value: d.value,
          },
        }) as any,
      )) as any;
      console.log('response', response2);
    }
    setIsLoading(false);
    onCloseConfig();
    fetchData();
  };

  const warningPopover = (itm: any) => {
    if (itm.valid) return '';
    return (
      <Popover trigger="hover">
        <PopoverTrigger>
          <Box>
            {itm.error === 'invalid-key' && <FiAlertCircle />}
            {itm.error === 'duplicate-key' && (
              <FiAlertTriangle color="orange" />
            )}
          </Box>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <Box>
              <Text whiteSpace="break-spaces" wordBreak="break-all">
                {itm.error === 'invalid-key' &&
                  translatedData['key-must-not-empty-no-space']}
                {itm.error === 'duplicate-key' &&
                  translatedData['provided-key-duplicated']}
              </Text>
              {itm.error === 'invalid-key' && (
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    size="xs"
                    colorScheme="pink"
                    onClick={() => useDefaultKey(itm)}>
                    {translatedData['use-system-designated-key']}
                  </Button>
                </Box>
              )}
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{translatedData['import-csv']}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              fontSize="sm"
              mb={3}
              paddingX={4}
              paddingY={2}
              backgroundColor="#F5F5F0">
              1.&nbsp;{' '}
              <a href="/csv/sample.csv" style={{color: '#0158f7'}}>
                {translatedData['import-csv-description-1']}
              </a>
              <br />
              2. {translatedData['import-csv-description-2']}
              <br />
              3. {translatedData['import-csv-description-3']}
              <br />
              4. {translatedData['import-csv-description-4']}
              <br />
              5. {translatedData['import-csv-description-5']}
              <br />
              6. {translatedData['import-csv-description-6']}
              <br />
              7. {translatedData['import-csv-description-7']}
              <br />
              {translatedData['import-csv-description-note']}
              <br />
            </Text>
            <FormControl id="version">
              <FormLabel>{translatedData['version']}</FormLabel>
              <Input onChange={e => setVersion(e.target.value)} />
            </FormControl>
            <FormControl id="SourceLang">
              <FormLabel>{translatedData['source-language']}</FormLabel>
              <MultiSelect
                options={dropOptions}
                values={originLang}
                disabled
                separator={true}
                placeholder={translatedData['source-language']}
                onChange={values => handleMultiSelect(values, 'origin')}
              />
            </FormControl>
            <FormControl id="TargetLang">
              <FormLabel>{translatedData['target-language']}</FormLabel>
              <MultiSelect
                options={dropOptions}
                values={targetLangs}
                separator={true}
                placeholder={translatedData['target-language']}
                onChange={values => handleMultiSelect(values, 'target')}
                multi
              />
            </FormControl>
            <FormControl id="name">
              <FormLabel>{translatedData['file']}</FormLabel>
              <Input type="file" accept={'.csv'} onChange={onChangeCSVFile} />
              <FormHelperText>{'.csv'}</FormHelperText>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              {translatedData['close']}
            </Button>
            <Button
              colorScheme="pink"
              isLoading={isLoading}
              onClick={handleAdd}>
              {translatedData['import']}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenConfig} onClose={onCloseConfig} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{translatedData['import-csv']}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl
              display="flex"
              alignItems="center"
              justifyContent="flex-end">
              <FormLabel htmlFor="contain-key-column" mb="0">
                {translatedData['imported-data-contains-key']}
              </FormLabel>
              <Switch
                id="contain-key-column"
                isChecked={isContainingKey}
                onChange={containingKeyChanged}
              />
            </FormControl>
            <Box mt={2}>
              <TableContainer height="50vh" overflowY="auto">
                <Table>
                  <Thead>
                    <Tr>
                      <StyledTh width={40}></StyledTh>
                      <StyledTh width="40%">
                        {translatedData['key-identifier']}
                      </StyledTh>
                      <StyledTh width="60%">{translatedData['value']}</StyledTh>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {importData.map((itm: any, idx: number) => (
                      <Tr key={idx}>
                        <StyledTd padding={1}>
                          <Box width={5} display="flex" alignItems="center">
                            <Checkbox
                              isChecked={itm.selected}
                              onChange={e => selectChanged(e, itm)}
                            />
                          </Box>
                        </StyledTd>
                        <StyledTd width={100} color={itm.valid ? '' : 'red'}>
                          <Box display="flex" alignItems="center" gap="5px">
                            {itm.key} {warningPopover(itm)}
                          </Box>
                        </StyledTd>
                        <StyledTd
                          width={100}
                          whiteSpace="break-spaces"
                          wordBreak="break-all">
                          {itm.value}
                        </StyledTd>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onCloseConfig}>
              {translatedData['close']}
            </Button>
            <Button
              colorScheme="pink"
              isLoading={isLoading}
              onClick={addContentClicked}>
              {translatedData['import']}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
