import {SketchPicker} from 'react-color';
import {Box, Popover, PopoverTrigger, PopoverContent} from '@chakra-ui/react';

export default function ColorPicker(props: any) {
  const {color, setColor} = props;

  return (
    <Box>
      <Popover placement="bottom" closeOnBlur={false}>
        <PopoverTrigger>
          <Box
            sx={{
              padding: '5px',
              background: '#fff',
              borderRadius: '1px',
              boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
              cursor: 'pointer',
              width: '60px',
            }}
          >
            <Box
              sx={{
                height: '15px',
                borderRadius: '2px',
                background: color,
              }}
            />
          </Box>
        </PopoverTrigger>
        <PopoverContent width="auto">
          <SketchPicker
            color={color}
            onChange={e =>
              setColor(`rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a})`)
            }
          />
        </PopoverContent>
      </Popover>
    </Box>
  );
}
