import styled from 'styled-components';
import {TableContainer, Tr} from '@chakra-ui/react';
import {Colors} from 'utils/colors';

export const StyledTableContainer = styled(TableContainer)`
  margin-top: 10px;
  border: 1px solid ${Colors.gray};
  border-radius: 10px;
  padding: 12px;
`;

export const StyledTr = styled(Tr)`
  cursor: pointer;

  &:hover {
    background-color: #edf2f7;
  }
`;
