import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from '@chakra-ui/react';
import {FiHelpCircle} from 'react-icons/fi';
import {PageHeader} from 'components/page/page-header-section';
import {Colors} from 'utils/colors';
import {LanguageContext} from 'App';
import {useContext} from 'react';

export const BillingFaqs = () => {
  const {translatedData} = useContext(LanguageContext);
  const faqs = [
    {
      title: translatedData['credits-available'],
      content: translatedData['credits-available-content'],
    },
    {
      title: translatedData['credits-consumed'],
      content: translatedData['credits-consumed-content'],
    },
    {
      title: translatedData['often-balance-updated'],
      content: translatedData['often-balance-updated-content'],
    },
    {
      title: translatedData['find-credit-balance'],
      content: translatedData['find-credit-balance-content'],
    },
    {
      title: translatedData['credits-rollover'],
      content: translatedData['credits-rollover-content'],
    },
    {
      title: translatedData['sample-service-free'],
      content: translatedData['sample-service-free-content'],
    },
    {
      title: translatedData['monthly-subscription-impacted'],
      content: translatedData['monthly-subscription-impacted-content'],
    },
    {
      title: translatedData['past-invoices'],
      content: `${translatedData['past-invoices-content']} <a target="_blank" style="text-decoration:underline" href="https://billing.stripe.com/p/login/28o15r2WRft32qI8ww">${translatedData['customer-portal']}</a>`,
    },
  ];
  return (
    <Box id="billing-faqs" sx={{mt: '50px'}}>
      <PageHeader
        title={translatedData['frequently-asked-questions']}
        TitleIcon={FiHelpCircle}
      />
      <Accordion allowMultiple defaultIndex={[0, 1, 2, 3, 4, 5, 6, 7]}>
        {faqs.map((faq, idx) => (
          <AccordionItem
            key={idx}
            sx={{border: '1px solid', borderColor: Colors.gray}}>
            <h2>
              <AccordionButton bgColor="gray.200">
                <Box as="span" flex="1" textAlign="left">
                  {faq.title}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Text
                dangerouslySetInnerHTML={{__html: faq.content}}
                sx={{whiteSpace: 'break-spaces'}}></Text>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};
