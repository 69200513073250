import React, {useEffect, useState} from 'react';
import {useAuth} from 'Auth';
import {PageContainer} from 'components/page/page-container';
import {PageHeader} from 'components/page/page-header-section';
import {BsFillPeopleFill} from 'react-icons/bs';
import {API, graphqlOperation} from 'aws-amplify';
import {getCompany} from 'graphql/queries';
import {User} from 'API';
import {useTranslation} from 'App';
import {StyledTableContainer} from 'components/ui/table/table';
import {
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Table,
  TableCaption,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Badge,
  MenuDivider,
} from '@chakra-ui/react';
import {FiDelete, FiEdit, FiMoreVertical, FiShieldOff} from 'react-icons/fi';
import {StyledMenuItem} from 'components/ui/menu';

export const MyTeamScreen = () => {
  const {auth} = useAuth();
  const {user: me, tenantID} = auth;
  const {translatedData} = useTranslation();

  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    if (!tenantID) return;

    (async () => {
      const response = (await API.graphql(
        graphqlOperation(getCompany, {id: tenantID}),
      )) as any;
      const company = response?.data?.getCompany;

      setUsers(company?.users?.items ?? []);
    })();
  }, [tenantID]);

  return (
    <PageContainer>
      <PageHeader
        title={'My Team'}
        description={`This page is an exclusive platform for the owners to manage their team efficiently. This page allows owners to oversee all team members, their roles, and their input in various tasks. Owners can easily add or remove team members, assign roles, set permissions and delegate responsibilities. This page provides a central hub for owners to view the team's structure, monitor their performance, and make necessary adjustments to ensure smooth team operations.`}
        TitleIcon={BsFillPeopleFill}
      />

      <StyledTableContainer>
        <Table variant="simple">
          <TableCaption>{'Team Members'}</TableCaption>
          <Thead>
            <Tr>
              <Th>{translatedData['first-name']}</Th>
              <Th>{translatedData['last-name']}</Th>
              <Th>{translatedData['email-address']}</Th>
              <Th>{translatedData['status']}</Th>
              <Th>{'access'}</Th>
              <Th style={{textAlign: 'center'}}>{translatedData['actions']}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.map(user => (
              <Tr key={user.id}>
                <Td>{user.firstName}</Td>
                <Td>{user.lastName}</Td>
                <Td>{user.email}</Td>
                <Td>
                  <Badge colorScheme="green">Active</Badge>
                </Td>
                <Td>{'Admin Access'}</Td>
                <Td style={{textAlign: 'center'}}>
                  {user.id === me?.attributes?.['sub'] ? (
                    <Badge colorScheme="green">Owner</Badge>
                  ) : (
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<FiMoreVertical />}
                        variant="outline"
                      />
                      <MenuList>
                        <StyledMenuItem>
                          <FiEdit />
                          <Text>Edit</Text>
                        </StyledMenuItem>
                        <MenuDivider />
                        <StyledMenuItem>
                          <FiShieldOff />
                          <Text>Deactivate</Text>
                        </StyledMenuItem>
                        <StyledMenuItem>
                          <FiDelete />
                          <Text>Delete</Text>
                        </StyledMenuItem>
                      </MenuList>
                    </Menu>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </StyledTableContainer>
    </PageContainer>
  );
};
