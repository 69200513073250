import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import {TenantContext} from 'App';
import {Auth} from 'aws-amplify';
import React, {useContext} from 'react';
import {useForm} from 'react-hook-form';

export function AddUser({isOpen, onClose}: any) {
  const {register, handleSubmit} = useForm();

  const tenantID = useContext(TenantContext);

  const handleCreateUser = async (data: any) => {
    try {
      return await Auth.signUp({
        username: data.email,
        password: 'password123',
        attributes: {
          email: data.email,
          'custom:tenantId': tenantID,
        },
      });
    } catch (err) {
      console.log('error signing up: ', err);
      return err;
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(handleCreateUser)}>
            <ModalHeader>Add User</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl id="email">
                <FormLabel>Email</FormLabel>
                <Input {...register(`email`)} />
                <FormHelperText>Enter the email for the user.</FormHelperText>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button colorScheme="pink" type="submit">
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}
