import React, {useState, useEffect, useContext} from 'react';
import Joyride from 'react-joyride';
import {LanguageContext} from 'App';
import {useAuth} from 'Auth';

export default function NavbarTour({
  type,
  start,
  onEnd,
}: {
  type: string | undefined;
  start?: boolean | undefined;
  onEnd?: any;
}) {
  const {auth} = useAuth();
  const {translatedData} = useContext(LanguageContext);

  const [tourStart, setTourStart] = useState(false);
  const [steps, setSteps] = useState<any[]>([]);

  useEffect(() => {
    setSteps([
      {
        target: '#tour-plugins',
        content: translatedData['tour-plugins'],
        disableBeacon: true,
      },
      {
        target: '#tour-content',
        content: translatedData['tour-content'],
        disableBeacon: true,
      },
      {
        target: '#tour-apikeys',
        content: translatedData['tour-apikeys'],
        disableBeacon: true,
      },
      {
        target: '#tour-application',
        content: translatedData['tour-application'],
        disableBeacon: true,
      },
      {
        target: '#tour-widget',
        content: translatedData['tour-widget'],
        disableBeacon: true,
      },
      {
        target: '#tour-billing',
        content: translatedData['tour-billing'],
        disableBeacon: true,
      },
      {
        target: '#tour-playground',
        content: translatedData['tour-playground'],
        disableBeacon: true,
      },
      {
        target: '#tour-documentation',
        content: translatedData['tour-documentation'],
        disableBeacon: true,
      },
    ]);
  }, [translatedData]);

  useEffect(() => {
    if (typeof start !== 'boolean') return;
    // setTourStart(start);
  }, [start]);

  useEffect(() => {
    if (type === 'manual') return;
    if (auth.user) {
      if (auth.user?.attributes['custom:isOnboarded']) {
        const tourState = localStorage.getItem('fluentc-tour-state');
        if (!tourState) {
          // setTimeout(() => setTourStart(true), 1000);
        }
      }
    }
  }, [auth.user]);

  const tourCallback = (data: any) => {
    const {status, action} = data;
    if (type === 'manual') {
      if (status === 'finished' || status === 'skipped' || action === 'close') {
        onEnd();
      }
      return;
    }
    if (status === 'finished' || status === 'skipped') {
      localStorage.setItem('fluentc-tour-state', status);
    }
    if (action === 'close') {
      localStorage.setItem('fluentc-tour-state', action);
      setTourStart(false);
    }
  };

  return (
    <>
      <Joyride
        run={tourStart}
        continuous={true}
        showSkipButton={true}
        // stepIndex={0}
        steps={steps}
        callback={tourCallback}
        locale={{
          back: translatedData['previous'],
          close: translatedData['close'],
          next: translatedData['next'],
          skip: translatedData['skip'],
          last: translatedData['finish'],
        }}
      />
    </>
  );
}
