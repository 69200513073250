import React from 'react';
import {PageContainer} from '../components/page/page-container';
import {PageHeader} from 'components/page/page-header-section';

export const WorkflowsScreen = () => {
  return (
    <PageContainer>
      <PageHeader title="Workflows" />
    </PageContainer>
  );
};
