import React, {useContext, useEffect, useState} from 'react';
import API from '@aws-amplify/api';
import {graphqlOperation} from '@aws-amplify/api-graphql';
import {LanguageContext} from 'App';
import {Box, Heading, Button, Text, Image, Collapse} from '@chakra-ui/react';
import {updateWidget} from 'graphql/mutations';
import Editor from 'react-simple-code-editor';
import {highlight, languages} from 'prismjs';
import 'prismjs/themes/prism.css';
import {
  AiOutlineCopy,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from 'react-icons/ai';
import copy from 'copy-to-clipboard';
import InstallDemoImage1 from 'assets/img/widget/install-1.png';
import InstallDemoImage2 from 'assets/img/widget/install-2.png';

export const WidgetInstall = (props: any) => {
  const {hidden, widget, submit, submitLabel} = props;
  const {translatedData} = useContext(LanguageContext);

  const [isSaving, setIsSaving] = useState(false);
  const [code, setCode] = useState('');
  const [injectCode] = useState(`<div id='fluentc-widget'></div>`);

  const [showDemo1, setShowDemo1] = useState(false);
  const [showDemo2, setShowDemo2] = useState(false);

  useEffect(() => {
    if (!widget) return;
    setCode(
      `<script src="${process.env.REACT_APP_FLUENTC_WIDGET_URL}"></script>\n<script>\n\tdocument.addEventListener("DOMContentLoaded", function () {\n\t\tf=new fluentcWidget({widgetID: "${widget?.id}"})\n\t\tf.setupWidget('fluentc-widget');\n\t});\n</script>`,
    );
  }, [widget]);

  const submitClicked = async () => {
    setIsSaving(true);
    const widgetResponse = (await API.graphql(
      graphqlOperation(updateWidget, {
        input: {
          id: widget.id,
          step: Math.max(widget?.step || 0, 4),
        },
      }) as any,
    )) as any;
    const wid = widgetResponse?.data?.updateWidget;
    if (!wid) return;
    submit(wid.id);
    setIsSaving(false);
  };

  return (
    <Box display={hidden ? 'none' : 'block'}>
      <Heading size="md">{translatedData['easy-install']}</Heading>
      <Box mt={3}>
        <Text fontWeight="medium" fontSize="large">
          {translatedData['widget-install-description-1']}
        </Text>
        <Box position="relative">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
            }}>
            <Box sx={{flexGrow: 1}}>
              <Editor
                className="editor"
                disabled
                value={code}
                onValueChange={console.log}
                highlight={code => highlight(code, languages.html, 'html')}
                padding={10}
                style={{
                  paddingTop: '20px',
                  whiteSpace: 'pre',
                  overflowX: 'auto',
                  backgroundColor: '#f7fafc',
                }}
              />
            </Box>
            <Collapse in={showDemo1} animateOpacity>
              <Box
                sx={{
                  flexGrow: 1,
                  backgroundColor: '#1f1f1f',
                  filter: 'invert(1)',
                  borderRadius: '8px',
                  overflow: 'hidden',
                }}>
                <Image src={InstallDemoImage1} />
              </Box>
            </Collapse>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}>
            <Button
              leftIcon={
                showDemo1 ? <AiOutlineEyeInvisible /> : <AiOutlineEye />
              }
              size="xs"
              colorScheme="teal"
              variant="outline"
              onClick={() => setShowDemo1(!showDemo1)}>
              {showDemo1
                ? translatedData['hide-example']
                : translatedData['show-example']}
            </Button>
            <Button
              leftIcon={<AiOutlineCopy />}
              size="xs"
              colorScheme="teal"
              variant="outline"
              onClick={() => copy(code)}>
              {translatedData['copy']}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box mt={3}>
        <Text fontWeight="medium" fontSize="large">
          {translatedData['widget-install-description-2']}
        </Text>
        <Box position="relative">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
            }}>
            <Box sx={{flexGrow: 1}}>
              <Editor
                className="editor"
                disabled
                value={injectCode}
                onValueChange={console.log}
                highlight={code => highlight(code, languages.html, 'html')}
                padding={10}
                style={{
                  paddingTop: '20px',
                  whiteSpace: 'pre',
                  overflowX: 'auto',
                  backgroundColor: '#f7fafc',
                }}
              />
            </Box>
            <Collapse in={showDemo2} animateOpacity>
              <Box
                sx={{
                  flexGrow: 1,
                  backgroundColor: '#1f1f1f',
                  filter: 'invert(1)',
                  borderRadius: '8px',
                  overflow: 'hidden',
                }}>
                <Image src={InstallDemoImage2} />
              </Box>
            </Collapse>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}>
            <Button
              leftIcon={
                showDemo2 ? <AiOutlineEyeInvisible /> : <AiOutlineEye />
              }
              size="xs"
              colorScheme="teal"
              variant="outline"
              onClick={() => setShowDemo2(!showDemo2)}>
              {showDemo2
                ? translatedData['hide-example']
                : translatedData['show-example']}
            </Button>
            <Button
              leftIcon={<AiOutlineCopy />}
              size="xs"
              colorScheme="teal"
              variant="outline"
              onClick={() => copy(injectCode)}>
              {translatedData['copy']}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" gap={2} mt={5}>
        {submit && (
          <Button
            colorScheme="pink"
            bg={'pink.900'}
            _hover={{bg: 'pink.800'}}
            isLoading={isSaving}
            onClick={submitClicked}>
            {submitLabel}
          </Button>
        )}
      </Box>
    </Box>
  );
};
