import React, {useCallback, useEffect, useState, useContext} from 'react';
import {API, graphqlOperation, Storage} from 'aws-amplify';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Textarea,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import Editor from 'react-simple-code-editor';
import {highlight, languages} from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import {PageLoading} from 'components/page/page-loading';
import {updateTranslation} from 'graphql/mutations';
import {useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router';
import {PageContainer} from 'components/page/page-container';
import {StyledTableContainer, StyledTr} from 'components/ui/table/table';
import {getVersion} from 'graphql/queries';
import {LanguageContext} from 'App';
import {PageHeader} from 'components/page/page-header-section';

export const VersionScreen = () => {
  const {translatedData} = useContext(LanguageContext);

  const [version, setVersion] = useState({
    name: '',
    id: '',
    translations: {items: []},
    content: {name: '', id: ''},
    type: '',
  });
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const {isOpen, onClose, onOpen} = useDisclosure();
  const {register, handleSubmit, resetField} = useForm();
  const [translation, setTranslation] = useState({id: '', text: ''});
  const [file, setFile] = useState('');
  const [contentSignedUrl, setContentSignedUrl] = useState('');

  const fetchData = useCallback(async () => {
    // Simple query
    const response = (await API.graphql(
      graphqlOperation(getVersion, {id: params.versionId}),
    )) as any;
    console.log(response.data);
    const data = response.data.getVersion;
    setVersion(data);

    if (data.type === 'file') {
      const signedUrl = await Storage.get(data.value);

      const res = await fetch(signedUrl);
      const text = await res.text();
      setFile(text);
      console.log(text);
    }
    if (['image', 'video'].includes(data.type)) {
      const signedUrl = await Storage.get(data.value);

      setContentSignedUrl(signedUrl);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleEditTranslationModal = async (translation: any) => {
    setTranslation(translation);
    onOpen();
  };

  const handleEditTranslationSubmit = async (data: any) => {
    setTranslation(translation);
    (await API.graphql(
      graphqlOperation(updateTranslation, {
        input: {
          id: translation.id,
          text: data.translationText,
        },
      }),
    )) as any;
    resetField('translationText');
    fetchData();
    onClose();
  };

  const setTranslationText = (e: any) => {
    setTranslation({...translation, text: e.target.value});
  };

  return isLoading ? (
    <PageLoading />
  ) : (
    <PageContainer>
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink href="/content">
            {translatedData['content']}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink
            href="#"
            onClick={() => navigate(`/content/${version.content.id}`)}>
            {version.content.name.length > 50
              ? `${version.content.name.substring(0, 50)} ...`
              : version.content.name}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="#">{version.name}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <br />
      {version.type === 'text' && (
        <>
          <PageHeader title={translatedData['translations']} />

          <StyledTableContainer>
            <Table variant="simple">
              <TableCaption>{translatedData['active-languages']}</TableCaption>
              <Thead>
                <Tr>
                  <Th>{translatedData['language']}</Th>
                  <Th>{translatedData['text']}</Th>
                  <Th>{translatedData['actions']}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {version.translations.items &&
                  version.translations.items.map(
                    (transl: {language: string; text: string}) => (
                      <StyledTr key={transl.language}>
                        <Td>{transl.language}</Td>
                        <Td>{transl.text}</Td>
                        <Td>
                          <Button
                            onClick={() => handleEditTranslationModal(transl)}>
                            {translatedData['edit']}
                          </Button>
                        </Td>
                      </StyledTr>
                    ),
                  )}
              </Tbody>
            </Table>
          </StyledTableContainer>
        </>
      )}

      {version.type === 'image' && (
        <>
          <PageHeader title={`${translatedData['image']}: ${version.name}}`} />

          <br />
          <img src={contentSignedUrl} />
        </>
      )}
      {version.type === 'video' && (
        <>
          <PageHeader title={`${translatedData['video']}: ${version.name}}`} />

          <br />
          <video width="320" height="240" controls>
            <source src={contentSignedUrl} />
            Your browser does not support the video tag.
          </video>
        </>
      )}
      {version.type === 'file' && (
        <>
          <PageHeader title={`${translatedData['file']}: ${version.name}}`} />

          <br />
          <Editor
            disabled
            value={file}
            onValueChange={code => setFile(code)}
            highlight={code => highlight(code, languages.js, 'css')}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
            }}
          />
        </>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(handleEditTranslationSubmit)}>
            <ModalHeader>{translatedData['edit-translation']}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl id="text">
                <FormLabel>{translatedData['text']}</FormLabel>
                <Textarea
                  {...register(`translationText`)}
                  placeholder={translation.text}
                  value={translation.text}
                  onChange={e => setTranslationText(e)}
                />
                <FormHelperText>
                  {translatedData['enter-translated-text']}
                </FormHelperText>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                {translatedData['close']}
              </Button>
              <Button colorScheme="pink" type="submit">
                {translatedData['save']}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </PageContainer>
  );
};
