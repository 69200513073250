import React, {useEffect, useState, useContext} from 'react';
import API from '@aws-amplify/api';
import {graphqlOperation} from '@aws-amplify/api-graphql';
import {Heading} from '@chakra-ui/layout';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import {PageLoading} from 'components/page/page-loading';
import {getApplication} from 'graphql/queries';
import {BsGear, BsTranslate} from 'react-icons/bs';
import {GrDocumentTest} from 'react-icons/gr';
import {useParams} from 'react-router';
import styled from 'styled-components';
import {PageContainer} from 'components/page/page-container';
import {PageHeader} from 'components/page/page-header';
import {ApplicationLanguages} from './_applicationLanguages';
import {Environments} from './_environments';
import {Settings} from './_settings';
import {LanguageContext} from 'App';
import {MobileHide} from 'components/page/mobilehide';

const TabIcon = styled.span`
  padding-right: 8px;
`;

export const ApplicationScreen = () => {
  const params = useParams();
  const {translatedData} = useContext(LanguageContext);

  const [isLoading, setIsLoading] = useState(true);
  const [application, setApplication] = useState<any>({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = (await API.graphql(
      graphqlOperation(getApplication, {id: params.applicationId}) as any,
    )) as any;

    const data = response.data.getApplication as any;
    setApplication(data);
    setIsLoading(false);
  };

  return isLoading ? (
    <PageLoading />
  ) : (
    <PageContainer>
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink href="/applications">
            {translatedData['applications']}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="#">{application.name}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <br />
      <PageHeader>
        <Heading>{application.name}</Heading>
      </PageHeader>
      <Tabs>
        <TabList>
          <Tab>
            <TabIcon>
              <GrDocumentTest />
            </TabIcon>
            <MobileHide>{translatedData['manage-environments']}</MobileHide>
          </Tab>

          <Tab>
            <TabIcon>
              <BsTranslate />
            </TabIcon>
            <MobileHide>{translatedData['supported-languages']}</MobileHide>
          </Tab>
          <Tab>
            <TabIcon>
              <BsGear />
            </TabIcon>
            <MobileHide>{translatedData['settings']}</MobileHide>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Environments />
          </TabPanel>

          <TabPanel>
            <ApplicationLanguages application={application} />
          </TabPanel>
          <TabPanel>
            <Settings />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageContainer>
  );
};
