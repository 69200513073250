import sanitizeHtml from 'sanitize-html';

const defaultOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a'],
  allowedAttributes: {
    a: ['href'],
  },
  allowedIframeHostnames: ['www.youtube.com'],
};

const sanitize = (dirty: any, options: any) => ({
  __html: sanitizeHtml(dirty, {...defaultOptions, ...options}),
});

export const SanitizeHTML = ({html, options}: any) => (
  <div dangerouslySetInnerHTML={sanitize(html, options)} />
);
