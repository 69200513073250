import React, {useContext, useEffect, useState} from 'react';
import {Heading, Box, Text} from '@chakra-ui/layout';
import {LanguageContext} from 'App';
import {PageLoading} from 'components/page/page-loading';
import {useNavigate, useParams} from 'react-router';
import {PageContainer} from 'components/page/page-container';
import {WidgetDetail} from './_widget-detail';
import {WidgetLanguage} from './_widget-language';
import {API, graphqlOperation} from 'aws-amplify';
import {getWidgetOnWidget} from 'graphql/custom';
import {WidgetSecurity} from './_widget-security';
import {WidgetInstall} from './_widget-install';
import {WidgetCreateSteps} from './widgetcreate-steps';

export const WidgetCreateScreen = () => {
  const {translatedData} = useContext(LanguageContext);
  const navigate = useNavigate();

  const submitWidgetDetail = async (widId: any) => {
    navigate(`/widget/${widId}`);
  };

  return (
    <PageContainer>
      <Box
        display="flex"
        flexDirection={'column'}
        width="100%"
        gap={5}
        alignItems="flex-start"
        flexWrap="wrap">
        <Box width="100%" flexGrow={1}>
          <Box mb={5}>
            <Heading size="lg">{translatedData['setup-new-widget']}</Heading>
            <Text>{translatedData['widget-setup-description']}</Text>
          </Box>
          <Box>
            <WidgetDetail
              widget={null}
              submitLabel={translatedData['save']}
              submit={submitWidgetDetail}
            />
          </Box>
        </Box>
      </Box>
    </PageContainer>
  );
};
