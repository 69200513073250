import React, {useState, useEffect, useCallback, useContext} from 'react';
import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverBody,
  PopoverCloseButton,
  useDisclosure,
  Text,
  TabList,
  Tab,
  Tabs,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Switch,
} from '@chakra-ui/react';
import {FiBell} from 'react-icons/fi';
import {API, graphqlOperation} from 'aws-amplify';
import {listNotifications} from 'graphql/queries';
import NotificationItem from './notification-item';
import {updateNotification} from 'graphql/mutations';
import {LanguageContext} from 'App';

export default function NotificationHeader() {
  const {translatedData} = useContext(LanguageContext);

  const [isLoading, setIsLoading] = useState(true);
  const {onOpen, onClose, isOpen} = useDisclosure();
  const [notifications, setNotifications] = useState([]);
  const [showOnlyInCompleted, setShowOnlyInCompleted] = useState(true);
  const [showOnlyUnRead, setShowOnlyUnRead] = useState(false);
  const [showBadge, setShowBadge] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    let filter: any = {};
    if (showOnlyUnRead) {
      filter.readAt = {attributeExists: false};
    }
    if (showOnlyInCompleted) {
      filter.status = {eq: 'INCOMPLETE'};
    }
    if (!showOnlyUnRead && !showOnlyInCompleted) filter = undefined;
    const response = (await API.graphql(
      graphqlOperation(listNotifications, {limit: 100, filter}) as any,
    )) as any;

    const notifications = response.data.listNotifications.items;
    notifications.sort((a: any, b: any) =>
      a.createdAt > b.createdAt ? -1 : 1,
    );

    setNotifications(notifications);
    setIsLoading(false);
  }, [showOnlyInCompleted, showOnlyUnRead]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [isOpen, fetchData]);

  useEffect(() => {
    if (notifications.filter((item: any) => !item.readAt).length) {
      setShowBadge(true);
    } else {
      setShowBadge(false);
    }
  }, [notifications]);

  const markAsRead = async (id: string, status: boolean) => {
    const readAt = status ? Date.now() : null;
    await API.graphql(
      graphqlOperation(updateNotification, {input: {id, readAt}}),
    );
    fetchData();
  };

  const markAsComplete = async (id: string, _status: boolean) => {
    const status = _status ? 'COMPLETE' : 'INCOMPLETE';
    await API.graphql(
      graphqlOperation(updateNotification, {input: {id, status}}),
    );
    fetchData();
  };

  const markAllAsRead = async () => {
    const readAt = Date.now();
    await Promise.all(
      notifications
        .filter((item: any) => !item.readAt)
        .map((item: any) =>
          API.graphql(
            graphqlOperation(updateNotification, {
              input: {id: item.id, readAt},
            }),
          ),
        ),
    );
    fetchData();
  };
  const markAllAsComplete = async () => {
    await Promise.all(
      notifications
        .filter((item: any) => item.status === 'INCOMPLETE')
        .map((item: any) =>
          API.graphql(
            graphqlOperation(updateNotification, {
              input: {id: item.id, status: 'COMPLETE'},
            }),
          ),
        ),
    );
    fetchData();
  };

  const onlyShowIncompleteSwitchChanged = (e: any) => {
    setShowOnlyInCompleted(e.target.checked);
  };
  const onlyShowUnReadSwitchChanged = (e: any) => {
    setShowOnlyUnRead(e.target.checked);
  };

  return (
    <Box marginRight={15} color="black">
      <Popover
        placement="bottom-start"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      >
        <PopoverTrigger>
          <Box position="relative" cursor="pointer">
            <FiBell size={20} color="white" />
            <Box
              display={showBadge ? 'block' : 'none'}
              position="absolute"
              top={0}
              right={0}
              borderRadius="50%"
              width="8px"
              height="8px"
              bgColor="red.600"
            />
          </Box>
        </PopoverTrigger>
        <PopoverContent width={400} maxW="100vw">
          <PopoverCloseButton />
          <PopoverBody>
            <Text fontSize="2xl">{translatedData['notifications']}</Text>
            <Tabs mt={2} size="sm">
              <TabList>
                <Tab>{translatedData['account']}</Tab>
                <Tab>{translatedData['content']}</Tab>
                <Tab>{translatedData['user']}</Tab>
              </TabList>
            </Tabs>
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Button size="sm" variant="link" onClick={markAllAsRead}>
                {translatedData['mark-all-as-read']}
              </Button>
              <Button size="sm" variant="link" onClick={markAllAsComplete}>
                {translatedData['mark-all-as-done']}
              </Button>
            </Box>
            <Box
              className="custom-scrollbar"
              position="relative"
              mt={1}
              maxHeight={300}
              overflowY="auto"
            >
              {notifications.map((item: any, idx) => (
                <NotificationItem
                  key={idx}
                  item={item}
                  markAsRead={markAsRead}
                  markAsComplete={markAsComplete}
                />
              ))}
              {!notifications.length && (
                <Box textAlign="center" py={5} color="gray">
                  {translatedData['you-have-no-notifications']}
                </Box>
              )}
              {isLoading && (
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  bgColor="#fffc"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress isIndeterminate size="40px" />
                </Box>
              )}
            </Box>
            <Box mt={1} pt={1} borderTop="1px solid #f0f0f0">
              <FormControl
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <FormLabel
                  mb={0}
                  fontSize={14}
                  color="gray"
                  htmlFor="isChecked"
                >
                  {translatedData['only-show-unread']}
                </FormLabel>
                <Switch
                  id="isChecked"
                  isChecked={showOnlyUnRead}
                  onChange={onlyShowUnReadSwitchChanged}
                />
              </FormControl>
              <FormControl
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <FormLabel
                  mb={0}
                  fontSize={14}
                  color="gray"
                  htmlFor="isChecked"
                >
                  {translatedData['only-show-incompleted']}
                </FormLabel>
                <Switch
                  id="isChecked"
                  isChecked={showOnlyInCompleted}
                  onChange={onlyShowIncompleteSwitchChanged}
                />
              </FormControl>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
}
