import {Helmet} from 'react-helmet';

export const Script = () => {
  return (
    <Helmet>
      <script src="/hotjar.js" type="text/javascript" />
      <script src="/linkedin.js" type="text/javascript" />
    </Helmet>
  );
};
