import {
  Badge,
  Box,
  Divider,
  Heading,
  Icon,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  theme,
} from '@chakra-ui/react';
import {Token, Widget} from 'API';
import {LanguageContext} from 'App';
import {useAuth} from 'Auth';
import {API, graphqlOperation} from 'aws-amplify';
import {PageHeader} from 'components/page/page-header-section';
import {PageLoading} from 'components/page/page-loading';
import CopyButton from 'components/utils/copy-button';
import {fetchCheckoutUrl, listWidgets, tokensByTenant} from 'graphql/queries';
import {useContext, useEffect, useState} from 'react';
import {FiHome, FiLayout} from 'react-icons/fi';
import {IoIosAddCircleOutline} from 'react-icons/io';
import {useNavigate} from 'react-router-dom';
import {formatNumber} from 'utils/helper';
import {PageContainer} from '../components/page/page-container';
import {CreditPackageCards} from './billing/_credit-package-cards';
import {ItemType} from './billing/billing-screen';
import {formatRelative, parseISO} from 'date-fns';
import styled from 'styled-components';

const WidgetDate = styled(Text)`
  font-size: 12px;
`;

export const HomeScreen = () => {
  const {translatedData} = useContext(LanguageContext);
  const navigate = useNavigate();
  const {auth} = useAuth();
  const [tokens, setTokens] = useState<Token[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBillingLoading, setIsBillingLoading] = useState({});
  const [widgets, setWidgets] = useState([]);

  const tenantID =
    auth.user?.attributes && auth.user?.attributes['custom:tenantId'];
  // if (!tenantID) return;

  useEffect(() => {
    fetchData();
    fetchWidgetData();
  }, []);

  const fetchData = async () => {
    const apiKeys = (await API.graphql(
      graphqlOperation(tokensByTenant, {tenantID, limit: 1000}) as any,
    )) as any;

    const tokens = apiKeys.data.tokensByTenant.items;
    tokens.sort((a: Token, b: Token) => (a.createdAt > b.createdAt ? -1 : 1));

    setTokens(tokens);

    setIsLoading(false);
  };

  const fetchWidgetData = async () => {
    const response = (await API.graphql(
      graphqlOperation(listWidgets, {}) as any,
    )) as any;
    const data = response.data.listWidgets.items as any;
    data.sort((a: any, b: any) => (a.createdAt > b.createdAt ? -1 : 1));
    setWidgets(data);
    setIsLoading(false);
  };
  const goDetail = (widget: any) => {
    navigate(`/widget/${widget.id}`);
  };

  const buyNow = async (item: ItemType) => {
    setIsBillingLoading({[item]: true});
    const host = window.location.origin;
    const response = (await API.graphql(
      graphqlOperation(fetchCheckoutUrl, {
        customerEmail: auth.user.attributes.email,
        host,
        basic: item === 'basic' ? 1 : 0,
        bulk: item === 'bulk' ? 1 : 0,
        premium: item === 'premium' ? 1 : 0,
      }) as any,
    )) as any;

    window.location.href = response.data.fetchCheckoutUrl.body;
    setIsBillingLoading({[item]: false});
  };

  return isLoading ? (
    <PageLoading />
  ) : (
    <PageContainer>
      <PageHeader
        title={translatedData['welcome-text']}
        description={translatedData['introduction-text']}
        TitleIcon={FiHome}
      />
      <Box my={10}>
        <Heading as="h2" size="lg" color="gray.700">
          {translatedData['api-keys']}
        </Heading>

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>{translatedData['api-key']}</Th>
              <Th>{translatedData['usage']}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {tokens.map((token: any) => (
              <Tr key={token?.apiKey}>
                <Td>
                  {token?.apiKey} <CopyButton text={token?.apiKey} />
                </Td>
                <Td>{formatNumber(token.creditsUsage || 0)} Credits</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Divider />

      <Box my={10}>
        <Heading as="h2" size="lg" color="gray.700">
          {translatedData['web-widgets']}
        </Heading>
        {widgets.length === 0 && (
          <Text marginY={4}>{translatedData['not-created-web-widgets']}</Text>
        )}
        <Box display="flex" gap="15px" flexWrap="wrap">
          {widgets.map((widget: Widget, idx: number) => (
            <Box
              key={idx}
              width="300px"
              bgColor="gray.100"
              p="15px"
              cursor="pointer"
              onClick={() => goDetail(widget)}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                <Icon as={FiLayout} boxSize={6} />
              </Box>
              <Text fontWeight="bold">{widget.name}</Text>

              <Text>{widget.description || ''}</Text>
              <WidgetDate>
                Created:{' '}
                {formatRelative(parseISO(widget.createdAt), new Date())}
              </WidgetDate>
            </Box>
          ))}
          <Box
            width="300px"
            bgColor="white.100"
            border={`1px dashed ${theme.colors.gray[500]}`}
            p="15px"
            cursor="pointer"
            onClick={() => navigate('/widget/create')}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column">
            <IoIosAddCircleOutline stroke="gray.500" />
            <Text fontWeight="bold" color="gray.500">
              {translatedData['create-new-widget']}
            </Text>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box my={10}>
        <Heading as="h2" size="lg" color="gray.700">
          {translatedData['recharge-your-account']}
        </Heading>
        <Spacer h={4} />
        <CreditPackageCards
          buyNow={buyNow}
          isLoading={isBillingLoading}
          displayFeatures={false}
        />
      </Box>
      <Divider />
      <Box my={10}>
        <Text fontSize="2xl" fontWeight="bold">
          {translatedData['support']}
        </Text>
        <Text mt={3}>
          {translatedData['support-text']}
          <br />
          <br />
          {translatedData['thankyou-text']}
        </Text>
      </Box>
    </PageContainer>
  );
};
