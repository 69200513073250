import React, {useEffect, useState, useContext, useCallback} from 'react';
import {
  Button,
  Box,
  Text,
  Heading as HeadText,
  Divider,
  Input,
  useToast,
} from '@chakra-ui/react';
import {useAuth} from 'Auth';
import {LanguageContext} from 'App';
import {API, graphqlOperation} from 'aws-amplify';
import {PageLoading} from 'components/page/page-loading';
import {getUser} from 'graphql/queries';
import {updateCompany, updateUser} from 'graphql/mutations';
import PhoneInput from 'react-phone-input-2';
import {PageContainer} from '../components/page/page-container';
import LanguageSelector from 'components/utils/language-selector';
import {PageHeader} from 'components/page/page-header-section';
import {FiSettings} from 'react-icons/fi';

export const AccountSettings = () => {
  const {auth} = useAuth();
  const toast = useToast();
  const {translatedData} = useContext(LanguageContext);
  const [isLoading, setIsLoading] = useState(true);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');

  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    if (auth?.user?.attributes['custom:isOnboarded']) fetch();
  }, [auth?.user]);

  const fetch = useCallback(async () => {
    setIsLoading(true);
    try {
      const userid = auth.user.attributes.sub;
      const response = (await API.graphql(
        graphqlOperation(getUser, {id: userid}),
      )) as any;
      const user = response.data.getUser;
      setFirstName(user.firstName || '');
      setLastName(user.lastName || '');
      setPhone(user.phone || '');
      setCompany(user.company.name || '');
    } catch (ex) {
      console.log(ex);
    }
    setIsLoading(false);
  }, [auth?.user]);

  const showToast = (message: any, status: any = 'error') => {
    toast({
      description: `${message}`,
      position: 'top',
      status: status,
      isClosable: true,
    });
  };

  const saveAccount = async () => {
    if (!firstName || !lastName || !phone) {
      showToast('Input all fields', 'warning');
      return;
    }
    setIsLoading(true);
    const userid = auth.user.attributes.sub;
    const response = await API.graphql(
      graphqlOperation(updateUser, {
        input: {
          id: userid,
          firstName,
          lastName,
          phone,
        },
      }),
    );
    console.log(response);
    await fetch();
  };

  const saveCompany = async () => {
    if (!company) {
      showToast('Input company name', 'warning');
      return;
    }

    setIsLoading(true);
    (await API.graphql(
      graphqlOperation(updateCompany, {
        input: {
          id: auth.user.attributes['custom:tenantId'],
          name: company,
        },
      }) as any,
    )) as any;

    await fetch();
  };

  const updatePassword = async () => {
    if (!password) {
      showToast('Input Password', 'warning');
      return;
    }
    if (password !== confirmPassword) {
      showToast(`Confirm Password doesn't match`, 'warning');
      return;
    }

    setIsLoading(true);
    try {
      await auth.changePassword(oldPassword, password);
      showToast('Password updated', 'success');
    } catch (ex: any) {
      showToast(ex.message || 'Update password failed');
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <PageLoading />}
      <PageContainer>
        <PageHeader
          title={translatedData['account-settings']}
          description={translatedData['account-settings-description']}
          TitleIcon={FiSettings}
        />
        <Box>
          <HeadText size="md">{translatedData['dashboard-settings']}</HeadText>
          <Divider marginY={2} />
          <Box maxWidth={300}>
            <Box marginTop={5}>
              <Text>{translatedData['language']}</Text>
              <LanguageSelector />
            </Box>
          </Box>
        </Box>
        <Box marginTop={12}>
          <HeadText size="md">{translatedData['my-account']}</HeadText>
          <Divider marginY={2} />
          <Box maxWidth={300}>
            <Box marginTop={5}>
              <Text>{translatedData['email-address']}</Text>
              <Input
                placeholder={translatedData['email-address']}
                value={auth?.user?.attributes['email']}
                disabled
              />
            </Box>
            <Box marginTop={5}>
              <Text>{translatedData['first-name']}</Text>
              <Input
                placeholder={translatedData['first-name']}
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
            </Box>
            <Box marginTop={5}>
              <Text>{translatedData['last-name']}</Text>
              <Input
                placeholder={translatedData['last-name']}
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />
            </Box>
            <Box marginTop={5}>
              <Text>{translatedData['phone']}</Text>
              <PhoneInput
                country={'us'}
                inputStyle={{width: '100%'}}
                placeholder={translatedData['phone']}
                value={phone}
                onChange={setPhone}
              />
            </Box>
          </Box>
          <Button marginTop={5} onClick={saveAccount}>
            {translatedData['save-account']}
          </Button>
        </Box>
        <Box marginTop={12}>
          <HeadText size="md">{translatedData['company']}</HeadText>
          <Divider marginY={2} />
          <Box maxWidth={300}>
            <Box marginTop={5}>
              <Text>{translatedData['company']}</Text>
              <Input
                placeholder={translatedData['company']}
                value={company}
                onChange={e => setCompany(e.target.value)}
              />
            </Box>
          </Box>
          <Button marginTop={5} onClick={saveCompany}>
            {translatedData['save-company']}
          </Button>
        </Box>
        <Box marginTop={12}>
          <HeadText size="md">{translatedData['password']}</HeadText>
          <Divider marginY={2} />
          <Box maxWidth={300}>
            <Box marginTop={5}>
              <Text>{translatedData['old-password']}</Text>
              <Input
                type="password"
                placeholder={translatedData['old-password']}
                value={oldPassword}
                onChange={e => setOldPassword(e.target.value)}
              />
            </Box>
            <Box marginTop={5}>
              <Text>{translatedData['new-password']}</Text>
              <Input
                type="password"
                placeholder={translatedData['new-password']}
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </Box>
            <Box marginTop={5}>
              <Text>{translatedData['confirm-password']}</Text>
              <Input
                type="password"
                placeholder={translatedData['confirm-password']}
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
              />
            </Box>
          </Box>
          <Button marginTop={5} onClick={updatePassword}>
            {translatedData['update-password']}
          </Button>
        </Box>
      </PageContainer>
    </>
  );
};
