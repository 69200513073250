import styled from 'styled-components';
import {Colors} from '../../utils/colors';

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${Colors.gray};
  margin-bottom: 20px;
  padding-bottom: 22px;

  @media screen and (max-width: 768px) {
    padding-bottom: 5px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }
`;
