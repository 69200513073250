import React, {useContext, useEffect, useState} from 'react';
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Switch,
} from '@chakra-ui/react';
import {Table, TableCaption, Tbody, Td, Th, Thead, Tr} from '@chakra-ui/table';
import {LanguageContext} from 'App';
import {API, graphqlOperation} from 'aws-amplify';
import {updateApplication} from 'graphql/mutations';
import {FiMoreVertical} from 'react-icons/fi';
import {StyledTableContainer} from 'components/ui/table/table';
import {PageHeader} from 'components/page/page-header-section';

export const ApplicationLanguages = (props: any) => {
  const {application} = props;
  const {languages, translatedData} = useContext(LanguageContext);
  const [supportedLanguages, setSupportedLanguages] = useState<any>([]);

  useEffect(() => {
    if (!application) return;
    const _languages = application?.supportedLanguages || [];
    setSupportedLanguages(
      languages.map((l: any) => ({
        ...l,
        selected: _languages.findIndex((ll: any) => l.code === ll.code) > -1,
      })),
    );
  }, [application]);

  const updateSupportedLanguages = async () => {
    const activeLanguages = supportedLanguages
      .filter((l: any) => l.selected)
      .map((l: any) => ({
        code: l.code,
        label: l.label,
        autoTranslate: true,
      }));

    return (await API.graphql(
      graphqlOperation(updateApplication, {
        input: {
          id: application.id,
          supportedLanguages: activeLanguages,
        },
      }),
    )) as any;
  };

  const handleChange = async (lang: any, checked: boolean) => {
    lang.selected = checked;

    const appResponse = await updateSupportedLanguages();
    if (appResponse) {
      setSupportedLanguages([...supportedLanguages]);
    }
  };

  const enableAll = async () => {
    for (const l of supportedLanguages) {
      l.selected = true;
    }

    const appResponse = await updateSupportedLanguages();
    if (appResponse) {
      setSupportedLanguages([...supportedLanguages]);
    }
  };

  const disableAll = async () => {
    for (const l of supportedLanguages) {
      l.selected = false;
    }

    const appResponse = await updateSupportedLanguages();
    if (appResponse) {
      setSupportedLanguages([...supportedLanguages]);
    }
  };

  return (
    <>
      <PageHeader
        title=""
        HeaderActions={
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<FiMoreVertical />}
              variant="outline"
            />
            <MenuList>
              <MenuItem onClick={enableAll}>
                {translatedData['enable-all']}
              </MenuItem>
              <MenuItem onClick={disableAll}>
                {translatedData['disable-all']}
              </MenuItem>
            </MenuList>
          </Menu>
        }
      />

      <StyledTableContainer>
        <Table variant="simple">
          <TableCaption>{translatedData['available-languages']}</TableCaption>
          <Thead>
            <Tr>
              <Th>{translatedData['code']}</Th>
              <Th>{translatedData['language']}</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {supportedLanguages.map((lang: any) => (
              <Tr key={lang.code}>
                <Td>{lang.code}</Td>
                <Td>{lang.label}</Td>
                <Td>
                  <Switch
                    colorScheme="teal"
                    size="lg"
                    isChecked={lang.selected}
                    onChange={e => handleChange(lang, e.target.checked)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </StyledTableContainer>
    </>
  );
};
