import React, {useState, useContext} from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
} from '@chakra-ui/react';
import {API, Auth, graphqlOperation} from 'aws-amplify';
import {updateCompany, updateUser} from 'graphql/mutations';
import {useForm} from 'react-hook-form';
import 'react-phone-input-2/lib/style.css';
// import PhoneInput from 'react-phone-input-2';
import {trackSignup} from 'utils/usePageTracking';
import {LanguageContext} from 'App';
import {useAuth} from 'Auth';

export function BlockModal({isOpen, onClose}: any) {
  const {translatedData} = useContext(LanguageContext);
  const {auth} = useAuth();

  const {register, handleSubmit} = useForm({
    defaultValues: {
      firstName: auth.user.attributes.given_name,
      lastName: auth.user.attributes.family_name,
      companyName: '',
      interest: '',
    },
  });
  const [isSaving, setIsSaving] = useState(false);
  const [isSkipping, setIsSkipping] = useState(false);
  // const [phone, setPhone] = useState('');

  // const setPhonenumber = (num: any) => {
  //   setPhone(num);
  // };

  const onSubmit = async (data: any) => {
    setIsSaving(true);

    trackSignup();

    const user = auth.user;
    const {companyName, firstName, lastName, interest} = data;
    const payload: {
      id: string;
      interest: string;
      firstName?: string;
      lastName?: string;
    } = {
      interest,
      id: user.attributes.sub,
    };

    if (
      !auth.user.attributes.given_name ||
      auth.user.attributes.given_name === ''
    ) {
      payload['firstName'] = firstName;
    }
    if (
      !auth.user.attributes.family_name ||
      auth.user.attributes.family_name === ''
    ) {
      payload['lastName'] = lastName;
    }
    const updateUserResponse = (await API.graphql(
      graphqlOperation(updateUser, {
        input: payload,
      }) as any,
    )) as any;

    (await API.graphql(
      graphqlOperation(updateCompany, {
        input: {
          id: updateUserResponse.data.updateUser.companyID,
          name: companyName,
        },
      }) as any,
    )) as any;

    await Auth.updateUserAttributes(user, {
      'custom:isOnboarded': 'true',
    });
    setIsSaving(false);
    window.location.href = '/';
  };

  const onSkip = async () => {
    setIsSkipping(true);
    await Auth.updateUserAttributes(auth.user, {
      'custom:isOnboarded': 'true',
    });
    setIsSkipping(false);
    window.location.href = '/';
  };

  const onCancel = async () => {
    await Auth.signOut();
    window.location.href = '/';
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>
              {translatedData['additional-modal-description']}
            </ModalHeader>
            <ModalBody pb={6}>
              <FormControl mt={4} isRequired>
                <FormLabel>{translatedData['company']}</FormLabel>
                <Input
                  {...register(`companyName`)}
                  placeholder={translatedData['company']}
                  required
                />
              </FormControl>
              {(!auth.user.attributes.given_name ||
                auth.user.attributes.given_name === '') && (
                <FormControl isRequired mt={4}>
                  <FormLabel>{translatedData['first-name']}</FormLabel>
                  <Input
                    {...register(`firstName`)}
                    placeholder={translatedData['first-name']}
                    required
                  />
                </FormControl>
              )}
              {(!auth.user.attributes.family_name ||
                auth.user.attributes.family_name === '') && (
                <FormControl isRequired mt={4}>
                  <FormLabel>{translatedData['last-name']}</FormLabel>
                  <Input
                    {...register(`lastName`)}
                    placeholder={translatedData['last-name']}
                    required
                  />
                </FormControl>
              )}
              <FormControl mt={4}>
                <FormLabel>{translatedData['product-interest']}</FormLabel>

                <Select placeholder="Select option" {...register(`interest`)}>
                  <option value="Figma">Figma</option>
                  <option value="Adobe XD">Adobe XD</option>
                  <option value="Web Widget">
                    {translatedData['web-widget']}
                  </option>
                </Select>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Box display="flex" justifyContent="flex-end" width="100%">
                {/* <Button isLoading={isSkipping} onClick={() => onSkip()}>
                  {translatedData['skip']}
                </Button> */}
                <Box display="flex" gap="10px">
                  {/* <Button onClick={() => onCancel()}>
                    {translatedData['cancel']}
                  </Button> */}
                  <Button
                    colorScheme="pink"
                    isLoading={isSaving}
                    bg={'pink.900'}
                    _hover={{bg: 'pink.800'}}
                    mr={3}
                    type="submit">
                    {translatedData['save']}
                  </Button>
                </Box>
              </Box>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}
