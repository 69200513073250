import React, {useCallback, useEffect, useState, useContext} from 'react';
import {Storage, API, graphqlOperation} from 'aws-amplify';
import {useDisclosure} from '@chakra-ui/hooks';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  Tab,
  Table,
  TableCaption,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import styled from 'styled-components';
import {PageContainer} from 'components/page/page-container';
import {PageLoading} from 'components/page/page-loading';
import {StyledTableContainer, StyledTr} from 'components/ui/table/table';
import {formatRelative, parseISO} from 'date-fns';
import {createVersion} from 'graphql/mutations';
import {getContent} from 'graphql/queries';
import {BsTranslate} from 'react-icons/bs';
import {VscVersions} from 'react-icons/vsc';
import {useNavigate, useParams} from 'react-router-dom';
import {LanguageContext} from 'App';
import {AddFile} from './_add_file';
import {Languages} from './_languages';
import {Version} from 'API';
import {MobileHide} from 'components/page/mobilehide';
import {PageHeader} from 'components/page/page-header-section';

const TabIcon = styled.span`
  padding-right: 8px;
`;
export const ContentScreen = () => {
  const {translatedData} = useContext(LanguageContext);

  const [contentData, setContentData] = useState({
    id: '',
    name: '',
    value: '',
    type: '',
  });
  const [versions, setVersions] = useState([]);
  const [name, setName] = useState('');
  const [text, setText] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [enabledLanguages, setEnabledLanguages] = useState([]);
  const [fileKey, setFileKey] = useState('');
  const [imageKey, setImageKey] = useState('');
  const [videoKey, setVideoKey] = useState('');
  const [storageKey, setStorageKey] = useState('');
  const [uploadStatus, setUploadStatus] = useState('notStarted');
  const params = useParams();

  const handleCreateVersion = async () => {
    setIsSaving(true);
    const response = (await API.graphql(
      graphqlOperation(createVersion, {
        input: {
          contentID: params.contentId,
          name,
          type: 'text',
          value: text,
        },
      }),
    )) as any;
    setIsSaving(true);
    console.log(response.data);
    await fetchData();
    setIsSaving(false);
    onClose();
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    // Simple query
    const responseContent = (await API.graphql(
      graphqlOperation(getContent, {
        id: params.contentId,
      }) as any,
    )) as any;

    const content = responseContent.data.getContent;
    content.versions.items = content.versions?.items ?? [];
    content.versions.items.sort((a: Version, b: Version) =>
      a.createdAt > b.createdAt ? -1 : 1,
    );

    setContentData(content);
    setEnabledLanguages(content.enabledLanguages || []);
    setVersions(content.versions.items);
    setIsLoading(false);
  }, []);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onChangeFile = async (e: any) => {
    setUploadStatus('uploading');
    const file = e.target.files[0];
    const timestamp = new Date().toISOString();
    const result = await Storage.put(file.name + timestamp, file);
    setStorageKey(result.key);
    console.log(result);
    setUploadStatus('complete');
  };

  const uploadFile = async () => {
    const response2 = (await API.graphql(
      graphqlOperation(createVersion, {
        input: {
          contentID: params.contentId,
          name: fileKey,
          type: 'file',
          value: storageKey,
        },
      }) as any,
    )) as any;
    console.log(response2);
    fetchData();
    onCloseFile();
  };
  const uploadImage = async () => {
    const response2 = (await API.graphql(
      graphqlOperation(createVersion, {
        input: {
          contentID: params.contentId,
          name: imageKey,
          type: 'image',
          value: storageKey,
        },
      }) as any,
    )) as any;
    console.log(response2);
    fetchData();
    setUploadStatus('notStarted');
    onCloseImage();
  };
  const uploadVideo = async () => {
    const response2 = (await API.graphql(
      graphqlOperation(createVersion, {
        input: {
          contentID: params.contentId,
          name: videoKey,
          type: 'video',
          value: storageKey,
        },
      }) as any,
    )) as any;
    console.log(response2);
    fetchData();
    setUploadStatus('notStarted');
    onCloseVideo();
  };

  const navigate = useNavigate();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {
    isOpen: isOpenFile,
    onOpen: onOpenFile,
    onClose: onCloseFile,
  } = useDisclosure();

  const {
    isOpen: isOpenImage,
    onOpen: onOpenImage,
    onClose: onCloseImage,
  } = useDisclosure();
  const {
    isOpen: isOpenVideo,
    onOpen: onOpenVideo,
    onClose: onCloseVideo,
  } = useDisclosure();

  return isLoading ? (
    <PageLoading />
  ) : (
    <PageContainer>
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink href="/content">
            {translatedData['content']}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="#">
            {contentData.name.length < 50
              ? contentData.name
              : `${contentData.name.substring(0, 50)} ...`}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <br />
      <PageHeader
        title={`${translatedData['content']}: 
          ${
            contentData.name.length < 30
              ? contentData.name
              : `${contentData.name.substring(0, 30)} ...`
          }`}
        description={`This page is a dedicated space for managing the different versions of your selected content. It provides a clear and organized view of all content versions, allowing you to track changes, compare different versions. This feature ensures that your content is always accurate and up-to-date, and gives you the flexibility to experiment with changes knowing you can easily return to a previous version. The Version page is a crucial tool for preserving the integrity of your content throughout its lifecycle.`}
        HeaderActions={
          <Button
            colorScheme="pink"
            onClick={() => {
              if (contentData.type === 'text') onOpen();
              if (contentData.type === 'file') onOpenFile();
              if (contentData.type === 'image') onOpenImage();
              if (contentData.type === 'video') onOpenVideo();
            }}>
            {translatedData['create-new-version']}
          </Button>
        }
      />

      <Tabs>
        <TabList>
          <Tab>
            <TabIcon>
              <VscVersions />
            </TabIcon>
            <MobileHide>{translatedData['versions']}</MobileHide>
          </Tab>
          {contentData.type === 'text' && (
            <Tab>
              <TabIcon>
                <BsTranslate />
              </TabIcon>
              <MobileHide>{translatedData['manage-translation']}</MobileHide>
            </Tab>
          )}
        </TabList>

        <TabPanels>
          <TabPanel>
            <StyledTableContainer>
              <Table variant="simple">
                <TableCaption>{translatedData['all-versions']}</TableCaption>
                <Thead>
                  <Tr>
                    <Th>{translatedData['version']}</Th>
                    <Th>{translatedData['value']}</Th>
                    <Th>{translatedData['created-at']}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {versions.length > 0 ? (
                    versions.map((version: any) => (
                      <StyledTr
                        key={version.id}
                        onClick={() =>
                          navigate(
                            `/content/${contentData.id}/versions/${version.id}`,
                          )
                        }>
                        <Td>{version.name}</Td>
                        <Td>{version.value}</Td>
                        <Td textAlign="right">
                          {formatRelative(
                            parseISO(version.createdAt),
                            new Date(),
                          )}
                        </Td>
                      </StyledTr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan={4} textAlign="center">
                        {translatedData['no-results-found']}
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </StyledTableContainer>
          </TabPanel>
          {contentData.type === 'text' && (
            <TabPanel>
              <Languages contentLanguages={enabledLanguages} />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{translatedData['new-version']}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="text">
              <FormLabel>{translatedData['name']}</FormLabel>
              <Input type="text" onChange={e => setName(e.target.value)} />
              <FormHelperText>
                {translatedData['version-name-description']}
              </FormHelperText>
            </FormControl>
            <br />
            <FormControl id="name">
              <FormLabel>{translatedData['text']}</FormLabel>
              <Textarea onChange={e => setText(e.target.value)} />
              {/* <Input type="text" onChange={e => setText(e.target.value)} /> */}
              <FormHelperText>{translatedData['enter-content']}</FormHelperText>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              {translatedData['close']}
            </Button>
            <Button
              colorScheme="pink"
              isLoading={isSaving}
              onClick={handleCreateVersion}>
              {translatedData['save']}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AddFile
        isOpen={isOpenFile}
        onClose={onCloseFile}
        onChangeFile={onChangeFile}
        uploadFile={uploadFile}
        setFileKey={setFileKey}
        uploadStatus={uploadStatus}
        accept=".txt,.css,.js"
      />
      <AddFile
        isOpen={isOpenImage}
        onClose={onCloseImage}
        onChangeFile={onChangeFile}
        uploadFile={uploadImage}
        setFileKey={setImageKey}
        uploadStatus={uploadStatus}
        accept=".png,.jpg,.jpeg"
      />
      <AddFile
        isOpen={isOpenVideo}
        onClose={onCloseVideo}
        onChangeFile={onChangeFile}
        uploadFile={uploadVideo}
        setFileKey={setVideoKey}
        uploadStatus={uploadStatus}
        accept=".mp4,.mov"
      />
    </PageContainer>
  );
};
