import React, {useContext} from 'react';
import {Menu, MenuButton, MenuItem, MenuList, Button} from '@chakra-ui/react';
import {BsFillCaretDownFill} from 'react-icons/bs';
import {LanguageContext} from 'App';

interface Props {
  buttonText?: string;
  handleClick(): void;
  handleClickImportCSV(): void;
  handleClickUploadFile(): void;
  handleClickUploadImage(): void;
  handleClickUploadVideo(): void;
}

export const Dropdown = ({
  buttonText = 'Actions',
  handleClick,
  handleClickImportCSV,
  handleClickUploadFile,
  handleClickUploadImage,
  handleClickUploadVideo,
}: Props) => {
  const {translatedData} = useContext(LanguageContext);

  return (
    <Menu colorScheme="pink">
      <MenuButton as={Button} rightIcon={<BsFillCaretDownFill />}>
        {buttonText}
      </MenuButton>
      <MenuList>
        <MenuItem onClick={handleClick}>{translatedData['add-text']}</MenuItem>
        <MenuItem onClick={handleClickImportCSV}>
          {translatedData['import-csv']}
        </MenuItem>
        <MenuItem onClick={handleClickUploadFile}>
          {translatedData['upload-file']}
        </MenuItem>
        <MenuItem onClick={handleClickUploadImage}>
          {translatedData['upload-image']}
        </MenuItem>
        <MenuItem onClick={handleClickUploadVideo}>
          {translatedData['upload-video']}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
