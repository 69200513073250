/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      owner
      tenantID
      credits
      usage
      insertUsage
      widgetUsage
      freeTrialUsage
      createdAtUnix
      updatedAtUnix
      createdAt
      updatedAt
      users {
        items {
          id
          companyID
          name
          username
          email
          isOnboarded
          phone
          firstName
          lastName
          lang
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
      contents {
        items {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
      applications {
        items {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
      tokens {
        items {
          id
          tenantID
          apiKey
          creditsUsage
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      owner
      tenantID
      credits
      usage
      insertUsage
      widgetUsage
      freeTrialUsage
      createdAtUnix
      updatedAtUnix
      createdAt
      updatedAt
      users {
        items {
          id
          companyID
          name
          username
          email
          isOnboarded
          phone
          firstName
          lastName
          lang
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
      contents {
        items {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
      applications {
        items {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
      tokens {
        items {
          id
          tenantID
          apiKey
          creditsUsage
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      owner
      tenantID
      credits
      usage
      insertUsage
      widgetUsage
      freeTrialUsage
      createdAtUnix
      updatedAtUnix
      createdAt
      updatedAt
      users {
        items {
          id
          companyID
          name
          username
          email
          isOnboarded
          phone
          firstName
          lastName
          lang
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
      contents {
        items {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
      applications {
        items {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
      tokens {
        items {
          id
          tenantID
          apiKey
          creditsUsage
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      companyID
      name
      username
      email
      isOnboarded
      phone
      firstName
      lastName
      lang
      createdAtUnix
      updatedAtUnix
      createdAt
      updatedAt
      company {
        id
        name
        owner
        tenantID
        credits
        usage
        insertUsage
        widgetUsage
        freeTrialUsage
        createdAtUnix
        updatedAtUnix
        createdAt
        updatedAt
        users {
          nextToken
        }
        contents {
          nextToken
        }
        applications {
          nextToken
        }
        tokens {
          nextToken
        }
      }
      tenantID
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      companyID
      name
      username
      email
      isOnboarded
      phone
      firstName
      lastName
      lang
      createdAtUnix
      updatedAtUnix
      createdAt
      updatedAt
      company {
        id
        name
        owner
        tenantID
        credits
        usage
        insertUsage
        widgetUsage
        freeTrialUsage
        createdAtUnix
        updatedAtUnix
        createdAt
        updatedAt
        users {
          nextToken
        }
        contents {
          nextToken
        }
        applications {
          nextToken
        }
        tokens {
          nextToken
        }
      }
      tenantID
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      companyID
      name
      username
      email
      isOnboarded
      phone
      firstName
      lastName
      lang
      createdAtUnix
      updatedAtUnix
      createdAt
      updatedAt
      company {
        id
        name
        owner
        tenantID
        credits
        usage
        insertUsage
        widgetUsage
        freeTrialUsage
        createdAtUnix
        updatedAtUnix
        createdAt
        updatedAt
        users {
          nextToken
        }
        contents {
          nextToken
        }
        applications {
          nextToken
        }
        tokens {
          nextToken
        }
      }
      tenantID
    }
  }
`;
export const createContent = /* GraphQL */ `
  mutation CreateContent(
    $input: CreateContentInput!
    $condition: ModelContentConditionInput
  ) {
    createContent(input: $input, condition: $condition) {
      id
      companyID
      name
      type
      createdAt
      updatedAt
      enabledLanguages {
        label
        code
        autoTranslate
      }
      company {
        id
        name
        owner
        tenantID
        credits
        usage
        insertUsage
        widgetUsage
        freeTrialUsage
        createdAtUnix
        updatedAtUnix
        createdAt
        updatedAt
        users {
          nextToken
        }
        contents {
          nextToken
        }
        applications {
          nextToken
        }
        tokens {
          nextToken
        }
      }
      tenantID
      versions {
        items {
          id
          contentID
          name
          value
          type
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
    }
  }
`;
export const updateContent = /* GraphQL */ `
  mutation UpdateContent(
    $input: UpdateContentInput!
    $condition: ModelContentConditionInput
  ) {
    updateContent(input: $input, condition: $condition) {
      id
      companyID
      name
      type
      createdAt
      updatedAt
      enabledLanguages {
        label
        code
        autoTranslate
      }
      company {
        id
        name
        owner
        tenantID
        credits
        usage
        insertUsage
        widgetUsage
        freeTrialUsage
        createdAtUnix
        updatedAtUnix
        createdAt
        updatedAt
        users {
          nextToken
        }
        contents {
          nextToken
        }
        applications {
          nextToken
        }
        tokens {
          nextToken
        }
      }
      tenantID
      versions {
        items {
          id
          contentID
          name
          value
          type
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
    }
  }
`;
export const deleteContent = /* GraphQL */ `
  mutation DeleteContent(
    $input: DeleteContentInput!
    $condition: ModelContentConditionInput
  ) {
    deleteContent(input: $input, condition: $condition) {
      id
      companyID
      name
      type
      createdAt
      updatedAt
      enabledLanguages {
        label
        code
        autoTranslate
      }
      company {
        id
        name
        owner
        tenantID
        credits
        usage
        insertUsage
        widgetUsage
        freeTrialUsage
        createdAtUnix
        updatedAtUnix
        createdAt
        updatedAt
        users {
          nextToken
        }
        contents {
          nextToken
        }
        applications {
          nextToken
        }
        tokens {
          nextToken
        }
      }
      tenantID
      versions {
        items {
          id
          contentID
          name
          value
          type
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
    }
  }
`;
export const createVersion = /* GraphQL */ `
  mutation CreateVersion(
    $input: CreateVersionInput!
    $condition: ModelVersionConditionInput
  ) {
    createVersion(input: $input, condition: $condition) {
      id
      contentID
      name
      value
      type
      createdAt
      updatedAt
      content {
        id
        companyID
        name
        type
        createdAt
        updatedAt
        enabledLanguages {
          label
          code
          autoTranslate
        }
        company {
          id
          name
          owner
          tenantID
          credits
          usage
          insertUsage
          widgetUsage
          freeTrialUsage
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
        }
        tenantID
        versions {
          nextToken
        }
      }
      tenantID
      translations {
        items {
          id
          versionID
          language
          text
          status
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
      environments {
        items {
          id
          environmentID
          versionID
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
    }
  }
`;
export const updateVersion = /* GraphQL */ `
  mutation UpdateVersion(
    $input: UpdateVersionInput!
    $condition: ModelVersionConditionInput
  ) {
    updateVersion(input: $input, condition: $condition) {
      id
      contentID
      name
      value
      type
      createdAt
      updatedAt
      content {
        id
        companyID
        name
        type
        createdAt
        updatedAt
        enabledLanguages {
          label
          code
          autoTranslate
        }
        company {
          id
          name
          owner
          tenantID
          credits
          usage
          insertUsage
          widgetUsage
          freeTrialUsage
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
        }
        tenantID
        versions {
          nextToken
        }
      }
      tenantID
      translations {
        items {
          id
          versionID
          language
          text
          status
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
      environments {
        items {
          id
          environmentID
          versionID
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
    }
  }
`;
export const deleteVersion = /* GraphQL */ `
  mutation DeleteVersion(
    $input: DeleteVersionInput!
    $condition: ModelVersionConditionInput
  ) {
    deleteVersion(input: $input, condition: $condition) {
      id
      contentID
      name
      value
      type
      createdAt
      updatedAt
      content {
        id
        companyID
        name
        type
        createdAt
        updatedAt
        enabledLanguages {
          label
          code
          autoTranslate
        }
        company {
          id
          name
          owner
          tenantID
          credits
          usage
          insertUsage
          widgetUsage
          freeTrialUsage
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
        }
        tenantID
        versions {
          nextToken
        }
      }
      tenantID
      translations {
        items {
          id
          versionID
          language
          text
          status
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
      environments {
        items {
          id
          environmentID
          versionID
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
    }
  }
`;
export const createTranslation = /* GraphQL */ `
  mutation CreateTranslation(
    $input: CreateTranslationInput!
    $condition: ModelTranslationConditionInput
  ) {
    createTranslation(input: $input, condition: $condition) {
      id
      versionID
      language
      text
      status
      createdAt
      updatedAt
      version {
        id
        contentID
        name
        value
        type
        createdAt
        updatedAt
        content {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        tenantID
        translations {
          nextToken
        }
        environments {
          nextToken
        }
      }
      tenantID
    }
  }
`;
export const updateTranslation = /* GraphQL */ `
  mutation UpdateTranslation(
    $input: UpdateTranslationInput!
    $condition: ModelTranslationConditionInput
  ) {
    updateTranslation(input: $input, condition: $condition) {
      id
      versionID
      language
      text
      status
      createdAt
      updatedAt
      version {
        id
        contentID
        name
        value
        type
        createdAt
        updatedAt
        content {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        tenantID
        translations {
          nextToken
        }
        environments {
          nextToken
        }
      }
      tenantID
    }
  }
`;
export const deleteTranslation = /* GraphQL */ `
  mutation DeleteTranslation(
    $input: DeleteTranslationInput!
    $condition: ModelTranslationConditionInput
  ) {
    deleteTranslation(input: $input, condition: $condition) {
      id
      versionID
      language
      text
      status
      createdAt
      updatedAt
      version {
        id
        contentID
        name
        value
        type
        createdAt
        updatedAt
        content {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        tenantID
        translations {
          nextToken
        }
        environments {
          nextToken
        }
      }
      tenantID
    }
  }
`;
export const createApplication = /* GraphQL */ `
  mutation CreateApplication(
    $input: CreateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    createApplication(input: $input, condition: $condition) {
      id
      companyID
      name
      type
      supportedLanguages {
        label
        code
        autoTranslate
      }
      createdAt
      updatedAt
      company {
        id
        name
        owner
        tenantID
        credits
        usage
        insertUsage
        widgetUsage
        freeTrialUsage
        createdAtUnix
        updatedAtUnix
        createdAt
        updatedAt
        users {
          nextToken
        }
        contents {
          nextToken
        }
        applications {
          nextToken
        }
        tokens {
          nextToken
        }
      }
      tenantID
      environments {
        items {
          id
          applicationID
          name
          url
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
    }
  }
`;
export const updateApplication = /* GraphQL */ `
  mutation UpdateApplication(
    $input: UpdateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    updateApplication(input: $input, condition: $condition) {
      id
      companyID
      name
      type
      supportedLanguages {
        label
        code
        autoTranslate
      }
      createdAt
      updatedAt
      company {
        id
        name
        owner
        tenantID
        credits
        usage
        insertUsage
        widgetUsage
        freeTrialUsage
        createdAtUnix
        updatedAtUnix
        createdAt
        updatedAt
        users {
          nextToken
        }
        contents {
          nextToken
        }
        applications {
          nextToken
        }
        tokens {
          nextToken
        }
      }
      tenantID
      environments {
        items {
          id
          applicationID
          name
          url
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
    }
  }
`;
export const deleteApplication = /* GraphQL */ `
  mutation DeleteApplication(
    $input: DeleteApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    deleteApplication(input: $input, condition: $condition) {
      id
      companyID
      name
      type
      supportedLanguages {
        label
        code
        autoTranslate
      }
      createdAt
      updatedAt
      company {
        id
        name
        owner
        tenantID
        credits
        usage
        insertUsage
        widgetUsage
        freeTrialUsage
        createdAtUnix
        updatedAtUnix
        createdAt
        updatedAt
        users {
          nextToken
        }
        contents {
          nextToken
        }
        applications {
          nextToken
        }
        tokens {
          nextToken
        }
      }
      tenantID
      environments {
        items {
          id
          applicationID
          name
          url
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
    }
  }
`;
export const createEnvironment = /* GraphQL */ `
  mutation CreateEnvironment(
    $input: CreateEnvironmentInput!
    $condition: ModelEnvironmentConditionInput
  ) {
    createEnvironment(input: $input, condition: $condition) {
      id
      applicationID
      name
      url
      createdAt
      updatedAt
      application {
        id
        companyID
        name
        type
        supportedLanguages {
          label
          code
          autoTranslate
        }
        createdAt
        updatedAt
        company {
          id
          name
          owner
          tenantID
          credits
          usage
          insertUsage
          widgetUsage
          freeTrialUsage
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
        }
        tenantID
        environments {
          nextToken
        }
      }
      tenantID
      versions {
        items {
          id
          environmentID
          versionID
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
    }
  }
`;
export const updateEnvironment = /* GraphQL */ `
  mutation UpdateEnvironment(
    $input: UpdateEnvironmentInput!
    $condition: ModelEnvironmentConditionInput
  ) {
    updateEnvironment(input: $input, condition: $condition) {
      id
      applicationID
      name
      url
      createdAt
      updatedAt
      application {
        id
        companyID
        name
        type
        supportedLanguages {
          label
          code
          autoTranslate
        }
        createdAt
        updatedAt
        company {
          id
          name
          owner
          tenantID
          credits
          usage
          insertUsage
          widgetUsage
          freeTrialUsage
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
        }
        tenantID
        environments {
          nextToken
        }
      }
      tenantID
      versions {
        items {
          id
          environmentID
          versionID
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
    }
  }
`;
export const deleteEnvironment = /* GraphQL */ `
  mutation DeleteEnvironment(
    $input: DeleteEnvironmentInput!
    $condition: ModelEnvironmentConditionInput
  ) {
    deleteEnvironment(input: $input, condition: $condition) {
      id
      applicationID
      name
      url
      createdAt
      updatedAt
      application {
        id
        companyID
        name
        type
        supportedLanguages {
          label
          code
          autoTranslate
        }
        createdAt
        updatedAt
        company {
          id
          name
          owner
          tenantID
          credits
          usage
          insertUsage
          widgetUsage
          freeTrialUsage
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
        }
        tenantID
        environments {
          nextToken
        }
      }
      tenantID
      versions {
        items {
          id
          environmentID
          versionID
          createdAt
          updatedAt
          tenantID
        }
        nextToken
      }
    }
  }
`;
export const createEnvironmentVersion = /* GraphQL */ `
  mutation CreateEnvironmentVersion(
    $input: CreateEnvironmentVersionInput!
    $condition: ModelEnvironmentVersionConditionInput
  ) {
    createEnvironmentVersion(input: $input, condition: $condition) {
      id
      environmentID
      versionID
      createdAt
      updatedAt
      version {
        id
        contentID
        name
        value
        type
        createdAt
        updatedAt
        content {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        tenantID
        translations {
          nextToken
        }
        environments {
          nextToken
        }
      }
      environment {
        id
        applicationID
        name
        url
        createdAt
        updatedAt
        application {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        tenantID
        versions {
          nextToken
        }
      }
      tenantID
    }
  }
`;
export const updateEnvironmentVersion = /* GraphQL */ `
  mutation UpdateEnvironmentVersion(
    $input: UpdateEnvironmentVersionInput!
    $condition: ModelEnvironmentVersionConditionInput
  ) {
    updateEnvironmentVersion(input: $input, condition: $condition) {
      id
      environmentID
      versionID
      createdAt
      updatedAt
      version {
        id
        contentID
        name
        value
        type
        createdAt
        updatedAt
        content {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        tenantID
        translations {
          nextToken
        }
        environments {
          nextToken
        }
      }
      environment {
        id
        applicationID
        name
        url
        createdAt
        updatedAt
        application {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        tenantID
        versions {
          nextToken
        }
      }
      tenantID
    }
  }
`;
export const deleteEnvironmentVersion = /* GraphQL */ `
  mutation DeleteEnvironmentVersion(
    $input: DeleteEnvironmentVersionInput!
    $condition: ModelEnvironmentVersionConditionInput
  ) {
    deleteEnvironmentVersion(input: $input, condition: $condition) {
      id
      environmentID
      versionID
      createdAt
      updatedAt
      version {
        id
        contentID
        name
        value
        type
        createdAt
        updatedAt
        content {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        tenantID
        translations {
          nextToken
        }
        environments {
          nextToken
        }
      }
      environment {
        id
        applicationID
        name
        url
        createdAt
        updatedAt
        application {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        tenantID
        versions {
          nextToken
        }
      }
      tenantID
    }
  }
`;
export const createRequest = /* GraphQL */ `
  mutation CreateRequest(
    $input: CreateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    createRequest(input: $input, condition: $condition) {
      id
      environmentID
      metadata
      language
      createdAt
      updatedAt
      environment {
        id
        applicationID
        name
        url
        createdAt
        updatedAt
        application {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        tenantID
        versions {
          nextToken
        }
      }
      tenantID
    }
  }
`;
export const updateRequest = /* GraphQL */ `
  mutation UpdateRequest(
    $input: UpdateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    updateRequest(input: $input, condition: $condition) {
      id
      environmentID
      metadata
      language
      createdAt
      updatedAt
      environment {
        id
        applicationID
        name
        url
        createdAt
        updatedAt
        application {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        tenantID
        versions {
          nextToken
        }
      }
      tenantID
    }
  }
`;
export const deleteRequest = /* GraphQL */ `
  mutation DeleteRequest(
    $input: DeleteRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    deleteRequest(input: $input, condition: $condition) {
      id
      environmentID
      metadata
      language
      createdAt
      updatedAt
      environment {
        id
        applicationID
        name
        url
        createdAt
        updatedAt
        application {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        tenantID
        versions {
          nextToken
        }
      }
      tenantID
    }
  }
`;
export const createToken = /* GraphQL */ `
  mutation CreateToken(
    $input: CreateTokenInput!
    $condition: ModelTokenConditionInput
  ) {
    createToken(input: $input, condition: $condition) {
      id
      tenantID
      apiKey
      creditsUsage
      createdAt
      updatedAt
      company {
        id
        name
        owner
        tenantID
        credits
        usage
        insertUsage
        widgetUsage
        freeTrialUsage
        createdAtUnix
        updatedAtUnix
        createdAt
        updatedAt
        users {
          nextToken
        }
        contents {
          nextToken
        }
        applications {
          nextToken
        }
        tokens {
          nextToken
        }
      }
    }
  }
`;
export const updateToken = /* GraphQL */ `
  mutation UpdateToken(
    $input: UpdateTokenInput!
    $condition: ModelTokenConditionInput
  ) {
    updateToken(input: $input, condition: $condition) {
      id
      tenantID
      apiKey
      creditsUsage
      createdAt
      updatedAt
      company {
        id
        name
        owner
        tenantID
        credits
        usage
        insertUsage
        widgetUsage
        freeTrialUsage
        createdAtUnix
        updatedAtUnix
        createdAt
        updatedAt
        users {
          nextToken
        }
        contents {
          nextToken
        }
        applications {
          nextToken
        }
        tokens {
          nextToken
        }
      }
    }
  }
`;
export const deleteToken = /* GraphQL */ `
  mutation DeleteToken(
    $input: DeleteTokenInput!
    $condition: ModelTokenConditionInput
  ) {
    deleteToken(input: $input, condition: $condition) {
      id
      tenantID
      apiKey
      creditsUsage
      createdAt
      updatedAt
      company {
        id
        name
        owner
        tenantID
        credits
        usage
        insertUsage
        widgetUsage
        freeTrialUsage
        createdAtUnix
        updatedAtUnix
        createdAt
        updatedAt
        users {
          nextToken
        }
        contents {
          nextToken
        }
        applications {
          nextToken
        }
        tokens {
          nextToken
        }
      }
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      userID
      title
      body
      category
      group
      status
      type
      deliveredByEmail
      deliveredByText
      readAt
      createdAtUnix
      updatedAtUnix
      createdAt
      updatedAt
      user {
        id
        companyID
        name
        username
        email
        isOnboarded
        phone
        firstName
        lastName
        lang
        createdAtUnix
        updatedAtUnix
        createdAt
        updatedAt
        company {
          id
          name
          owner
          tenantID
          credits
          usage
          insertUsage
          widgetUsage
          freeTrialUsage
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
        }
        tenantID
      }
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      userID
      title
      body
      category
      group
      status
      type
      deliveredByEmail
      deliveredByText
      readAt
      createdAtUnix
      updatedAtUnix
      createdAt
      updatedAt
      user {
        id
        companyID
        name
        username
        email
        isOnboarded
        phone
        firstName
        lastName
        lang
        createdAtUnix
        updatedAtUnix
        createdAt
        updatedAt
        company {
          id
          name
          owner
          tenantID
          credits
          usage
          insertUsage
          widgetUsage
          freeTrialUsage
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
        }
        tenantID
      }
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      userID
      title
      body
      category
      group
      status
      type
      deliveredByEmail
      deliveredByText
      readAt
      createdAtUnix
      updatedAtUnix
      createdAt
      updatedAt
      user {
        id
        companyID
        name
        username
        email
        isOnboarded
        phone
        firstName
        lastName
        lang
        createdAtUnix
        updatedAtUnix
        createdAt
        updatedAt
        company {
          id
          name
          owner
          tenantID
          credits
          usage
          insertUsage
          widgetUsage
          freeTrialUsage
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
        }
        tenantID
      }
    }
  }
`;
export const createWidget = /* GraphQL */ `
  mutation CreateWidget(
    $input: CreateWidgetInput!
    $condition: ModelWidgetConditionInput
  ) {
    createWidget(input: $input, condition: $condition) {
      id
      environmentID
      name
      description
      urls
      tags
      sourceLanguage
      autoTranslate
      display
      bannerColor
      bannerBorderColor
      hideBanner
      hideLogo
      disabled
      formality
      step
      enableMonitoring
      createdAt
      updatedAt
      environment {
        id
        applicationID
        name
        url
        createdAt
        updatedAt
        application {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        tenantID
        versions {
          nextToken
        }
      }
      tenantID
    }
  }
`;
export const updateWidget = /* GraphQL */ `
  mutation UpdateWidget(
    $input: UpdateWidgetInput!
    $condition: ModelWidgetConditionInput
  ) {
    updateWidget(input: $input, condition: $condition) {
      id
      environmentID
      name
      description
      urls
      tags
      sourceLanguage
      autoTranslate
      display
      bannerColor
      bannerBorderColor
      hideBanner
      hideLogo
      disabled
      formality
      step
      enableMonitoring
      createdAt
      updatedAt
      environment {
        id
        applicationID
        name
        url
        createdAt
        updatedAt
        application {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        tenantID
        versions {
          nextToken
        }
      }
      tenantID
    }
  }
`;
export const deleteWidget = /* GraphQL */ `
  mutation DeleteWidget(
    $input: DeleteWidgetInput!
    $condition: ModelWidgetConditionInput
  ) {
    deleteWidget(input: $input, condition: $condition) {
      id
      environmentID
      name
      description
      urls
      tags
      sourceLanguage
      autoTranslate
      display
      bannerColor
      bannerBorderColor
      hideBanner
      hideLogo
      disabled
      formality
      step
      enableMonitoring
      createdAt
      updatedAt
      environment {
        id
        applicationID
        name
        url
        createdAt
        updatedAt
        application {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
        }
        tenantID
        versions {
          nextToken
        }
      }
      tenantID
    }
  }
`;
export const createWidgetInteraction = /* GraphQL */ `
  mutation CreateWidgetInteraction(
    $input: CreateWidgetInteractionInput!
    $condition: ModelWidgetInteractionConditionInput
  ) {
    createWidgetInteraction(input: $input, condition: $condition) {
      id
      widgetID
      type
      autoTranslate
      sourceLanguage
      targetLanguage
      count
      url
      ip
      createdAt
      updatedAt
      widget {
        id
        environmentID
        name
        description
        urls
        tags
        sourceLanguage
        autoTranslate
        display
        bannerColor
        bannerBorderColor
        hideBanner
        hideLogo
        disabled
        formality
        step
        enableMonitoring
        createdAt
        updatedAt
        environment {
          id
          applicationID
          name
          url
          createdAt
          updatedAt
          tenantID
        }
        tenantID
      }
      tenantID
    }
  }
`;
export const updateWidgetInteraction = /* GraphQL */ `
  mutation UpdateWidgetInteraction(
    $input: UpdateWidgetInteractionInput!
    $condition: ModelWidgetInteractionConditionInput
  ) {
    updateWidgetInteraction(input: $input, condition: $condition) {
      id
      widgetID
      type
      autoTranslate
      sourceLanguage
      targetLanguage
      count
      url
      ip
      createdAt
      updatedAt
      widget {
        id
        environmentID
        name
        description
        urls
        tags
        sourceLanguage
        autoTranslate
        display
        bannerColor
        bannerBorderColor
        hideBanner
        hideLogo
        disabled
        formality
        step
        enableMonitoring
        createdAt
        updatedAt
        environment {
          id
          applicationID
          name
          url
          createdAt
          updatedAt
          tenantID
        }
        tenantID
      }
      tenantID
    }
  }
`;
export const deleteWidgetInteraction = /* GraphQL */ `
  mutation DeleteWidgetInteraction(
    $input: DeleteWidgetInteractionInput!
    $condition: ModelWidgetInteractionConditionInput
  ) {
    deleteWidgetInteraction(input: $input, condition: $condition) {
      id
      widgetID
      type
      autoTranslate
      sourceLanguage
      targetLanguage
      count
      url
      ip
      createdAt
      updatedAt
      widget {
        id
        environmentID
        name
        description
        urls
        tags
        sourceLanguage
        autoTranslate
        display
        bannerColor
        bannerBorderColor
        hideBanner
        hideLogo
        disabled
        formality
        step
        enableMonitoring
        createdAt
        updatedAt
        environment {
          id
          applicationID
          name
          url
          createdAt
          updatedAt
          tenantID
        }
        tenantID
      }
      tenantID
    }
  }
`;
