import {Box, Heading, Text} from '@chakra-ui/react';
import {Colors} from 'utils/colors';

const creditsFeatures = [
  `FluentC Widget website translation services`,
  `FluentC Translate plug-in translation services`,
];
const accountFeatures = [
  'GraphQL',
  'CSV Uploads',
  'Team Management',
  'Unlimited Team Size',
  'Unlimited Web Widgets',
  'Translation Customization',
  'Email-based Customer Support',
];

export const CreditFeatures = () => {
  return (
    <Box sx={{mt: 3, width: '400px', maxWidth: '100%', mx: 'auto'}}>
      <Box
        textAlign="center"
        sx={{
          mt: 2,
          background: Colors.gray,
          padding: '4px 16px',
          borderRadius: '8px',
          width: '100%',
        }}>
        <Box>
          <Heading mt={3} size="md">
            {`What's included?`}
          </Heading>
          {creditsFeatures.map((f, idx) => (
            <Text key={idx}>{f}</Text>
          ))}
        </Box>
        <Box>
          <Heading mt={3} size="md">
            Included with your account:
          </Heading>
          {accountFeatures.map((f, idx) => (
            <Text key={idx}>{f}</Text>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
