import React, {useContext, useEffect, useState} from 'react';
import {Badge, Box, Text, Tooltip} from '@chakra-ui/react';
import {FiBell, FiCheckCircle, FiCircle, FiFlag} from 'react-icons/fi';
import {useNavigate} from 'react-router-dom';
import {formatRelative, parseISO} from 'date-fns';
import {LanguageContext} from 'App';

export default function NotificationItem(props: any) {
  const {item, markAsRead, markAsComplete} = props;
  const {translatedData} = useContext(LanguageContext);
  const navigate = useNavigate();

  const [isNew, setIsNew] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    if (!item) return;
    setIsNew(!item.readAt);
    setIsCompleted(!(item.status === 'INCOMPLETE'));
  }, [item]);

  const handleClick = () => {
    if (isNew) {
      markAsRead(item.id, isNew);
    }
    switch (item.category) {
      case 'profile-completeness':
        navigate('/settings');
        break;
      case 'widget-free-first-use':
        navigate('/widget');
        break;
      case 'widget-free-expired':
      case 'billing-upgrade-alert-subscribers':
        navigate('/billing');
        break;
    }
  };

  const markAsReadClicked = () => {
    markAsRead(item.id, isNew);
  };

  const markAsCompleteClicked = () => {
    markAsComplete(item.id, !isCompleted);
  };

  const getNotificationTitle = (item: any) => {
    return translatedData['noti-' + item.category + '-title'] || item.title;
  };
  const getNotificationBody = (item: any) => {
    return translatedData['noti-' + item.category + '-desc'] || item.body;
  };

  return (
    <Box
      pl={2}
      pr={1}
      py={2}
      borderRadius={10}
      cursor="pointer"
      sx={{_hover: {bg: 'gray.100'}}}
      onClick={handleClick}
    >
      <Box display="flex" alignItems="center" gap={3}>
        <Box position="relative">
          <FiBell size={48} color="SkyBlue" />
          {isNew && (
            <Badge position="absolute" top={-2} right={-1} colorScheme="red">
              NEW
            </Badge>
          )}
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          gap={1}
          width={1}
          flexGrow={1}
        >
          <Box width={1} flexGrow={1}>
            <Box>{getNotificationTitle(item)}&nbsp;&nbsp;&nbsp;</Box>
            <Text fontSize={14} color="gray.500">
              {getNotificationBody(item)}
            </Text>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            gap={2}
            mt={1}
            onClick={e => e.stopPropagation()}
          >
            <Tooltip
              label={
                isNew
                  ? translatedData['mark-as-read']
                  : translatedData['mark-as-unread']
              }
            >
              <Box color="gray" cursor="pointer" onClick={markAsReadClicked}>
                {isNew ? <FiCircle /> : <FiCheckCircle />}
              </Box>
            </Tooltip>
            <Tooltip
              label={
                isCompleted
                  ? translatedData['mark-as-incomplete']
                  : translatedData['mark-as-done']
              }
            >
              <Box
                color="gray"
                cursor="pointer"
                onClick={markAsCompleteClicked}
              >
                <FiFlag fill={isCompleted ? 'gray' : 'transparent'} />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Text align="right" whiteSpace="nowrap" fontSize={14} color="skyblue">
        {formatRelative(parseISO(item.createdAt), new Date())}
      </Text>
    </Box>
  );
}
