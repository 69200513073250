export const searchTotalContents = /* GraphQL */ `
  query searchTotalContents(
    $filter: SearchableContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      total
    }
  }
`;

export const searchEnvironmentContents = /* GraphQL */ `
  query searchEnvironmentContents(
    $filter: SearchableContentFilterInput
    $sort: SearchableContentSortInput
    $from: Int
    $limit: Int
  ) {
    searchContents(filter: $filter, sort: $sort, limit: $limit, from: $from) {
      items {
        id
        name
        type
        createdAt
        updatedAt
        enabledLanguages {
          code
        }
        versions {
          items {
            id
            name
            value
            createdAt
            translations {
              items {
                text
              }
            }
          }
        }
      }
      nextToken
      total
    }
  }
`;

export const getEnvironmentWithContents = /* GraphQL */ `
  query getEnvironmentWithContents($id: ID!, $limit: Int) {
    getEnvironment(id: $id) {
      id
      name
      url
      application {
        id
        name
      }

      versions(limit: $limit) {
        items {
          id
          environmentID

          version {
            id
            name
            type
            value
            createdAt
            content {
              id
              name
              versions {
                items {
                  id
                  name
                }
              }
            }
            translations {
              items {
                text
                language
              }
            }
          }
        }
      }
    }
  }
`;

export const createEnvironmentVersion = /* GraphQL */ `
  mutation CreateEnvironmentVersion(
    $input: CreateEnvironmentVersionInput!
    $condition: ModelEnvironmentVersionConditionInput
  ) {
    createEnvironmentVersion(input: $input, condition: $condition) {
      id
      environmentID

      version {
        id
        name
        type
        value
        createdAt
        content {
          id
          name
          versions {
            items {
              id
              name
            }
          }
        }
        translations {
          items {
            text
            language
          }
        }
      }
    }
  }
`;

export const deleteEnvironmentVersion = /* GraphQL */ `
  mutation DeleteEnvironmentVersion(
    $input: DeleteEnvironmentVersionInput!
    $condition: ModelEnvironmentVersionConditionInput
  ) {
    deleteEnvironmentVersion(input: $input, condition: $condition) {
      id
    }
  }
`;

export const searchContents = /* GraphQL */ `
  query SearchContents(
    $filter: SearchableContentFilterInput
    $sort: SearchableContentSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchContents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        companyID
        name
        type
        createdAt
        enabledLanguages {
          label
          code
          autoTranslate
        }
        updatedAt
        tenantID
        versions {
          items {
            id
            name
          }
        }
      }
      nextToken
      total
    }
  }
`;

export const listApplicationsOnWidget = /* GraphQL */ `
  query ListApplications(
    $filter: ModelApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyID
        name
        type
        supportedLanguages {
          label
          code
          autoTranslate
        }
        createdAt
        updatedAt
        company {
          id
          name
          tenantID
          createdAtUnix
          updatedAtUnix
          createdAt
          updatedAt
        }
        tenantID
        environments {
          items {
            id
            name
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const getWidgetOnWidget = /* GraphQL */ `
  query GetWidget($id: ID!) {
    getWidget(id: $id) {
      id
      environmentID
      name
      description
      urls
      tags
      sourceLanguage
      autoTranslate
      display
      bannerColor
      bannerBorderColor
      hideBanner
      hideLogo
      disabled
      formality
      step
      enableMonitoring
      createdAt
      updatedAt
      environment {
        id
        applicationID
        name
        url
        createdAt
        updatedAt
        application {
          id
          companyID
          name
          type
          createdAt
          updatedAt
          tenantID
          supportedLanguages {
            label
            code
            autoTranslate
          }
        }
        tenantID
        versions {
          nextToken
        }
      }
      tenantID
    }
  }
`;
