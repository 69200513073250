import API from '@aws-amplify/api';
import {graphqlOperation, GraphQLResult} from '@aws-amplify/api-graphql';
import {Heading} from '@chakra-ui/layout';
import {Button, useDisclosure} from '@chakra-ui/react';
import {SkeletonText} from '@chakra-ui/skeleton';
import {Table, TableCaption, Tbody, Td, Th, Thead, Tr} from '@chakra-ui/table';
import {ListUsersQuery} from 'API';
import {PageActions} from 'components/page/page-actions';
import {PageContainer} from 'components/page/page-container';
import {PageHeader} from 'components/page/page-header';
import {listUsers} from 'graphql/queries';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {AddUser} from './_add-user';

const StyledTr = styled(Tr)`
  cursor: pointer;
`;
export const UsersScreen = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  const modalOptions = useDisclosure();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    // Simple query
    const response = (await API.graphql(
      graphqlOperation(listUsers),
    )) as GraphQLResult<ListUsersQuery>;

    const data = response?.data?.listUsers?.items as any;
    setUsers(data);
    setLoading(false);
  };

  return (
    <PageContainer>
      <PageHeader>
        <Heading>Users</Heading>
        <PageActions>
          <Button onClick={modalOptions.onOpen}>Add User</Button>
        </PageActions>
      </PageHeader>
      {!loading ? (
        <Table variant="simple">
          <TableCaption>Users</TableCaption>
          <Thead>
            <Tr>
              <Th>Email</Th>
              <Th>Name</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.length > 0 ? (
              users.map((user: any) => (
                <StyledTr
                  onClick={() => (window.location.href = '/users/1')}
                  key={user.id}>
                  <Td>{user.email}</Td>
                  <Td>{user.name}</Td>
                  <Td>--</Td>
                </StyledTr>
              ))
            ) : (
              <StyledTr>
                <Td>No users</Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
              </StyledTr>
            )}
          </Tbody>
        </Table>
      ) : (
        <SkeletonText mt="4" noOfLines={4} spacing="4" />
      )}
      <AddUser {...modalOptions} />
    </PageContainer>
  );
};
