import {useEffect, useState, useContext} from 'react';
import {API, graphqlOperation} from 'aws-amplify';
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
} from '@chakra-ui/react';
import {LanguageContext} from 'App';
import {v4 as uuidv4} from 'uuid';

import {PageContainer} from 'components/page/page-container';
import CopyButton from 'components/utils/copy-button';
import {PageLoading} from 'components/page/page-loading';
import {tokensByTenant} from 'graphql/queries';
import {Token} from 'API';
import {formatRelative, parseISO} from 'date-fns';
import {StyledTableContainer} from 'components/ui/table/table';
import {formatNumber} from 'utils/helper';
import {useAuth} from 'Auth';
import {FiDelete, FiKey, FiMoreVertical} from 'react-icons/fi';
import styled from 'styled-components';
import {deleteToken, updateToken} from 'graphql/mutations';
import {PageHeader} from 'components/page/page-header-section';
import {BsKeyFill} from 'react-icons/bs';
import ConfirmDialog from 'components/utils/confirm-dialog';

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  gap: 15px;
`;

export const ApiKeyScreen = () => {
  const {auth} = useAuth();
  const {translatedData} = useContext(LanguageContext);

  const [isLoading, setIsLoading] = useState(true);
  const [tokens, setTokens] = useState<Token[]>([]);

  const [regenerateConfirmApiKeyId, setRegenerateConfirmApiKeyId] =
    useState('');
  const isRegenerateConfirmModalOpen = Boolean(regenerateConfirmApiKeyId);

  const [deleteConfirmApiKeyId, setDeleteConfirmApiKeyId] = useState('');
  const isDeleteConfirmModalOpen = Boolean(deleteConfirmApiKeyId);

  const tenantID =
    auth.user?.attributes && auth.user?.attributes['custom:tenantId'];
  // if (!tenantID) return;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const apiKeys = (await API.graphql(
      graphqlOperation(tokensByTenant, {tenantID, limit: 1000}) as any,
    )) as any;

    const tokens = apiKeys.data.tokensByTenant.items;
    tokens.sort((a: Token, b: Token) => (a.createdAt > b.createdAt ? -1 : 1));

    setTokens(tokens);

    setIsLoading(false);
  };

  const regenerateApiKey = async () => {
    if (!regenerateConfirmApiKeyId) return;
    const newApiKey = uuidv4();

    const response = (await API.graphql(
      graphqlOperation(updateToken, {
        input: {
          id: regenerateConfirmApiKeyId,
          apiKey: newApiKey,
        },
      }),
    )) as any;

    const updatedToken = response?.data?.updateToken as Token;
    if (updatedToken?.id) {
      const idx = tokens.findIndex(
        (token: Token) => token.id === updatedToken?.id,
      );
      if (idx > -1) tokens[idx].apiKey = updatedToken.apiKey;
      setTokens([...tokens]);
    }

    setRegenerateConfirmApiKeyId('');
  };

  const deleteApiKey = async () => {
    if (!deleteConfirmApiKeyId) return;

    const response = (await API.graphql(
      graphqlOperation(deleteToken, {
        input: {
          id: deleteConfirmApiKeyId,
        },
      }),
    )) as any;

    const deletedToken = response?.data?.deleteToken as Token;
    if (deletedToken?.id) {
      const idx = tokens.findIndex(
        (token: Token) => token.id === deletedToken?.id,
      );
      if (idx > -1) tokens.splice(idx, 1);
      setTokens([...tokens]);
    }

    setDeleteConfirmApiKeyId('');
  };

  return isLoading ? (
    <PageLoading />
  ) : (
    <PageContainer>
      <PageHeader
        title={translatedData['api-keys']}
        description={translatedData['api-keys-description']}
        TitleIcon={BsKeyFill}
      />

      <StyledTableContainer>
        <Table variant="simple">
          <TableCaption>{translatedData['api-keys']}</TableCaption>
          <Thead>
            <Tr>
              <Th>{translatedData['type']}</Th>
              <Th>{translatedData['api-key']}</Th>
              <Th>{translatedData['usage']}</Th>
              <Th style={{textAlign: 'center'}}>
                {translatedData['created-at']}
              </Th>
              <Th style={{textAlign: 'center'}}>{translatedData['actions']}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {tokens.map((token: any) => (
              <Tr key={token?.apiKey}>
                <Td>{translatedData['api-key']}</Td>
                <Td>
                  {token?.apiKey} <CopyButton text={token?.apiKey} />
                </Td>
                <Td>{formatNumber(token.creditsUsage || 0)} Credits</Td>
                <Td style={{textAlign: 'center'}}>
                  {formatRelative(parseISO(token.createdAt), new Date())}
                </Td>
                <Td style={{textAlign: 'center'}}>
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label="Options"
                      icon={<FiMoreVertical />}
                      variant="outline"
                    />
                    <MenuList>
                      <StyledMenuItem
                        onClick={() => setRegenerateConfirmApiKeyId(token.id)}
                      >
                        <FiKey />
                        <Text>Regenerate Key</Text>
                      </StyledMenuItem>
                      <StyledMenuItem
                        onClick={() => setDeleteConfirmApiKeyId(token.id)}
                      >
                        <FiDelete />
                        <Text>Delete</Text>
                      </StyledMenuItem>
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </StyledTableContainer>

      <ConfirmDialog
        isOpen={isRegenerateConfirmModalOpen}
        onClose={() => setRegenerateConfirmApiKeyId('')}
        isSaving={false}
        confirm={regenerateApiKey}
        headerText={'Regenerate API Key?'}
        bodyText={`Are you sure? You can't undo this action afterwards.`}
        confirmText={translatedData['confirm']}
      />
      <ConfirmDialog
        isOpen={isDeleteConfirmModalOpen}
        onClose={() => setDeleteConfirmApiKeyId('')}
        isSaving={false}
        confirm={deleteApiKey}
        headerText={'Delete API Key?'}
        bodyText={`Are you sure? You can't undo this action afterwards.`}
        confirmText={translatedData['delete']}
      />
    </PageContainer>
  );
};
