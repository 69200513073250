import React, {useContext} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import {LanguageContext} from 'App';

export function AddFile({
  isOpen,
  onClose,
  uploadFile,
  onChangeFile,
  setFileKey,
  accept,
  uploadStatus = 'notStarted',
}: any) {
  const {translatedData} = useContext(LanguageContext);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{translatedData['add-content']}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="name">
            <FormLabel>{translatedData['key-identifier']}</FormLabel>
            <Input type="text" onChange={e => setFileKey(e.target.value)} />
            <FormHelperText>
              {translatedData['file-key-description']}
            </FormHelperText>
          </FormControl>
          <br />
          <FormControl id="name">
            <FormLabel>{translatedData['file']}</FormLabel>
            <Input type="file" accept={accept} onChange={onChangeFile} />
            <FormHelperText>{accept}</FormHelperText>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            {translatedData['close']}
          </Button>
          <Button
            colorScheme="pink"
            disabled={uploadStatus !== 'complete'}
            onClick={() => uploadFile()}>
            {uploadStatus === 'complete' && translatedData['save']}
            {uploadStatus === 'notStarted' && translatedData['choose-file']}
            {uploadStatus === 'uploading' && translatedData['uploading']}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
